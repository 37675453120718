import "react-hot-loader"
import React from "react"
import ReactDOM from "react-dom"
import { Provider } from "react-redux"
import * as serviceWorker from "./serviceWorker"
import "./i18n"
import App from "./App"
import { EPopupTypes } from "./types/Popups"
import storeFactory from "./store/store"
import "./assets/fonts/Avenir-Black.otf"
import "./assets/fonts/Avenir-Light.otf"
import "materialize-css/dist/css/materialize.min.css"
import "./styles/index.css"
import "./styles/textStyles.css"
import "./styles/students.css"
import "./styles/contentCreator.css"

const store = storeFactory()

ReactDOM.render(
  <Provider store={store}>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </Provider>,

  document.getElementById("root")
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()

document.addEventListener("DOMContentLoaded", function () {
  const elem = document.getElementById(EPopupTypes.LOADING_POPUP)
  const loadingModal = M.Modal.getInstance(elem as Element)
  loadingModal.options.dismissible = false
})
