import firebase from "firebase/app"
import "firebase/storage"
import "firebase/auth"
import config from "./config"

// Initialize Firebase
firebase.initializeApp(config.firebase)

const storage = firebase.storage()

export { storage, firebase as default }
