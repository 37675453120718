import React, { useState, useEffect } from "react"
import SQVTotalQuestions from "./Students/StudentQuickView/SQVTotalQuestions"

interface IProps {
  imageUrl: string
  id: string
  name: string
  enabled: boolean
  onClick: Function
  totalQuestions?: number
}

const BundleQuickView = ({
  imageUrl,
  id,
  name,
  enabled,
  onClick,
  totalQuestions,
}: IProps) => {
  const [on, setOn] = useState(false)

  useEffect(() => {
    setOn(enabled)
  }, [enabled])

  return (
    <div className="valign-wrapper flexListItem grey lighten-3">
      <div className="valign-wrapper section center small">
        <img
          className="responsive-img"
          style={{ maxHeight: "80px" }}
          alt=""
          src={imageUrl}
        />
      </div>
      <div className="valign-wrapper section center subtitle">{name}</div>
      <div className="valign-wrapper section center hide-on-small-only">
        <SQVTotalQuestions title="Total answered" value={totalQuestions} />
      </div>
      <div className="valign-wrapper section center hide-on-small-only" />

      <div className="valign-wrapper section center small">
        <div className="switch">
          <label className="row subtitle">Activate</label>
          <label>
            <input
              id={`switch${id}`}
              type="checkbox"
              defaultChecked={enabled}
              onClick={(e) => {
                const active = e.currentTarget.checked
                onClick({ bundleId: id, add: active })
                setOn(active)
              }}
            />
            <span className="lever" />
          </label>
        </div>
      </div>
    </div>
  )
}

BundleQuickView.defaultProps = {
  onClick: () => {},
  totalQuestions: 0,
}

export default BundleQuickView
