import { Dispatch } from "redux"
import firebaseRTDB from "firebase"
import { AppActions } from "../../types/actionTypes/actions"
import { EAssetsActionTypes } from "../../types/actionTypes/assetsActionTypes"
import firebase, { storage } from "../../configs/fbConfig"
import { RootState } from "../reducers/rootReducer"
import { EImageStatus } from "../reducers/assetsReducer"
import { EAssetType } from "../../types/generalInterfaces"
import { validateAndChnageFilenameForStorage } from "../../utils/utils";

export const getSprites = () => async (dispatch: Dispatch<AppActions>) => {
  try {
    // for now getting this as once from the firebase node. Need to add this to the reducer somewhere in the assetManager
    const snapshot = await firebaseRTDB
      .database()
      .ref("/sprites/web")
      .once("value")
    if (snapshot.exists()) {
      const data = snapshot.val()
      console.log(data)
      dispatch({
        type: EAssetsActionTypes.GET_SPRITE_SUCCESS,
        spritesData: data,
      })
    }
  } catch (error) {
    console.error(error)

    dispatch({
      type: EAssetsActionTypes.GET_SPRITE_FAILED,
    })
  }
}

export const getImages = () => async (dispatch: Dispatch<AppActions>) => {
  try {
    // for now getting this as once from the firebase node. Need to add this to the reducer somewhere in the assetManager
    const snapshot = await firebaseRTDB
      .database()
      .ref("/images/web")
      .once("value")
    if (snapshot.exists()) {
      const data = snapshot.val()
      console.log(data)
      dispatch({
        type: EAssetsActionTypes.GET_IMAGES_SUCCESS,
        imagesData: data,
      })
    }
  } catch (error) {
    console.error(error)

    dispatch({
      type: EAssetsActionTypes.GET_IMAGES_FAILED,
    })
  }
}

export const uploadImage = (
  image: File,
  assetType: EAssetType = EAssetType.IMAGE_FOR_PERSONALIZED_BUNDLE
) => async (dispatch: Dispatch<AppActions>, getState: () => RootState) => {
  if (image) {
    const { uid } = firebase.auth().currentUser!
    const userId = getState().user.id
    let uploadPath
    switch (assetType) {
      case EAssetType.IMAGE_FOR_PERSONALIZED_BUNDLE:
        uploadPath = `/users/${uid}/${userId}/bundles/${image.name}`
        break
      default:
        uploadPath = `/users/${uid}/${userId}/bundles/${image.name}`
        break
    }
    const uploadTask = storage.ref(uploadPath).put(image)

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        // takes a snap shot of the process as it is happening
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        dispatch({
          type: EAssetsActionTypes.UPLOADING,
          progress,
          imageName: image.name,
        })
      },
      (err) => {
        // catches the errors
        console.error(err)
        dispatch({
          type: EAssetsActionTypes.UPLOAD_FAILED,
          imageName: image.name,
        })
      },
      async () => {
        // gets the download url then sets the image from firebase as the value for the imgUrl key:
        const downloadURL = await uploadTask.snapshot.ref.getDownloadURL()
        dispatch({
          type: EAssetsActionTypes.UPLOAD_SUCCESS,
          downloadURL,
          imageName: image.name,
        })
      }
    )
  }
}

export const getImageURL = (imageName: string): any => async (
  dispatch: Dispatch<AppActions>,
  getState: () => RootState
) => {
  // first get the state and check if there is no a procrss to download this already
  const { assetManager } = getState()
  if (
    assetManager.images[imageName] &&
    assetManager.images[imageName].status !== EImageStatus.ERROR
  ) {
    console.log(
      `getImageURL already in progress. Doing nothing for ${imageName}`
    )
    return
  }
  console.log(`getImageURL ${imageName}`)

  let downloadURL
  try {
    dispatch({
      type: EAssetsActionTypes.DOWNLOADING,
      imageName,
    })

    let imagePath = ""
    if (imageName.split("/").length === 1) {
      imagePath = `/images/app/${imageName}`
    } else {
      imagePath = imageName
    }
    console.log(`getImageURL download image  from ${imagePath}`)

    downloadURL = await storage.ref(imagePath).getDownloadURL()

    console.log("getImageURL", downloadURL)

    if (downloadURL) {
      dispatch({
        type: EAssetsActionTypes.GET_IMAGE_URL_SUCCESS,
        downloadURL,
        imageName,
      })
    }
  } catch (error) {
    try {
      // got an error
      console.error(error)

      const errorCode = error.code
      if (errorCode === "storage/object-not-found") {
        // try to get from the userUID
        const { uid } = firebase.auth().currentUser!
        downloadURL = await storage
          .ref(`/users/${uid}/${imageName}`)
          .getDownloadURL()
        console.log("getImageURL", downloadURL)

        if (downloadURL) {
          dispatch({
            type: EAssetsActionTypes.GET_IMAGE_URL_SUCCESS,
            downloadURL,
            imageName,
          })
        }
      }
    } catch (error) {
      dispatch({
        type: EAssetsActionTypes.GET_IMAGE_URL_FAILED,
        downloadURL,
        imageName,
      })
    }
  }
}
