import {
  EBundleActionsTypes,
  BundleActionTypes,
} from "../../types/actionTypes/BundleActionTypes"

const childReducerDefaultState: {
  selectedbundleId: string
  isEditable: boolean
} = {
  selectedbundleId: "",
  isEditable: false,
}

const bundleReducer = (
  state = childReducerDefaultState,
  action: BundleActionTypes
): {
  selectedbundleId: string
  isEditable: boolean
} => {
  switch (action.type) {
    case EBundleActionsTypes.UPDATE_SELECTED_PERSONALIZED_BUNDLE_ID: {
      const { bundleId, isEditable } = action
      return {
        ...state,
        selectedbundleId: bundleId,
        isEditable: !!isEditable,
      }
    }

    case EBundleActionsTypes.CLEAR_SELECTED_BUNDLE_ID: {
      return {
        ...state,
        selectedbundleId: "",
        isEditable: false,
      }
    }

    default:
      return state
  }
}

export { bundleReducer }
