import React, { useEffect, useState } from 'react'
// @ts-ignore
import { SpriteAnimator } from 'react-sprite-animator'
import SpriteSideBar  from './SpriteSideBar'
import { bindActionCreators, Dispatch } from 'redux';
import { RootState } from '../../../../store/reducers/rootReducer';
import { connect } from 'react-redux';
import { getSprites } from '../../../../store/actions/assetsActions'



interface Props {
    updateSpriteSelected:Function,
    spriteName:string
}

type IProps = ReturnType<typeof mapDispatchToProps> & ReturnType<typeof mapStateToProps> & Props


const SpriteSelector = ({ updateSpriteSelected,spriteName,sprites,getSprites }: IProps) => {

  
  const [spriteURL,setSpriteURL] = useState<string | null>()

  useEffect(() => {
    if (sprites[spriteName] && sprites[spriteName].downloadURL){
      setSpriteURL(sprites[spriteName].downloadURL)
    }else{
      //get the sprites
      getSprites();
    }
  }, [spriteName]);

  useEffect(() => {
    if (sprites[spriteName] && sprites[spriteName].downloadURL){
      setSpriteURL(sprites[spriteName].downloadURL)
    }
  }, [sprites]);

  const openCreateSideBar = () => {
    const elem = document.getElementById("sprite-slide-out")
    const instance = M.Sidenav.init(elem!,{edge:'right'})// M.Sidenav.getInstance(elem as Element)
    instance.open();
  }

  const selectSpriteButton = <button type="button" className={`sidenav-trigger`} style={{border:"none"}} 
  onClick={(e) => openCreateSideBar()}>
    {spriteURL ? 
    <SpriteAnimator
    sprite={spriteURL}
    width={600}
    height={600}
    frameCount={36}
    wrapAfter={6}
    fps={25}
    scale={4}
  />
  :
  <i className="material-icons large" style={{ color: "white", backgroundColor: "#979797",borderRadius:"5px" }}>add</i>}
      </button>
  
  return (
    <div className="row">
    <div className="col s12 center"> 
      {selectSpriteButton}
      <div>Choose/Upload question image</div>
    </div>

    <SpriteSideBar updateSpriteSelected={(sName:string) => {updateSpriteSelected(sName)}}/>
    </div>
  )
}

const mapStateToProps = (state: RootState) => {
  return {
    sprites: state.assetManager.sprites
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => {

  return bindActionCreators(
    {
      getSprites
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(SpriteSelector)



