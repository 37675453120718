import React, { useState, useEffect } from "react"
import { connect } from "react-redux"
import { ThunkDispatch } from "redux-thunk"
import { bindActionCreators } from "redux"
import { useHistory } from "react-router"
import { RootState } from "../../../store/reducers/rootReducer"
import { AppActions } from "../../../types/actionTypes/actions"
import {
  addChildToFamily,
  addChildToFamilyRequestInterface,
} from "../../../store/actions/userActions"
import { editSupervisedData } from "../../../store/actions/childActions"
import { getAllBundlesByIds } from "../../../store/selectors/kingdomsSelector"
import {
  genderEnum,
  questionPackageActionInterface,
} from "../../../types/generalInterfaces"
import ChangeBundles from "../ChangeBundles"
import { DynaInput, DynaDropdown, ImageWithFallback } from "../../common"
import {
  LOCK_SCREEN_FREQUENCY_OPTIONS,
  LOCK_SCREEN_FREQUENCY_NEVER,
  LOCALE_OPTIONS,
  AGE_OPTIONS,
  AVATARS,
} from "../../../constants/constants"
import {
  getKingdoms,
  getPublicPersonalizedBundles,
} from "../../../store/actions/kingdomActions"
import { Bundle } from "../../../types/Bundle"
import StudentAvatarPopup from "./StudentAvatarPopup"
import {
  showPopupById,
  hidePopupById,
} from "../../../store/actions/popupActions"
import { EPopupTypes } from "../../../types/Popups"
import { DynaButton } from "../../common/DynaButton"
import { Supervised } from "../../../types/Child"

interface IProps {}

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  IProps

const CreateChild = ({
  addChildToFamily,
  allBundlesByIds,
  getKingdoms,
  getPublicPersonalizedBundles,
  kingdoms,
  showPopupById,
  hidePopupById,
  editSupervisedData,
}: Props) => {
  const history = useHistory()
  const [isEdit, setIsEdit] = useState(false)
  const [isReady, setIsReady] = useState(false)

  const [createChildObj, setCreateChildObj] = useState<
    addChildToFamilyRequestInterface
  >({
    profile: {
      nickname: "",
      age: AGE_OPTIONS[0].value,
      gender: genderEnum.UNKNOWN,
      avatarName: "",
    },
    questionsPackageActions: [],
    lockScreenFrequency: 1,
    manualLocale: LOCALE_OPTIONS[0].value,
    enabledLockScreen: true,
  })

  const [photoUrl, setPhotoUrl] = useState<any>(null)
  const [selectedAvatar, setSelectedAvatar] = useState<{
    url: string
    name: string
  }>({ url: "", name: "" })
  const [showAddBundles, setShowAddBundles] = useState(false)
  const [createChildProfile, setCreateChildProfile] = useState<{
    nickname: string
    age: number
    gender: genderEnum
    avatarName: string
  }>({
    nickname: "",
    age: AGE_OPTIONS[0].value,
    gender: genderEnum.UNKNOWN,
    avatarName: "",
  })

  useEffect(() => {
    const modalelems = document.querySelectorAll(".modal")
    M.Modal.init(modalelems)
    M.updateTextFields()
  }, [])

  useEffect(() => {
    const state = history.location.state as {
      isEdit: boolean
      supervised: Supervised
    }
    if (state && state.isEdit && state.supervised) {
      const { supervised } = state
      setCreateChildProfile({
        ...createChildProfile,
        nickname: supervised.profile.nickname,
        age: supervised.profile.age,
      })

      setCreateChildObj({
        ...createChildObj,
        manualLocale: supervised.profile.locale,
        lockScreenFrequency: supervised.profile.lockScreenFrequency,
        enabledLockScreen: supervised.profile.enableLockscreen,
      })

      if (supervised.profile.avatarName) {
        const avatar = AVATARS.find(
          (a) => a.name === supervised.profile.avatarName
        )

        if (avatar) {
          setSelectedAvatar(avatar)
        }
      }

      setIsEdit(true)
    }

    setIsReady(true)
  }, [])

  useEffect(() => {
    if (kingdoms.length === 0) {
      getKingdoms()
      getPublicPersonalizedBundles()
    }
  }, [getKingdoms, kingdoms.length])

  const onSelectManualLocaleChange = (e: any) => {
    const { value } = e.target
    setCreateChildObj({ ...createChildObj, manualLocale: value })
  }

  const onSelectFrquencyChange = (e: any) => {
    const { value } = e.target
    const data = {
      ...createChildObj,
    }
    if (value === LOCK_SCREEN_FREQUENCY_NEVER) {
      data.lockScreenFrequency = 1
      data.enabledLockScreen = false
    } else {
      data.lockScreenFrequency = parseInt(value, 10)
      data.enabledLockScreen = true
    }
    setCreateChildObj(data)
  }

  const addRemoveBundlesToChild = (
    addRemoveArray: questionPackageActionInterface[]
  ) => {
    setCreateChildObj({
      ...createChildObj,
      questionsPackageActions: addRemoveArray,
    })
  }

  const addChild = () => {
    // TODO - support custom avatars (photoUrl)
    const childToAdd = {
      ...createChildObj,
      profile: { ...createChildProfile, avatarName: selectedAvatar.name },
    }
    addChildToFamily(childToAdd)
  }

  const handleSupervisedUpdate = () => {
    const state = history.location.state as {
      isEdit: boolean
      supervised: Supervised
    }

    const {
      supervised: { id },
    } = state

    const supervisedToUpdate = {
      ...createChildObj,
      profile: { ...createChildProfile, avatarName: selectedAvatar.name },
    }

    editSupervisedData(supervisedToUpdate, id)
  }

  const renderSelectedBundles = () => {
    return createChildObj.questionsPackageActions.map((o, index) => {
      const bundle: Bundle | undefined | null = allBundlesByIds[o.id]
      if (bundle) {
        return (
          <div key={index}>
            <img
              className="responsive-img"
              style={{
                width: 80,
                height: 80,
                margin: "0 10px",
              }}
              src={bundle.imageUrl}
              alt=""
            />
          </div>
        )
      }

      return null
    })
  }

  const handleImageAsFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedAvatar({ name: "", url: "" })
    const image = e.target.files && e.target.files[0]
    const reader = new FileReader()

    reader.onload = (e) => {
      setPhotoUrl(e?.target?.result)
    }

    image && reader.readAsDataURL(image)
    hidePopupById(EPopupTypes.SELECT_STUDENT_AVATAR_POPUP)
  }

  const renderHeader = () => {
    return (
      <span className="header title">
        {`Students >`}
        <span className={`${!showAddBundles && "bold"}`}>
          &nbsp;Add student&nbsp;
        </span>
        {showAddBundles && (
          <>
            {`>`}
            <span className="bold">&nbsp;Add bundles</span>
          </>
        )}
      </span>
    )
  }

  const renderCreateStudent = () => {
    return (
      <>
        <form className="col s12 noPadding">
          <div
            className="center"
            style={{
              flex: 1,
              margin: "30px auto",
            }}
          >
            <button
              type="button"
              className="waves-effect transparent noPadding"
              style={{
                border: 0,
                borderRadius: "50%",
                width: 100,
                height: 100,
              }}
              onClick={() =>
                showPopupById(EPopupTypes.SELECT_STUDENT_AVATAR_POPUP)
              }
            >
              <ImageWithFallback
                imageUrl={photoUrl || selectedAvatar.url}
                fallbackImageUrl={require("../../../assets/images/profile_student_placeholder.png")}
                width={100}
                height={100}
              />
            </button>
            <div className="title">Upload student avatar</div>
          </div>
          <div style={{ display: "flex", flex: 1 }}>
            <DynaInput
              value={createChildProfile.nickname}
              id="nickname"
              onChange={(value: string) =>
                setCreateChildProfile({
                  ...createChildProfile,
                  nickname: value,
                })
              }
              title="Student name"
              required
              minLength={2}
              validate
              errorText="Minimum 2 charecters"
            />
          </div>

          <div style={{ display: "flex", flex: 1, paddingBottom: 18 }}>
            {isReady && (
              <DynaDropdown
                options={AGE_OPTIONS}
                id="age"
                initialValue={isEdit ? createChildProfile.age : null}
                onChange={(e: any) =>
                  setCreateChildProfile({
                    ...createChildProfile,
                    age: e.target.value,
                  })
                }
                title="Age"
                required
              />
            )}
          </div>

          <div style={{ display: "flex", flex: 1, paddingBottom: 18 }}>
            {isReady && (
              <DynaDropdown
                options={LOCALE_OPTIONS}
                id="nativeLanguage"
                initialValue={isEdit ? createChildObj.manualLocale : null}
                onChange={onSelectManualLocaleChange}
                title="Native language"
                required
              />
            )}
          </div>

          <div style={{ display: "flex", flex: 1, paddingBottom: 18 }}>
            {isReady && (
              <DynaDropdown
                options={LOCK_SCREEN_FREQUENCY_OPTIONS}
                id="lockScreenMethod"
                initialValue={
                  isEdit ? createChildObj.lockScreenFrequency : null
                }
                onChange={onSelectFrquencyChange}
                title="Lock screen method"
                required
              />
            )}
          </div>

          <div
            style={{
              display: "flex",
              flex: 0.3,
              paddingBottom: 18,
            }}
          >
            <DynaButton
              text={isEdit ? "Update Students Details" : "Add Bundles"}
              disabled={createChildProfile.nickname.length === 0}
              onClick={() => {
                if (!selectedAvatar.url && !photoUrl) {
                  showPopupById(EPopupTypes.SELECT_STUDENT_AVATAR_POPUP)
                  return
                }
                isEdit ? handleSupervisedUpdate() : setShowAddBundles(true)
              }}
              customContainerStyle={{ margin: "0 auto" }}
            />
          </div>
        </form>
      </>
    )
  }

  const renderAddBundles = () => {
    return (
      <div className="col s12 noPadding">
        <div className="col s12 l4" style={{ padding: "0 20px" }}>
          <div
            className="center"
            style={{
              display: "flex",
              backgroundColor: "#eeeeee",
              padding: 20,
            }}
          >
            <button
              type="button"
              className="btn-floating btn-small"
              style={{
                backgroundColor: "#bababa",
                position: "absolute",
              }}
              onClick={() => {
                addRemoveBundlesToChild([])
                setShowAddBundles(false)
              }}
            >
              <i className="material-icons">edit</i>
            </button>
            <div className="" style={{ margin: "0 auto" }}>
              <ImageWithFallback
                imageUrl={photoUrl || selectedAvatar.url}
                fallbackImageUrl={require("../../../assets/images/profile_student_placeholder.png")}
                width={100}
                height={100}
              />

              <div className="title bold">{createChildProfile.nickname}</div>
            </div>
          </div>
          <div style={{ height: "2px", backgroundColor: "#979797" }} />
          <div
            className="center"
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center",
              flex: 1,
              minHeight: 200,
              backgroundColor: "#eeeeee",
              padding: 20,
            }}
          >
            {createChildObj.questionsPackageActions.length === 0 && (
              <div className="subtitle">
                Please select at least one bundle from the right panel
              </div>
            )}
            {renderSelectedBundles()}
          </div>

          <div
            style={{
              display: "flex",
              paddingTop: 18,
            }}
          >
            <DynaButton
              text="Create a Student"
              disabled={createChildObj.questionsPackageActions.length === 0}
              onClick={() => addChild()}
              customContainerStyle={{ margin: "0 auto" }}
            />
          </div>
        </div>
        <div className="col s12 l8">
          <ChangeBundles
            childBundles={[]}
            addRemoveBundlesToChild={addRemoveBundlesToChild}
            addRemoveArray={createChildObj.questionsPackageActions}
          />
        </div>
      </div>
    )
  }

  // TODO - form validation
  return (
    <div className="row contentContainer">
      {renderHeader()}
      {showAddBundles ? renderAddBundles() : renderCreateStudent()}
      <StudentAvatarPopup
        handleImageAsFile={(e: any) => handleImageAsFile(e)}
        onClick={(avatar: { url: string; name: string }) => {
          setPhotoUrl(null)
          setSelectedAvatar(avatar)
          hidePopupById(EPopupTypes.SELECT_STUDENT_AVATAR_POPUP)
        }}
      />
    </div>
  )
}

const mapStateToProps = (state: RootState) => {
  const {
    kingdoms: { kingdoms },
  } = state

  const allBundlesByIds = getAllBundlesByIds(state)

  return {
    allBundlesByIds,
    kingdoms,
  }
}

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AppActions>) => {
  return bindActionCreators(
    {
      addChildToFamily,
      getKingdoms,
      getPublicPersonalizedBundles,
      showPopupById,
      hidePopupById,
      editSupervisedData,
    },
    dispatch
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateChild)
