import { Dispatch } from "redux"
import { pathOr } from "ramda"
import { v1 as uuidv1 } from "uuid"
import { history } from "../../App"
import { AppActions } from "../../types/actionTypes/actions"
import { EAuthActionTypes } from "../../types/actionTypes/AuthActionsTypes"
import { EPopupTypes } from "../../types/Popups"
import firebase from "../../configs/fbConfig"
import { showPopupById, hidePopupById } from "./popupActions"
import {
  editUser,
  getSupervisedListMetadata,
  getPersonalizedBundles,
} from "./userActions"
// import { setPendingChildrenListeners } from "./childActions"
import { requestWrapper2 } from "../../services/ServerConnection"
import { identifyUser, registerEvent } from "../../services/analyticsService"
import { EErrorTypes } from "../../types/Error"
import { EUserType, EStorageKeys } from "../../types/generalInterfaces"
import { getKingdoms, getPublicPersonalizedBundles } from "./kingdomActions"

export const setIsLoggedIn = (isLoggedIn: boolean): any => ({
  type: EAuthActionTypes.SET_IS_LOGGED_IN,
  isLoggedIn,
})

export const getLoggedInUser = (user: firebase.User | null) => async (
  dispatch: Dispatch<AppActions>
) => {
  if (!user) {
    // user logged out - show login popup
    localStorage.removeItem(EStorageKeys.isAdmin)
    dispatch({ type: EAuthActionTypes.CLEAR_SENSITIVE_DATA })
    // dispatch(showPopupById(EPopupTypes.LOGIN_POPUP))
    // history.push({
    //   pathname: "/login",
    // })
  } else {
    // set listener on pending children "lastSeen" to detect child auth
    // dispatch(setPendingChildrenListeners())
  }
  identifyUser()
  registerEvent("app opened")
  dispatch(setIsLoggedIn(!!user))
}

export const signInWithGoogle = () => async (
  dispatch: Dispatch<AppActions>
) => {
  try {
    // get google provider from firebase
    const provider = new firebase.auth.GoogleAuthProvider()
    // auth with google
    const userDetails = await firebase.auth().signInWithPopup(provider)
    console.log("userDetails", userDetails)

    const email = pathOr(
      "",
      ["additionalUserInfo", "profile", "email"],
      userDetails
    )
    const firstName = pathOr(
      "",
      ["additionalUserInfo", "profile", "given_name"],
      userDetails
    )
    const lastName = pathOr(
      "",
      ["additionalUserInfo", "profile", "family_name"],
      userDetails
    )

    dispatch(login(email, firstName, lastName))
  } catch (error) {
    console.log(error)
    dispatch(
      showPopupById(
        error.code === EErrorTypes.ACCOUNT_EXISTS
          ? EPopupTypes.ACCOUNT_EXISTS_POPUP
          : EPopupTypes.GENERAL_ERROR_POPUP
      )
    )

    dispatch(logout())
  }
}

export const signInWithFacebook = () => async (
  dispatch: Dispatch<AppActions>
) => {
  try {
    // get facebook provider from firebase
    const provider = new firebase.auth.FacebookAuthProvider()
    // auth with Facebook
    const userDetails = await firebase.auth().signInWithPopup(provider)
    console.log("userDetails", userDetails)

    const email = pathOr(
      "",
      ["additionalUserInfo", "profile", "email"],
      userDetails
    )
    const firstName = pathOr(
      "",
      ["additionalUserInfo", "profile", "first_name"],
      userDetails
    )
    const lastName = pathOr(
      "",
      ["additionalUserInfo", "profile", "last_name"],
      userDetails
    )

    dispatch(login(email, firstName, lastName))
  } catch (error) {
    console.log(error)
    dispatch(
      showPopupById(
        error.code === EErrorTypes.ACCOUNT_EXISTS
          ? EPopupTypes.ACCOUNT_EXISTS_POPUP
          : EPopupTypes.GENERAL_ERROR_POPUP
      )
    )
    dispatch(logout())
  }
}

export const login = (
  userEmail: string,
  userFirstName: string,
  userLastName: string,
  isUserUpdate?: boolean
): any => async (dispatch: Dispatch<AppActions>) => {
  try {
    dispatch(hidePopupById(EPopupTypes.LOGIN_POPUP))
    dispatch(showPopupById(EPopupTypes.LOADING_POPUP))
    const res = await requestWrapper2("loginUser", {
      email: userEmail,
      firstName: userFirstName,
      lastName: userLastName,
      userType: EUserType.MENTOR,
    })

    console.log("res.data", res.data)
    dispatch(setIsLoggedIn(true))
    dispatch(hidePopupById(EPopupTypes.LOADING_POPUP))
    console.log(res)

    const id = pathOr("", ["data", "id"], res)
    const email = pathOr("", ["data", "email"], res)
    const firstName = pathOr("", ["data", "firstName"], res)
    const lastName = pathOr("", ["data", "lastName"], res)
    const familyId = pathOr("", ["data", "familyId"], res)
    const userType = pathOr(EUserType.MENTOR, ["data", "userType"], res)
    const staticConnectCode = pathOr("", ["data", "staticConnectCode"], res)
    const photoUrl = pathOr("", ["data", "photoUrl"], res)
    const permissions = pathOr({}, ["data", "permissions"], res)

    if (res.data.permissions?.admin) {
      localStorage.setItem(EStorageKeys.isAdmin, "true")
    }

    dispatch(
      editUser({
        id,
        email,
        firstName,
        lastName,
        familyId,
        userType,
        staticConnectCode,
        supervised: [],
        supervisors: [],
        personalizedBundles: [],
        photoUrl,
        permissions,
      })
    )

    if (res.status === 201) {
      history.push({
        pathname: "/userProfile",
        state: { isFromSignUp: true },
      })
    } else if (res.status === 200) {
      history.push({
        pathname: "/students",
      })
    }

    if (isUserUpdate) {
      dispatch(getSupervisedListMetadata())
      dispatch(getPersonalizedBundles())
      dispatch(getKingdoms())
      dispatch(getPublicPersonalizedBundles())
    }
  } catch (error) {
    console.log(error)
    dispatch(logout())
    dispatch(hidePopupById(EPopupTypes.LOADING_POPUP))
    dispatch(showPopupById(EPopupTypes.GENERAL_ERROR_POPUP))
  }
}

export const logout = (): any => async (dispatch: Dispatch<AppActions>) => {
  try {
    // logout
    localStorage.removeItem(EStorageKeys.isAdmin)
    await firebase.auth().signOut()
    dispatch(setIsLoggedIn(false))
    dispatch({ type: EAuthActionTypes.LOGOUT })
  } catch (error) {
    console.log(error.message)
  }
}

export const setUUID = (uuid: string | null): any => {
  return {
    type: EAuthActionTypes.SET_UUID,
    uuid: uuid || uuidv1(),
  }
}
