import React, { useState, useEffect } from "react"
import copy from "clipboard-copy"
import { useTranslation } from "react-i18next"
import { MonetizationStatus, Supervised } from "../../../../types/Child"
import { activeStatus } from "../../../../types/generalInterfaces"
import { DynaButton } from "../../../common/DynaButton"
import { Button } from "../../../common"

enum Estatus {
  SUBSCRIBED = "Subscribed",
  IN_TRIAL = "In trial",
  PAIRING_NEEDED = "Pairing needed",
}

interface IProps {
  supervised: Supervised
  onRefresh?: Function
  refreshing?: boolean
  loading?: boolean
}

const SQVStatus = ({
  supervised: {
    monetization: { status: monetizationStatus, paywallLink },
    supervisedUser,
    profile: { connectDetails },
  },
  onRefresh,
  refreshing,
  loading,
}: IProps) => {
  const [status, setStatus] = useState<Estatus>(Estatus.PAIRING_NEEDED)
  const { t } = useTranslation()

  useEffect(() => {
    if (monetizationStatus === MonetizationStatus.SUBSCRIBED) {
      setStatus(Estatus.SUBSCRIBED)
    } else if (
      supervisedUser &&
      supervisedUser.state === activeStatus.INACTIVE
    ) {
      setStatus(Estatus.PAIRING_NEEDED)
    } else if (
      monetizationStatus === MonetizationStatus.IN_TRIAL || // for now without the 14 days trial thingy
      (supervisedUser &&
        supervisedUser.state ===
          activeStatus.ACTIVE) /* && moment().diff(moment(supervisedUser.stateTimestamp), "days") < 14 */
    ) {
      setStatus(Estatus.IN_TRIAL)
    } else {
      setStatus(Estatus.PAIRING_NEEDED)
    }
  }, [])

  const renderRelevantLinkView = () => {
    if (status === Estatus.IN_TRIAL) {
      return (
        <DynaButton
          text={t("paymentLink")}
          customContainerStyle={{ margin: "0 auto" }}
          onClick={() => {
            M.toast({ html: t("linkCopied") })
            copy(paywallLink)
          }}
        />
      )
    }

    if (status === Estatus.SUBSCRIBED) {
      return (
        <span
          className="row"
          role="img"
          aria-label="ok"
          style={{ fontSize: "38px", fontWeight: "bold" }}
        >
          🤙
        </span>
      )
    }

    if (status === Estatus.PAIRING_NEEDED && connectDetails) {
      return (
        <div style={{ position: "relative" }}>
          <div className="smallText">{`${t("code")}: ${
            refreshing ? "- -" : connectDetails.code
          }`}</div>
          <DynaButton
            text={t("pairingLink")}
            onClick={() => {
              M.toast({ html: t("linkCopied") })
              copy(connectDetails.shortLink)
            }}
            customContainerStyle={{ margin: "0 auto" }}
          />
        </div>
      )
    }
  }

  return (
    <div style={{ flex: 1, position: "relative", padding: "0 20px" }}>
      <div className="smallText bold">
        {t(
          status === Estatus.IN_TRIAL
            ? "inTrial"
            : status === Estatus.SUBSCRIBED
            ? "subscribed"
            : "pairingNeeded"
        )}
      </div>
      {renderRelevantLinkView()}
      {onRefresh &&
        !loading &&
        status === Estatus.PAIRING_NEEDED &&
        connectDetails && (
          <button
            className="btn-flat waves-effect"
            style={{
              position: "absolute",
              right: 0,
              top: 0,
            }}
            onClick={(e) => {
              e.preventDefault()
              e.stopPropagation()
              onRefresh()
            }}
          >
            <i className="material-icons" style={{ fontSize: "36px" }}>
              refresh
            </i>
          </button>
        )}
    </div>
  )
}

SQVStatus.defaultProps = {
  onRefresh: null,
  refreshing: false,
  loading: false,
}

export default SQVStatus
