import React, { useEffect, useState, CSSProperties } from "react"
import { connect } from "react-redux"
import { Dispatch, bindActionCreators } from "redux"
// @ts-ignore
import { SpriteAnimator } from "react-sprite-animator"
// @ts-ignore
import { Textfit } from "react-textfit"
import { Content } from "../../../../types/Content"
import { RootState } from "../../../../store/reducers/rootReducer"
import {
  getImageURL,
  getSprites,
} from "../../../../store/actions/assetsActions"

export enum EContentViewSize {
  HEADER = "HEADER",
  SMALL = "SMALL",
}

interface IProps {
  content: Content
  size: EContentViewSize
  style?: CSSProperties
}

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  IProps

const ContentView = ({
  content,
  style,
  size,
  assetManager,
  getImageURL,
  getSprites,
}: Props) => {
  const { images, sprites } = assetManager

  useEffect(() => {
    // TODO - here disciss with tomer the assetManager recurring rendering issue he talked about
    if (content && content.sprite && content.sprite !== "") {
      if (!(sprites[content.sprite] && sprites[content.sprite].downloadURL)) {
        getSprites()
      }
    }
  }, [])

  return (
    <div
      className=""
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        flexDirection: "column",
        ...style,
      }}
    >
      {content.text !== "" && size === EContentViewSize.HEADER ? (
        <Textfit
          mode="multi"
          max={32}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            flexDirection: "column",
            ...style,
          }}
        >
          {content.text}
        </Textfit>
      ) : (
        // content.text
        content.text
      )}
      {content.sprite !== "" &&
        sprites[content.sprite] &&
        sprites[content.sprite].downloadURL && (
          <div style={{ width: "80px", height: "80px" }}>
            {/* <SpriteAnimator
            sprite={sprites[content.sprite].downloadURL}
            width={600}
            height={600}
            frameCount={36}
            wrapAfter={6}
            fps={25}
            scale={8}
          /> */}
          </div>
        )}
      {content.image !== "" &&
        images[content.image] &&
        images[content.image].downloadURL && (
          <img
            className="responsive-img"
            style={{ height: "60px" }}
            src={images[content.image].downloadURL}
          />
        )}
    </div>
  )
}

const mapStateToProps = (state: RootState) => {
  return {
    assetManager: state.assetManager,
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      getImageURL,
      getSprites,
    },
    dispatch
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(ContentView)
