import { createStore, applyMiddleware } from "redux"
import { persistStore, persistReducer } from "redux-persist"
import storage from "redux-persist/lib/storage"
import thunk, { ThunkMiddleware } from "redux-thunk"
import { composeWithDevTools } from "redux-devtools-extension"
import { rootReducer, RootState } from "../store/reducers/rootReducer"
import { AppActions } from "../types/actionTypes/actions"

let storeInstance: any = null

export default () => {
  if (storeInstance) {
    return storeInstance
  }

  const persistConfig = {
    key: "root",
    storage,
    whitelist: ["user"],
  }

  const persistedReducer = persistReducer(persistConfig, rootReducer)

  const store = createStore(
    persistedReducer,
    composeWithDevTools(
      applyMiddleware(thunk as ThunkMiddleware<RootState, AppActions>)
    )
  )

  persistStore(store)
  storeInstance = store

  return store
}
