import { EPopupTypes } from "../../types/Popups"

export const showPopupById = (popupId: EPopupTypes): any => async () => {
  const instance = getPopupInstanceById(popupId)
  instance && instance.open()
}

export const hidePopupById = (popupId: EPopupTypes): any => async () => {
  const instance = getPopupInstanceById(popupId)
  instance.close()
}

const getPopupInstanceById = (popupId: EPopupTypes) => {
  const elem = document.getElementById(popupId)
  return M.Modal.getInstance(elem as Element)
}
