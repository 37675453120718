import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { setIsRtl } from "../../locales/localesService"

const LocaleSelector = () => {
  const [selectedIndex, setSelectedIndex] = useState(0)
  const { t, i18n } = useTranslation()

  useEffect(() => {
    const selectedLocale = localStorage.getItem("selectedLocale")
    if (selectedLocale === "he") {
      setSelectedIndex(1)
      i18n.changeLanguage("he")
      setIsRtl(true)
    }
  }, [])

  return (
    <div>
      <button
        className="btn-flat waves-effect white-text noPadding"
        style={{ fontWeight: selectedIndex === 0 ? 800 : "normal" }}
        onClick={() => {
          setSelectedIndex(0)
          i18n.changeLanguage("en")
          localStorage.setItem("selectedLocale", "en")
          setIsRtl(false)
        }}
      >
        {t("en")}
      </button>
      <span style={{ marginRight: 5, marginLeft: 5 }}>|</span>
      <button
        className="btn-flat waves-effect white-text noPadding"
        style={{ fontWeight: selectedIndex === 1 ? 800 : "normal" }}
        onClick={() => {
          setSelectedIndex(1)
          i18n.changeLanguage("he")
          localStorage.setItem("selectedLocale", "he")
          setIsRtl(true)
        }}
      >
        {t("he")}
      </button>
    </div>
  )
}

export default LocaleSelector
