import React, { useEffect } from "react"
import { connect } from "react-redux"
import { ThunkDispatch } from "redux-thunk"
import { bindActionCreators } from "redux"
import { history } from "../App"
import { AppActions } from "../types/actionTypes/actions"
import {
  signInWithGoogle,
  signInWithFacebook,
} from "../store/actions/authActions"
import { RootState } from "../store/reducers/rootReducer"

interface IProps {}

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  IProps

const Login = ({ signInWithFacebook, signInWithGoogle, isLoggedIn }: Props) => {
  useEffect(() => {
    if (isLoggedIn) {
      history.push({
        pathname: "/students",
      })
    }
  }, [isLoggedIn])

  return (
    <div className="container center-align" style={{ paddingTop: "10%" }}>
      <div className="row center title">Welcome to Dynamo Dash</div>
      <div className="row">
        <button
          className="waves-effect waves-light btn push-s2"
          style={styles.button}
          onClick={() => signInWithFacebook()}
        >
          <img
            className="responsive-img"
            style={{ width: 309, height: 53 }}
            src={require("../assets/images/facebook_buton.png")}
          />
        </button>
      </div>

      <div className="row">
        <button
          className="waves-effect waves-light btn push-s2"
          style={styles.button}
          onClick={() => signInWithGoogle()}
        >
          <img
            className="responsive-img"
            style={{ width: 309, height: 53 }}
            src={require("../assets/images/google_button.png")}
          />
        </button>
      </div>
    </div>
  )
}

const styles = {
  button: {
    padding: 0,
    backgroundColor: "transparent",
    borderRadius: 10,
    height: 52,
  },
}

const mapStateToProps = (state: RootState) => {
  const {
    auth: { isLoggedIn },
  } = state

  return { isLoggedIn }
}

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AppActions>) => {
  return bindActionCreators(
    {
      signInWithGoogle,
      signInWithFacebook,
    },
    dispatch
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(Login)
