import React from "react"
import { NavLink } from "react-router-dom"
import { connect } from "react-redux"
import { ThunkDispatch } from "redux-thunk"
import { bindActionCreators } from "redux"
import avatar from "../../assets/images/av_1_on.png"
import { User } from "../../types/User"
import SQVPhotoName from "../child/Students/StudentQuickView/SQVPhotoName"
import { RootState } from "../../store/reducers/rootReducer"
import { AppActions } from "../../types/actionTypes/actions"
import { login } from "../../store/actions/authActions"

interface IProps {
  supervisor: User
}

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  IProps

const SupervisorQuickView = ({ supervisor, login }: Props) => {
  return (
    <NavLink
      style={{ color: "black" }}
      onClick={() =>
        login(supervisor.email, supervisor.firstName, supervisor.lastName)
      }
      to={{
        pathname: "/",
      }}
    >
      <div className="row grey lighten-3" style={{ height: "146px" }}>
        <div
          className="col s4 l2"
          style={{ paddingTop: "10px", height: "100%" }}
        >
          <SQVPhotoName
            nickname={`${supervisor.firstName} ${supervisor.lastName}`}
            photoUrl={supervisor.photoUrl || avatar}
            status={undefined}
            showStatus={false}
          />
        </div>
        <div
          className="col l2 hide-on-med-and-down"
          style={{ paddingTop: "10px", height: "100%" }}
        >
          {`${supervisor.firstName} ${supervisor.lastName}`}
        </div>
        <div
          className="col s4 l3"
          style={{ paddingTop: "10px", height: "100%" }}
        >
          <div>{supervisor.email}</div>
        </div>
        <div
          className="col l2 hide-on-med-and-down"
          style={{ paddingTop: "10px", height: "100%" }}
        />
        <div
          className="col l1 hide-on-med-and-down"
          style={{ paddingTop: "10px", height: "100%" }}
        />
        <div
          className="col s4 l2"
          style={{ paddingTop: "10px", height: "100%" }}
        />
      </div>
    </NavLink>
  )
}

const mapStateToProps = (state: RootState) => {
  const {} = state

  return {}
}

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AppActions>) => {
  return bindActionCreators(
    {
      login,
    },
    dispatch
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(SupervisorQuickView)
