import React, { useEffect } from "react"
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { AppActions } from "../../../types/actionTypes/actions";
import { bindActionCreators } from "redux";
import { updateSelectedBundleId } from "../../../store/actions/bundleActions"
import { addRemoveBundlesToChild } from "../../../store/actions/childActions"
import { Bundle } from "../../../types/Bundle";
import { showPopupById, hidePopupById } from "../../../store/actions/popupActions"
import { EPopupTypes } from "../../../types/Popups";
import SelectSupervisedPopup from "../../layout/popups/SelectSupervisedPopup";
import { packageActionType, questionPackageActionInterface } from "../../../types/generalInterfaces";

interface IProps {
  bundle: Bundle,
  isEditable: boolean
}

type Props = ReturnType<typeof mapDispatchToProps> & IProps

const BundleQuickRowView = ({
  bundle,
  isEditable,
  updateSelectedBundleId,
  addRemoveBundlesToChild
}: Props) => {

  useEffect(() => {
    var modalelems = document.querySelectorAll('.modal');
    M.Modal.init(modalelems);
  }, [])

  return (<>
    <NavLink style={{ color: 'black' }}
      onClick={() => updateSelectedBundleId({ bundleId: bundle.id, isEditable })}
      to={{
        pathname: "/bundle",
      }}
    >
      <div style={{height:"146px",display:"flex" ,backgroundColor:"#eee",marginBottom:"10px"}}>
        <div style={{display:"flex",flex:1,justifyContent:"center",alignItems:"center"}}>
          <img className="responsive-img" style={{ maxHeight: "80%" }} alt="" src={bundle.imageUrl} />
        </div>
        <div style={{display:"flex",flex:2,justifyContent:"flexStart",alignItems:"center",fontSize:"40px",textOverflow:"ellipsis",overflow:"hidden",overflowWrap:"break-word"}}>
          {bundle.name}
        </div>
        <div style={{ display:"flex",flex: 1, justifyContent: "center", alignItems: "center", }}>
          <div className="btn" style={{display:"flex"}}
            onClick={(e) => {
              e.preventDefault();
              const elem = document.getElementById(bundle.id)
              const instance = M.Modal.getInstance(elem as Element)
              instance && instance.open()
            }
            }
          >
            Addto
        </div>
        </div>

      </div>
      
      
      
      {/* <div className="row grey lighten-3" style={{ height: "146px" }}>
        <div className="col s4 l2" style={{ paddingTop: "10px", height: "100%" }}>
          <div className="" style={{ position: "relative" }}>
            <div className="center" style={{ width: "80px", paddingTop: "11px", marginLeft: "auto", marginRight: "auto" }}>
              <img className="responsive-img" style={{ maxHeight: "100%" }} alt="" src={bundle.imageUrl} />
            </div>
            <div className="center">
              {bundle.name}
            </div>
          </div>
        </div>
        <div className="col l2 hide-on-med-and-down" style={{ paddingTop: "10px", height: "100%" }}>
          <div className="center">
            <span className="row ">Total questions</span>
            <span className="row" style={{ fontSize: "39px", fontWeight: "bold" }}>376</span>
          </div>
        </div>
        <div className="col s4 l2" style={{ paddingTop: "10px", height: "100%" }}>
          <div className="center">
            <span className="row ">Grades</span>
            <span className="row" style={{ fontSize: "39px", fontWeight: "bold" }}>376</span>
          </div>
        </div>
        <div className="col l2 hide-on-med-and-down" style={{ paddingTop: "10px", height: "100%" }}>
          <div className="center">
            <span className="row ">Languages</span>
            <span className="row" style={{ fontSize: "39px", fontWeight: "bold" }}>376</span>
          </div>
        </div>
        <div className="col l2 hide-on-med-and-down" style={{ paddingTop: "10px", height: "100%" }}>
          <div className="center">
            <span className="row ">Last update</span>
            <span className="row" style={{ fontSize: "39px", fontWeight: "bold" }}>376</span>
          </div>
        </div>
        <div className="col s4 l2" style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "100%" }}>
          <div className="btn"
            onClick={(e) => {
              e.preventDefault();
              const elem = document.getElementById(bundle.id)
              const instance = M.Modal.getInstance(elem as Element)
              instance && instance.open()
            }
            }
          >
            Addto
        </div>
        </div>
      </div> */}
    </NavLink>
    <SelectSupervisedPopup
      id={bundle.id}
      bundle={bundle}
      submitActions={(actions: { [key: string]: packageActionType }) => {
        console.log(actions);
        const elem = document.getElementById(bundle.id)
        const instance = M.Modal.getInstance(elem as Element)
        instance.close()
        //now need to add and remove the actions for this bundleId
        for (const [supervisedId, value] of Object.entries(actions)) {
          const addremoveBundle = { id: bundle.id, action: value } as questionPackageActionInterface
          addRemoveBundlesToChild(supervisedId, [addremoveBundle])
        }

      }}
    />
  </>
  )
}

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AppActions>) => {
  return bindActionCreators(
    {
      updateSelectedBundleId,
      showPopupById,
      addRemoveBundlesToChild
    },
    dispatch
  )
}

export default connect(null, mapDispatchToProps)(BundleQuickRowView)
