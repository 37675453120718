import { v4 as uuidv4 } from "uuid"
import moment from "moment"
import * as _ from "lodash"
import { ContentData } from "../types/ContentData"
import {
  contentTranslationType,
  SourceType,
  contentTypeType,
  layoutTypes,
} from "../types/generalInterfaces"
import { AnswerOptionsContent, Content } from "../types/Content"
import { Question } from "../types/Question"
import config from "../configs/config"
import { dailyReportInterface } from "../types/Child"

export const getSessionUser = () => {
  const user = window.sessionStorage.getItem(
    `firebase:authUser:${config.firebase.apiKey}:[DEFAULT]`
  )
  return JSON.parse(user as string)
}

export const initQuestion = ({
  layoutType,
  creatorName,
  difficultyLevel,
}: {
  layoutType: layoutTypes
  creatorName: string
  difficultyLevel: number
}) => {
  const q: Question = {
    id: uuidv4(),
    source: SourceType.BUNDLER,
    contentType: contentTypeType.QUESTION,
    layoutType,
    difficultyLevel,
    contentData: initContentData({
      questionText: "",
      sprite: "",
      answers: [{ correct: true, text: "" }],
    }),
    packageId: "",
    kingdomId: "",
    creatorName,
    version: getQuestionVersionForLayout(layoutType),
    sourceId: "",
    practicedLanguage: "",
  }
  return q
}

export const getQuestionVersionForLayout = (
  layoutType: layoutTypes
): number => {
  let version = 1
  switch (layoutType) {
    case layoutTypes.DID_YOU_KNOW_VERTICAL_A:
      version = 2
      break
    case layoutTypes.BE_INTERNET_AWESOME_VERTICAL_A:
      version = 3
      break
    case layoutTypes.HORIZONTAL_IMAGE_320PX_1_TO_4_ANSWERS_NO_BACKGROUND:
    case layoutTypes.VERTICAL_TEXT_ONE_LINE_1_TO_4_ANSWERS_GREEN_BACKGROUND:
    case layoutTypes.VERTICAL_TEXT_ONE_LINE_1_TO_4_ANSWERS_WHITE_BACKGROUND:
    case layoutTypes.VERTICAL_TEXT_TWO_LINES_1_TO_4_ANSWERS_GREEN_BACKGROUND:
    case layoutTypes.VERTICAL_TEXT_TWO_LINES_1_TO_4_ANSWERS_WHITE_BACKGROUND:
    case layoutTypes.HORIZONTAL_TWO_ROWS_IMAGE_220PX_AND_BOTTOM_TEXT_1_TO_8_ANSWERS_GREEN_BACKGROUND:
    case layoutTypes.HORIZONTAL_TWO_ROWS_IMAGE_220PX_AND_BOTTOM_TEXT_1_TO_8_ANSWERS_WHITE_BACKGROUND:
      version = 4
      break
    case layoutTypes.GENERIC_DID_YOU_KNOW:
      version = 5
      break
    default:
      version = 1
      break
  }
  return version
}

export const initContentData = ({
  questionText,
  sprite,
  answers,
}: {
  questionText: string
  sprite: string
  answers: { correct: boolean; text: string }[]
}) => {
  const contentData = new ContentData()
  let isFunFact = false
  contentData.translation = contentTranslationType.NO_TRANSLATION
  if (sprite) {
    contentData.sprite = sprite
    isFunFact = true
  }
  contentData.text = questionText
  contentData.image = ""
  contentData.sound = ""
  contentData.sprite = sprite || ""
  contentData.id = uuidv4()
  contentData.answers = answers.map((answer) => {
    return initAnswer(answer, isFunFact)
  })
  contentData.notification = new Content()
  contentData.notification.translation = contentTranslationType.NO_TRANSLATION
  contentData.notification.text = ""
  contentData.notification.image = ""
  contentData.notification.sound = ""
  contentData.notification.id = uuidv4()
  return contentData
}

export const initAnswer = (
  answerData: { correct: boolean; text: string },
  isFunFact: boolean
): AnswerOptionsContent => {
  const answer = new AnswerOptionsContent()
  answer.correct = answerData.correct
  answer.text = answerData.text
  answer.sound = isFunFact ? "fun_fact.mp3" : ""
  answer.image = ""
  answer.id = uuidv4()
  answer.translation = contentTranslationType.NO_TRANSLATION
  return answer
}

export const duplicateQuestion = (question: Question): Question => {
  const q = _.cloneDeep(question)
  q.id = uuidv4()
  q.contentData.id = uuidv4()
  q.contentData.answers.forEach((answer) => {
    answer.id = uuidv4()
  })
  return q
}

export const getTimeByFormat = (timestamp: number, format: string) =>
  moment(timestamp).format(format)

export const getTotalAnswersLastWeek = (dailys: dailyReportInterface) => {
  const lastWeekDailysKeys: string[] = Object.keys(dailys).filter((key) =>
    moment(key, "DD-MM-YYYY").isAfter(moment().subtract("days", 7))
  )
  let totalAnswersLastWeek = 0
  lastWeekDailysKeys.forEach((key: string) => {
    totalAnswersLastWeek += dailys[key].totalAnswers
  })
  return totalAnswersLastWeek
}

export const getJoinDate = (dailys: dailyReportInterface) => {
  let joinDate = moment()
  Object.keys(dailys).forEach((key: string) => {
    const currentDate = moment(key, "DD-MM-YYYY")
    if (currentDate.isBefore(joinDate)) {
      joinDate = currentDate
    }
  })
  return joinDate.format("dddd, MMMM Do YYYY")
}

export const validateAndChnageFilenameForStorage = (
  imageName: string,
  userId: string
): string => {
  // remove any dots or slashs from the file name can not contain ".", "#", "$", "[", or "]"
  return `${userId}__${imageName.replace(
    /([#$\s\[\]]|(?:[.](?![a-z0-9]+$)))/g,
    ""
  )}`
}

export const hideSideBar = (pathName: string) => {
  if (pathName.indexOf("payments/subscription") !== -1) {
    return true
  }
  return false
}
