import { createSelector } from "reselect"
import { RootState } from "../reducers/rootReducer"
import { Bundle } from "../../types/Bundle"

const getAllKingdoms = (state: RootState) => state.kingdoms.kingdoms
const getPublicPersonalizedBundles = (state: RootState) =>
  state.kingdoms.publicPersonalizedBundles
const getPersonalizedBundles = (state: RootState) =>
  state.user.personalizedBundles

export const getKingdomBundlesByIds = createSelector(
  [getAllKingdoms],
  (allKingdoms) => {
    if (!allKingdoms) return {}
    const allBundles: Array<Bundle> = allKingdoms.reduce(
      (res: Array<Bundle>, kingdom) => {
        return [...res, ...kingdom.packages]
      },
      []
    )

    const result: { [key: string]: Bundle } = allBundles.reduce((res, item) => {
      return {
        ...res,
        [item.id]: item,
      }
    }, {})
    return result
  }
)

export const getPersonalizedBundlesByIds = createSelector(
  [getPersonalizedBundles],
  (personalizedBundles) => {
    if (!personalizedBundles) return {}
    const result: { [key: string]: Bundle } = personalizedBundles.reduce(
      (res, item) => {
        return {
          ...res,
          [item.id]: item,
        }
      },
      {}
    )
    return result
  }
)

export const getPublicPersonalizedBundlesByIds = createSelector(
  [getPublicPersonalizedBundles],
  (publicPersonalizedBundles) => {
    if (!publicPersonalizedBundles) return {}
    const result: { [key: string]: Bundle } = publicPersonalizedBundles.reduce(
      (res, item) => {
        return {
          ...res,
          [item.id]: item,
        }
      },
      {}
    )
    return result
  }
)

export const getAllBundlesByIds = createSelector(
  [
    getPersonalizedBundlesByIds,
    getKingdomBundlesByIds,
    getPublicPersonalizedBundles,
  ],
  (
    personalizedBundlesByIds,
    kingdomBundlesByIds,
    publicPersonalizedBundles
  ) => {
    const res = {
      ...personalizedBundlesByIds,
      ...kingdomBundlesByIds,
    }

    publicPersonalizedBundles.forEach((publicPersonalizedBundle) => {
      if (!res[publicPersonalizedBundle.id]) {
        res[publicPersonalizedBundle.id] = publicPersonalizedBundle
      }
    })

    return res
  }
)
