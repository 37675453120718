import React from "react"

interface Props {
  url: string
  nickname: string
  mentorName: string
}

const useQuery = (url: string) => {
  return new URLSearchParams(url)
}

const Payment = ({ url, nickname, mentorName }: Props) => {
  const query = useQuery(url.replace(/[^\?]*(\?.*)/, "$1"))
  const price = query.get("sum")

  return (
    <div className="rtl center-align">
      <div style={{ fontSize: 24, color: "#7a7a7a" }}>
        {`על מנת לחבר את ${nickname} עם ${mentorName} נא להסדיר את התשלום כאן:`}
      </div>
      <div className="bold" style={{ fontSize: 24, color: "#7a7a7a" }}>
        {price ? `התשלום החודשי שלנו הוא ${price} שקלים` : "תשלום חודשי"}
      </div>
      <iframe title="Payment" height={455} width={370} src={url} />
    </div>
  )
}

export default Payment
