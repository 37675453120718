import React, { useState } from "react"
import { connect } from "react-redux"
import { ThunkDispatch } from "redux-thunk"
import { bindActionCreators } from "redux"
import { NavLink } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { RootState } from "../../store/reducers/rootReducer"
import { AppActions } from "../../types/actionTypes/actions"
import { SearchBox } from "../common"
import { DYNAMO_COLOR } from "../../constants/constants"

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>

const ContentCreatorView = (props: Props) => {
  const [searchTerm, setSearchTerm] = useState("")
  const { t } = useTranslation()
  return (
    <div className="contentContainer">
      <div className="header" style={{ justifyContent: "space-between" }}>
        <div className="title bold">{t("students")}</div>

        <div style={{ display: "flex" }}>
          <NavLink
            className="btn addStudentsBtn dynamoBackground"
            style={{
              display: "flex",
              alignItems: "center",
              background: DYNAMO_COLOR,
              height: "50px",
            }}
            to="/createBundle"
          >
            <i className="material-icons left">add_circle</i>
            <span>{t("createNewBundle")}</span>
          </NavLink>
          <SearchBox
            value={searchTerm}
            placeholder="Search"
            onChange={(value: string) => setSearchTerm(value)}
          />
        </div>
      </div>

      {/* {gettingStudents ? (
        <Loader />
      ) : (
        students.map((sup) => {
          return <StudentQuickView supervised={sup} key={sup.id} />
        })
      )}

      {searchTerm.length === 0 && (
        <AddStudentListItemBtn
          onClick={() =>
            history.push({
              pathname: "/createChild",
            })
          }
          title={t("addNewStudent")}
          tutorialText="Click here to add your first student."
          showTutorial={supervised.length === 0}
        />
      )}

      {searchTerm.length !== 0 && students.length === 0 && (
        <div className="title bold center" style={{ marginTop: 100 }}>
          No results
        </div>
      )} */}
    </div>
  )
}

const mapStateToProps = (state: RootState) => {
  const {} = state

  return {}
}

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AppActions>) => {
  return bindActionCreators({}, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(ContentCreatorView)
