import React from "react"

interface IProps {
  title: string
  value?: number
}

const SQVTotalQuestions = ({ title, value }: IProps) => {
  return (
    <div className="center">
      <span className="row">{title}</span>
      <span className="row title bold">{value || "- -"}</span>
    </div>
  )
}

export default SQVTotalQuestions
