import * as _ from "lodash"
import { Kingdom } from "../../types/Kingdom"
import {
  KingdomActionTypes,
  EKingdomActionsTypes,
} from "../../types/actionTypes/KingdomActionTypes"
import { Bundle } from "../../types/Bundle"

const kingdomReducerDefaultState: {
  kingdoms: Kingdom[]
  publicPersonalizedBundles: Bundle[]
} = {
  kingdoms: [],
  publicPersonalizedBundles: [],
}

const kingdomsReducer = (
  state = kingdomReducerDefaultState,
  action: KingdomActionTypes
): { kingdoms: Kingdom[]; publicPersonalizedBundles: Bundle[] } => {
  switch (action.type) {
    case EKingdomActionsTypes.GET_KINGDOMS:
      return { ...state }
    case EKingdomActionsTypes.UPDATE_KINGDOMS:
      return { ...state, kingdoms: action.kingdoms }
    case EKingdomActionsTypes.GET_QUESTIONS_FOR_PACKAGEID_KINGDOM_SUCCESS:
      const { kingdomId, bundleId, questions } = action
      const kingdomsCopy = _.cloneDeep(state.kingdoms)
      const publicPersonalizedBundlesCopy = _.cloneDeep(
        state.publicPersonalizedBundles
      )
      const index = _.findIndex(kingdomsCopy, (k) => k.id === kingdomId)
      // if index == -1 this is not found.
      if (index !== -1) {
        const kingdom = kingdomsCopy[index]
        const pIndex = _.findIndex(kingdom.packages, (p) => p.id === bundleId)
        if (pIndex !== -1) {
          kingdomsCopy[index].packages[pIndex].questions = questions
        }
      } else {
        const pIndex = _.findIndex(
          publicPersonalizedBundlesCopy,
          (p) => p.id === bundleId
        )
        if (pIndex !== -1) {
          publicPersonalizedBundlesCopy[pIndex].questions = questions
        }
      }

      return {
        ...state,
        kingdoms: kingdomsCopy,
        publicPersonalizedBundles: publicPersonalizedBundlesCopy,
      }
    case EKingdomActionsTypes.GET_PUBLIC_PERSONALIZED_BUNDLES_SUCCESS: {
      const { bundels } = action
      return { ...state, publicPersonalizedBundles: bundels }
    }
    default:
      return state
  }
}

export { kingdomsReducer }
