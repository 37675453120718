export enum EMonetizationStatus {
  pending = "PENDING",
  subscribed = "SUBSCRIBED",
}

export interface SupervisorSubscriptionInfo {
  supervisedName: string
  supervisor: {
    email: string
    firstName: string
    lastName: string
    photoUrl?: string
  }
  monetizationStatus: EMonetizationStatus
  monetizationLink: string
}
