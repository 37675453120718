import { Dispatch } from "redux"
import firebase from "../../configs/fbConfig"
import { history } from "../../App"
import {
  requestWrapper,
  requestWrapper2,
} from "../../services/ServerConnection"
import { AppActions } from "../../types/actionTypes/actions"
import { EChildActionTypes } from "../../types/actionTypes/ChildActionTypes"
import { LastAnswer } from "../../types/LastAnswer"
import {
  Supervised,
  ChildInstallLinkResponseInterface,
} from "../../types/Child"
import { questionPackageActionInterface } from "../../types/generalInterfaces"
import { RootState } from "../reducers/rootReducer"
import { getTotalAnswersLastWeek, getJoinDate } from "../../utils/utils"
import {
  getSupervisedList,
  addChildToFamilyRequestInterface,
} from "./userActions"
import { getImageURL } from "./assetsActions"
import { EUserActionTypes } from "../../types/actionTypes/UserActionTypes"
import { showPopupById, hidePopupById } from "./popupActions"
import { EPopupTypes } from "../../types/Popups"

export const updateSelectedSupervisedId = (supervisedId: string): any => (
  dispatch: Dispatch<AppActions>,
  getState: () => RootState
) => {
  const {
    child: { selectedSupervisedId },
    user: { supervised },
  } = getState()

  // get child data
  dispatch(getSupervisedList(supervisedId))

  const currentSelectedSupervised = supervised.find(
    (sup) => sup.id === supervisedId
  )
  const prevSelectedSupervised = supervised.find(
    (sup) => sup.id === selectedSupervisedId
  )
  // get child last answers for non pending child
  currentSelectedSupervised &&
    currentSelectedSupervised.supervisedUser &&
    dispatch(
      getChildAnswers({
        supervisedId,
        childId: currentSelectedSupervised.supervisedUser.id,
        resetLastAnswers: true,
      })
    )

  dispatch({
    type: EChildActionTypes.UPDATE_SELECTED_CHILD_ID,
    supervisedId,
  })

  // remove previous child database listener
  if (prevSelectedSupervised && prevSelectedSupervised.supervisedUser) {
    removeChildDBListeners(prevSelectedSupervised)
  }

  // set current child database listener
  currentSelectedSupervised &&
    currentSelectedSupervised.supervisedUser &&
    dispatch(setChildDBListeners(currentSelectedSupervised))
}

export const clearSelectedSupervisedId = () => (
  dispatch: Dispatch<AppActions>
) => {
  dispatch({
    type: EChildActionTypes.CLEAR_SELECTED_SUPERVISED_ID,
  })
}

export const addRemoveBundlesToChild = (
  supervisedId: string,
  addRemoveArray: questionPackageActionInterface[]
) => async (dispatch: Dispatch<AppActions>) => {
  try {
    dispatch({
      type: EChildActionTypes.ADD_REMOVE_PACKAGES_START,
      supervisedId,
    })
    // childId, parentId, familyId, text,sendAtTimestamp,parentType
    await requestWrapper2("addRemovePackages", {
      supervisedId,
      questionsPackageActions: addRemoveArray,
    })
    dispatch({
      type: EChildActionTypes.ADD_REMOVE_PACKAGES_SUCCESS,
      supervisedId,
    })
    dispatch(getSupervisedList(supervisedId))
  } catch (error) {
    console.log(error)
  }
}

export interface getChildAnswersActionInterface {
  supervisedId: string
  childId: string
  resetLastAnswers: boolean
  toTimestamp?: number
}

export interface getChildAnswersRequestInterface {
  childId: string
  toTimestamp?: number
}

export interface ChildAnswersResponseInterface {
  lastAnswers: LastAnswer[]
}

export const getChildAnswers = ({
  supervisedId,
  childId,
  resetLastAnswers,
  toTimestamp,
}: getChildAnswersActionInterface): any => async (
  dispatch: Dispatch<AppActions>
) => {
  try {
    dispatch({
      type: EChildActionTypes.GET_CHILD_LAST_ANSWERS_START,
      supervisedId,
    })
    // nickname, parentId,age, familyId, questionsPackageActions, installId, appVersionName, appVersionNumber,automaticPackageSelection,anonymousCId, gender,lockScreenFrequency,parentType,manualLocale,enabledLockScreen
    const reqData: getChildAnswersRequestInterface = { childId }
    if (toTimestamp) {
      reqData.toTimestamp = toTimestamp
    }
    const res = await requestWrapper("getLastAnswers", reqData)
    const { lastAnswers } = res.data as ChildAnswersResponseInterface

    // get image urls
    lastAnswers.forEach((question) => {
      const {
        question: {
          contentData: { answers, image },
        },
      } = question
      if (image && image.length > 0) {
        dispatch(getImageURL(image))
      }

      answers.forEach((answer) => {
        const { image: answerImage } = answer
        if (answerImage && answerImage.length > 0) {
          dispatch(getImageURL(answerImage))
        }
      })
    })

    dispatch({
      type: EChildActionTypes.GET_CHILD_LAST_ANSWERS_SUCCESS,
      supervisedId,
      lastAnswers,
      resetLastAnswers,
    })
  } catch (error) {
    console.log(error)
  }
}

export const setChildDBListeners = (selectedSupervised: Supervised): any => (
  dispatch: Dispatch<AppActions>
) => {
  const child = selectedSupervised.supervisedUser || null
  if (child) {
    firebase
      .database()
      .ref(`family/${child.familyId}/${child.id}`)
      .on(
        "value",
        (snapshot) => {
          const { currentStatus, answersStats } = snapshot.val()
          const { dailys } = answersStats
          dispatch({
            type: EChildActionTypes.GET_CHILD_DATA_SUCCESS,
            childCurrentStatus: {
              supervisedId: selectedSupervised.id,
              data: {
                ...currentStatus,
                totalAnswers: answersStats.totalAnswers,
                totalAnswersToday: answersStats.totalAnswersToday,
                totalAnswersLastWeek: getTotalAnswersLastWeek(dailys),
                joinDate: getJoinDate(dailys),
              },
            },
          })
        },
        (e: any) => {
          console.error("firebase database failed", e)
        }
      )
  }
}

export const removeChildDBListeners = (selectedSupervised: Supervised): any => {
  const child = selectedSupervised.supervisedUser || null
  if (child) {
    firebase.database().ref(`family/${child.familyId}/${child.id}`).off()
  }
}

// export const setPendingChildrenListeners = (): any => (
//   dispatch: Dispatch<AppActions>,
//   getState: () => RootState
// ) => {
//   const {
//     user: { children },
//   } = getState()

//   children.forEach((child) => {
//     const { familyId, id, state } = child
//     if (state === activeStatus.PENDING) {
//       firebase
//         .database()
//         .ref(`family/${familyId}/${id}/currentStatus/lastSeen`)
//         .on("value", (snapshot) => {
//           const lastSeen = snapshot.val()

//           if (lastSeen !== null) {
//             firebase
//               .database()
//               .ref(`family/${familyId}/${id}/currentStatus/lastSeen`)
//               .off()
//             dispatch(getSupervisedList(child.id))
//           }
//         })
//     }
//   })
// }

export const getSupervisedInstallLink = (supervisedId: string): any => async (
  dispatch: Dispatch<AppActions>
) => {
  try {
    console.log(supervisedId)
    dispatch({
      type: EChildActionTypes.GET_CHILD_CONNECT_DETAILS_START,
      supervisedId,
    })
    // nickname, parentId,age, familyId, questionsPackageActions, installId, appVersionName, appVersionNumber,automaticPackageSelection,anonymousCId, gender,lockScreenFrequency,parentType,manualLocale,enabledLockScreen
    const res = await requestWrapper2("getSupervisedInstallLink", {
      supervisedId,
    })
    const connectDetails = res.data as ChildInstallLinkResponseInterface
    dispatch({
      type: EChildActionTypes.GET_CHILD_CONNECT_DETAILS_SUCCESS,
      supervisedId,
      connectDetails,
    })
  } catch (error) {
    dispatch({
      type: EChildActionTypes.GET_CHILD_CONNECT_DETAILS_FAIL,
      supervisedId,
    })
    console.error(error)
  }
}

export const editSupervisedData = (
  reqData: addChildToFamilyRequestInterface,
  supervisedId: string
): any => async (dispatch: Dispatch<AppActions>) => {
  try {
    dispatch({
      type: EUserActionTypes.ADD_CHILD_IN_PROGRESS,
    })

    dispatch(showPopupById(EPopupTypes.LOADING_POPUP))
    const data = {
      supervisedId,
      ...reqData,
    }

    await requestWrapper2("editSupervisedData", data)
    dispatch(updateSelectedSupervisedId(supervisedId))
    history.push({
      pathname: "/child",
    })
    dispatch(hidePopupById(EPopupTypes.LOADING_POPUP))
  } catch (error) {
    console.error(error)
  }
}
