import React, { useEffect, useState } from "react"
import { bindActionCreators, Dispatch } from "redux"
import { connect } from "react-redux"
import { none } from "ramda"
import ImagesSideBar from "./ImagesSideBar"
import { RootState } from "../../../../store/reducers/rootReducer"
import { getImageURL } from "../../../../store/actions/assetsActions"

interface Props {
  updateImageSelected: Function
  imageName: string
  contentId: string
  showAddButton?: boolean
}

type IProps = ReturnType<typeof mapDispatchToProps> &
  ReturnType<typeof mapStateToProps> &
  Props

const ImageSelector = ({
  updateImageSelected,
  imageName,
  images,
  contentId,
  getImageURL,
  showAddButton = true,
}: IProps) => {
  const [imageURL, setImageURL] = useState<string | null>()

  useEffect(() => {
    if (images[imageName] && images[imageName].downloadURL) {
      setImageURL(images[imageName].downloadURL)
    } else {
      getImageURL(imageName)
    }
  }, [imageName, images])

  const openCreateSideBar = () => {
    const elem = document.getElementById(`images-slide-out-${contentId}`)
    const instance = M.Sidenav.init(elem!, { edge: "right" }) // M.Sidenav.getInstance(elem as Element)
    instance.open()
  }

  const selectImageButton = () => {
    return (
      <button
        type="button"
        className="btn-floating btn-small green sidenav-trigger"
        onClick={() => openCreateSideBar()}
      >
        <i className="material-icons">add</i>
      </button>
    )
  }

  return (
    <div className="row" style={{ marginBottom: 0 }}>
      <div className="col s12 center" style={{ padding: 0 }}>
        <button
          type="button"
          style={{
            border: "none",
            backgroundColor: imageName ? "transparent" : "#b4b3b3",
            borderRadius: "10px",
            width: 80,
            height: 80,
            padding: 0,
          }}
          className=""
          onClick={() => openCreateSideBar()}
        >
          {imageURL ? (
            <img className="img" width="80px" src={imageURL} />
          ) : (
            <i
              className="material-icons medium"
              style={{
                color: "#eeeeee",
                backgroundColor: "#b4b3b3",
              }}
            >
              add
            </i>
          )}
        </button>
      </div>
      <ImagesSideBar
        contentId={contentId}
        updateImageSelected={(sName: string) => {
          updateImageSelected(sName)
        }}
      />
    </div>
  )
}

const mapStateToProps = (state: RootState) => {
  const {
    assetManager: { images },
  } = state
  return {
    images,
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      getImageURL,
    },
    dispatch
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(ImageSelector)
