import * as _ from "lodash"
import { User } from "../../types/User"
import {
  UserActionTypes,
  EUserActionTypes,
} from "../../types/actionTypes/UserActionTypes"
import {
  AuthActionTypes,
  EAuthActionTypes,
} from "../../types/actionTypes/AuthActionsTypes"
import { EUserType } from "../../types/generalInterfaces"
import { Supervised } from "../../types/Child"
import {
  BundleActionTypes,
  EBundleActionsTypes,
} from "../../types/actionTypes/BundleActionTypes"
import {
  ChildActionTypes,
  EChildActionTypes,
} from "../../types/actionTypes/ChildActionTypes"

const userReducerDefaultState: User = {
  id: "",
  firstName: "",
  lastName: "",
  email: "",
  familyId: "",
  supervised: [],
  supervisors: [],
  staticConnectCode: "",
  userType: EUserType.MENTOR,
  personalizedBundles: [],
  permissions: {},
}

const userReducer = (
  state = userReducerDefaultState,
  action:
    | UserActionTypes
    | AuthActionTypes
    | BundleActionTypes
    | ChildActionTypes
): User => {
  switch (action.type) {
    case EUserActionTypes.EDIT_USER: {
      return { ...action.user }
    }

    case EUserActionTypes.GET_CHILDREN_SUCCESS: {
      const { supervised } = action
      return { ...state, supervised }
    }

    case EUserActionTypes.GET_SUPERVISORS_SUCCESS: {
      const { supervisors } = action
      return { ...state, supervisors }
    }

    case EUserActionTypes.UPDATE_CHILDREN_DATA_SUCCESS: {
      const { supervised } = action
      const currentSupervisedObj: { [key: string]: Supervised } = {}
      state.supervised.forEach((sup) => {
        currentSupervisedObj[sup.id] = sup
      })

      supervised.forEach((sup) => {
        currentSupervisedObj[sup.id] = sup
      })
      const res = Object.values(currentSupervisedObj)

      return { ...state, supervised: res }
    }

    case EUserActionTypes.ADD_CHILD_IN_PROGRESS: {
      return { ...state }
    }

    case EUserActionTypes.ADD_CHILD_SUCCESS: {
      // TODO
      // const children = [...state.children]
      // // eslint-disable-next-line no-restricted-syntax
      // for (const child of children) {
      //   if (child.id === action.connectDetails.childId) {
      //     child.connectDetails = {
      //       code: action.connectDetails.code,
      //       link: action.connectDetails.childInstallLink,
      //     }
      //   }
      // }
      return { ...state }
    }

    case EUserActionTypes.GET_PERSONALIZED_BUNDLES_SUCCESS: {
      return { ...state, personalizedBundles: action.bundels }
    }

    case EBundleActionsTypes.UPDATE_QUESTIONS_SUCCES: {
      console.log("in the UPDATE_QUESTIONS_SUCCES in userReducer")
      const { bundleId, questions } = action
      const personalizedBundles = _.cloneDeep(state.personalizedBundles)
      const index = personalizedBundles.findIndex((p) => p.id === bundleId)
      // if index == -1 this is not found. Try a Kingdom package
      if (index === -1) {
        return { ...state }
      }
      personalizedBundles[index].questions = questions
      return { ...state, personalizedBundles }
    }

    case EUserActionTypes.GET_QUESTIONS_FOR_PACKAGEID_SUCCESS: {
      // order the questions by their difficultylevel
      const qs = _.orderBy(action.questions, ["difficultyLevel"])
      const { bundleId } = action
      const personalizedBundles = _.cloneDeep(state.personalizedBundles)
      const index = personalizedBundles.findIndex((p) => p.id === bundleId)
      // if index == -1 this is not found. Try a Kingdom package
      if (index === -1) {
        return { ...state }
      }
      personalizedBundles[index].questions = qs
      return { ...state, personalizedBundles }
    }

    case EUserActionTypes.UPDATE_USER_PHOTO_URL: {
      const { photoUrl } = action
      return { ...state, photoUrl }
    }

    case EAuthActionTypes.LOGOUT:
    case EAuthActionTypes.CLEAR_SENSITIVE_DATA: {
      return { ...userReducerDefaultState }
    }

    case EChildActionTypes.GET_CHILD_CONNECT_DETAILS_SUCCESS: {
      const { supervisedId, connectDetails } = action

      const selectedSupervised = state.supervised.find(
        (sup) => sup.id === supervisedId
      )

      if (selectedSupervised && selectedSupervised.profile) {
        selectedSupervised.profile.connectDetails = {
          code: connectDetails.code,
          shortLink: connectDetails.childInstallLink,
        }
      }

      const supervisedCopy = _.cloneDeep(state.supervised)

      return { ...state, supervised: [...supervisedCopy] }
    }

    default:
      return state
  }
}

export { userReducer }
