import React, { useEffect } from "react"
import { EPlatform } from "../../../../constants/constants"

const appleLogo = require("../../../../assets/images/appleLogo.png")
const androidLogo = require("../../../../assets/images/android.svg")

interface IProps {
  platform?: EPlatform
  hasApeareOnTop?: boolean
}

const SQVPlatform = ({ platform, hasApeareOnTop }: IProps) => {
  useEffect(() => {
    const elems = document.querySelectorAll(".tooltipped")
    M.Tooltip.init(elems)
  })

  const hasPlatform =
    platform === EPlatform.IOS || platform === EPlatform.ANDROID

  return (
    <div className="center" style={{ position: "relative", padding: 20 }}>
      {hasPlatform && (
        <i
          className="material-icons tooltipped"
          data-position="top"
          data-tooltip={`Has appear on top: ${!!hasApeareOnTop}`}
          style={{
            fontSize: 20,
            color: hasApeareOnTop ? "#59d859" : "#ff5757",
            position: "absolute",
            top: 10,
            right: 0,
          }}
        >
          {hasApeareOnTop ? "check_circle" : "error_outline"}
        </i>
      )}

      <span>Platform</span>
      <div className="title bold">
        {hasPlatform ? (
          <img
            style={{ height: "50px" }}
            src={platform === EPlatform.IOS ? appleLogo : androidLogo}
          />
        ) : (
          "- -"
        )}
      </div>
    </div>
  )
}

export default SQVPlatform
