import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { ThunkDispatch } from "redux-thunk"
import { bindActionCreators } from "redux"
import * as _ from "lodash"
import { RootState } from "../../store/reducers/rootReducer"
import { AppActions } from "../../types/actionTypes/actions"
import Loader from "../layout/Loader"
import BundleRowView from "./BundleChooser/BundleRowView"
import { getKingdoms } from "../../store/actions/kingdomActions"
import {
  packageStatusType,
  questionPackageActionInterface,
  packageActionType,
} from "../../types/generalInterfaces"
import { Bundle } from "../../types/Bundle"
import { Kingdom } from "../../types/Kingdom"

interface IProps {
  childBundles?: Bundle[]
  addRemoveBundlesToChild: Function
  addRemoveArray: questionPackageActionInterface[]
}

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  IProps

const ChangeBundles = (props: Props) => {
  const {
    kingdoms,
    personalizedBundles,
    addRemoveBundlesToChild,
    childBundles = [],
    addRemoveArray,
    gettingKingdomBundles,
    gettingPersonalizedBundles,
    gettingPublicPersonalizedBundles,
    publicPersonalizedBundles,
  } = props

  const [tempAddRemoveArray, setTempAddRemoveArray] = useState<
    questionPackageActionInterface[]
  >([])

  useEffect(() => {
    const elems = document.querySelectorAll(".tabs")
    M.Tabs.init(elems)
  }, [])

  useEffect(() => {
    setTempAddRemoveArray(addRemoveArray)
  }, [addRemoveArray])

  const childStatusForPackageId = (bundleId: string): packageStatusType => {
    const p = _.find(childBundles, (p) => {
      return p.id === bundleId
    })
    if (p) {
      return p.status
    }
    return packageStatusType.FRESH
  }

  const addRemoveBundle = (
    bundleId: string,
    isAdd: boolean,
    practicedLanguage?: string
  ) => {
    const action = isAdd ? packageActionType.ADD : packageActionType.REMOVE
    // firts find if there is a packageId for this that aleady exists
    const pIdExistsIndex = _.findIndex(addRemoveArray, (obj) => {
      return obj.id === bundleId
    })
    if (pIdExistsIndex !== -1) {
      // it exists
      // if the action is different than the one that exists, then this is a reset action and so remove the obj
      const a = addRemoveArray[pIdExistsIndex]
      if (a.action !== action) {
        const newArray = tempAddRemoveArray.filter(
          (_, i) => i !== pIdExistsIndex
        )
        setTempAddRemoveArray(newArray)
        addRemoveBundlesToChild(newArray)
        return
      }
    }
    // add the bundle to the array
    const o = { id: bundleId, action } as questionPackageActionInterface /// /TODO practiced language
    if (practicedLanguage) o.practicedLanguage = practicedLanguage

    const newBundlesArray = [...addRemoveArray, o]
    setTempAddRemoveArray(newBundlesArray)
    addRemoveBundlesToChild(newBundlesArray)
  }

  return (
    <div className="row">
      <div className="col s12">
        <ul className="tabs">
          <li className="tab col s4">
            <a href="#dynamoBundles">Dynamo Bundles</a>
          </li>
          <li className="tab col s4">
            <a href="#personalized">My Bundles</a>
          </li>
          <li className="tab col s4">
            <a href="#tutorBundles">Tutor Bundles</a>
          </li>
        </ul>
      </div>
      <div id="personalized" className="col s12">
        {gettingPersonalizedBundles && <Loader />}
        {personalizedBundles.map((p, index) => {
          return (
            <BundleRowView
              key={index}
              bundle={p}
              initState={childStatusForPackageId(p.id)}
              addRemoveBundle={addRemoveBundle}
            />
          )
        })}
      </div>
      <div id="dynamoBundles" className="col s12">
        {gettingKingdomBundles && <Loader />}
        {kingdoms.map((k) => {
          return k.packages.map((p, index) => {
            return (
              <BundleRowView
                key={index}
                bundle={p}
                initState={childStatusForPackageId(p.id)}
                addRemoveBundle={addRemoveBundle}
              />
            )
          })
        })}
      </div>
      <div id="tutorBundles" className="col s12">
        {gettingPublicPersonalizedBundles && <Loader />}
        {publicPersonalizedBundles.map((publicPersonalizedBundle, index) => {
          return (
            <BundleRowView
              key={index}
              bundle={publicPersonalizedBundle}
              initState={childStatusForPackageId(publicPersonalizedBundle.id)}
              addRemoveBundle={addRemoveBundle}
            />
          )
        })}
      </div>
    </div>
  )
}

const mapStateToProps = (state: RootState) => {
  const {
    kingdoms: { kingdoms, publicPersonalizedBundles },
    user: { personalizedBundles },
    loaders: {
      gettingKingdomBundles,
      gettingPersonalizedBundles,
      gettingPublicPersonalizedBundles,
    },
  } = state
  return {
    kingdoms,
    personalizedBundles,
    gettingKingdomBundles,
    gettingPersonalizedBundles,
    gettingPublicPersonalizedBundles,
    publicPersonalizedBundles,
  }
}

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AppActions>) => {
  return bindActionCreators(
    {
      getKingdoms,
    },
    dispatch
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(ChangeBundles)
