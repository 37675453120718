import React, { useState, useEffect } from 'react'
import { Question } from '../../../types/Question';
import ContentView, { EContentViewSize } from './layouts/ContentView';
import * as _ from 'lodash'
import { layoutTypes } from '../../../types/generalInterfaces';
const questionImage = require("../../../assets/images/question.png")



interface Iprops {
  question: Question
}

const QuestionPreview = ({ question }: Iprops) => {


  

  useEffect(() => {
    
  }, []);

  const renderAnswers = () => {
    let isVertical = true;
    let fontSize = "12px";
    switch (question.layoutType) {
      //vertical
      case layoutTypes.GENERIC_DID_YOU_KNOW:
      case layoutTypes.VERTICAL_TEXT_TWO_LINES_1_TO_4_ANSWERS_WHITE_BACKGROUND:
      case layoutTypes.DID_YOU_KNOW_VERTICAL_A:
      case layoutTypes.BE_INTERNET_AWESOME_VERTICAL_A:
      case layoutTypes.VERTICAL_TEXT_TWO_LINES_1_TO_4_ANSWERS_GREEN_BACKGROUND: {
        isVertical = true
        break
      }
      case layoutTypes.VERTICAL_TEXT_ONE_LINE_1_TO_4_ANSWERS_GREEN_BACKGROUND:
      case layoutTypes.VERTICAL_TEXT_ONE_LINE_1_TO_4_ANSWERS_WHITE_BACKGROUND: {
        isVertical = true
        fontSize = "25px"
        break;
      }
      //horizontal
      case layoutTypes.HORIZONTAL_IMAGE_320PX_1_TO_4_ANSWERS_NO_BACKGROUND:
      case layoutTypes.HORIZONTAL_TWO_ROWS_IMAGE_220PX_AND_BOTTOM_TEXT_1_TO_8_ANSWERS_GREEN_BACKGROUND:
      case layoutTypes.HORIZONTAL_TWO_ROWS_IMAGE_220PX_AND_BOTTOM_TEXT_1_TO_8_ANSWERS_WHITE_BACKGROUND:{
        isVertical = false;
        break;
      }
      default:
        //vertical
        isVertical = true
        break;
    }
    return <div style={{ 
        flexGrow: 3, 
        flexDirection: isVertical ? "column" : "row", 
        display: "flex", 
        flexWrap: "wrap",
        paddingLeft:"2px",
        paddingRight:"2px",
        justifyContent: isVertical ? "flex-start" :"center", 
        alignContent:isVertical ? "center" : "flex-start" }}>
          
          {question.contentData.answers.map(answer => {
            return (
              <div key={answer.id} style={{
                borderRadius:"5px",
                backgroundColor:"#c2ee6b",
                width: isVertical ? "90%" : "72px",
                color: "#455d16",
                fontSize: fontSize,
                wordBreak: "break-word",
                lineHeight: "18px",
                overflow: "hidden",
                textAlign: "center",
                //margin: "10px",
                marginBottom:"5px",
                marginTop:"5px", 
                height: isVertical ? "38px" : "72px"
              }}>
                <ContentView content={_.cloneDeep(answer)} size={EContentViewSize.SMALL} />
              </div>
            )
  
              })}
        </div>

}

return (
    <div style={{ position: "relative" }}>
      <img className="responsive-img" style={{ maxHeight: "100%" }} alt="" src={questionImage} />
      <div style={{
        display: "flex",
        flexDirection: "column",
        height: "82%",
        position: "absolute",
        top: "18%",
        width: "100%",
        paddingLeft:"16px",
        paddingRight:"16px"
      }}>
        <div style={{
          flexGrow: 1,
          fontWeight:"bold",
          color: "#155b60",
          fontSize: "1.5vw",
          wordBreak: "break-word",
          backgroundColor:"rgba(255,255,255,0.5",
          borderRadius:"5px",
          overflow: "hidden",
          textAlign: "center", 
          maxHeight: "80px"
        }}>
          <ContentView content={_.cloneDeep(question.contentData)} size={EContentViewSize.HEADER} />
        </div>
        {renderAnswers()}
        
      </div>


      
    </div>
  )
}

export default QuestionPreview



