import React, { useState } from "react"
import { EPopupTypes } from "../../../types/Popups"

interface Props {
  onClick: Function
}

const ManageSupervisorsPopup = ({ onClick }: Props) => {
  const [email, setEmail] = useState<string>("")
  const [firstName, setFirstName] = useState<string>("")
  const [lastName, setLastName] = useState<string>("")

  return (
    <div
      id={EPopupTypes.MANAGE_SUPERVISORS_POPUP}
      className="modal modal-fixed-footer"
    >
      <div className="modal-content">
        <h5
          className="black-text"
          style={{ textAlign: "center", marginBottom: "40px" }}
        >
          Enter supervisor details
        </h5>
        <div className="input-field" style={{ height: "auto" }}>
          <input
            value={email}
            type="text"
            id="email"
            onChange={(e: any) => {
              const { value } = e.target
              setEmail(value)
            }}
            required
          />
          <span
            className="helper-text red-text"
            data-error="Please enter supervisor email (required)"
            data-success=""
          />
          <label htmlFor="email">Email</label>
        </div>
        <div className="input-field" style={{ height: "auto" }}>
          <input
            value={firstName}
            type="text"
            id="firstName"
            onChange={(e: any) => {
              const { value } = e.target
              setFirstName(value)
            }}
            required
          />
          <span
            className="helper-text"
            data-error="Please enter supervisor first name (optional)"
            data-success=""
          />
          <label htmlFor="firstName">First Name</label>
        </div>
        <div className="input-field" style={{ height: "auto" }}>
          <input
            value={lastName}
            type="text"
            id="lastName"
            onChange={(e: any) => {
              const { value } = e.target
              setLastName(value)
            }}
            required
          />
          <span
            className="helper-text"
            data-error="Please enter supervisor last name (optional)"
            data-success=""
          />
          <label htmlFor="lastName">lastName</label>
        </div>
      </div>
      <div className="modal-footer" style={{ height: "auto" }}>
        <button
          onClick={() => {
            onClick(email, firstName, lastName)
            setEmail("")
            setFirstName("")
            setLastName("")
          }}
          className="btn-flat cyan white-text"
        >
          Agree
        </button>
      </div>
    </div>
  )
}

export default ManageSupervisorsPopup
