import _ from "lodash"
import { createSelector } from "reselect"
import { RootState } from "../reducers/rootReducer"
import { Bundle } from "../../types/Bundle"
import { getKingdomBundlesByIds } from "./kingdomsSelector"

const getPersonalizedBundles = (state: RootState) =>
  state.user.personalizedBundles
const getPublicPersonalizedBundles = (state: RootState) =>
  state.kingdoms.publicPersonalizedBundles
const getSelectedBundleId = (state: RootState) => state.bundle.selectedbundleId

export const getSelectedBundle = createSelector(
  [
    getPersonalizedBundles,
    getSelectedBundleId,
    getKingdomBundlesByIds,
    getPublicPersonalizedBundles,
  ],
  (
    personalizedBundles,
    selectedbundleId,
    kingdomBundlesByIds,
    publicPersonalizedBundles
  ) => {
    let selectedBundle = _.find(
      publicPersonalizedBundles,
      (publicPersonalizedBundle) => {
        return publicPersonalizedBundle.id === selectedbundleId
      }
    ) as Bundle

    if (!selectedBundle) {
      // search on kingdom bundles
      selectedBundle = kingdomBundlesByIds[selectedbundleId]
    }

    if (!selectedBundle) {
      selectedBundle = _.find(personalizedBundles, (personalizedBundle) => {
        return personalizedBundle.id === selectedbundleId
      }) as Bundle
    }

    return selectedBundle ? _.cloneDeep(selectedBundle) : null
  }
)
