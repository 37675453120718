import React, { useEffect } from 'react'
// @ts-ignore
import { SpriteAnimator } from 'react-sprite-animator'
import { connect } from 'react-redux';
import { RootState } from '../../../../store/reducers/rootReducer';
import { Dispatch, bindActionCreators } from 'redux';
import { getSprites } from '../../../../store/actions/assetsActions'





interface Props {
    updateSpriteSelected:Function
}


type IProps = ReturnType<typeof mapDispatchToProps> & ReturnType<typeof mapStateToProps> & Props

const SpriteSideBar = ({ updateSpriteSelected, getSprites,sprites }: IProps) => {

  
  useEffect(() => {
    getSprites()
  }, []);

  const onClickHandler = (spriteName: string) => {
    updateSpriteSelected(spriteName);
    //close the sideBar
    const elem = document.getElementById("sprite-slide-out")
    const instance = M.Sidenav.getInstance(elem as Element)
    instance.close();
  }
  
  
  const spriteView = (spriteURL: string,spriteName:string) => {
    
    return (
      <div key={spriteName} onClick={() => onClickHandler(spriteName)}>
      <SpriteAnimator
        sprite={spriteURL}
        width={600}
        height={600}
        frameCount={36}
        wrapAfter={6}
        fps={25}
        scale={4}
      />
      </div>
    )
  } 
  
  
  return (
    <div id="sprite-slide-out" className="sidenav">
          <div className="col s12">
            {
              Object.keys(sprites).map(key => {
                return spriteView(sprites[key].downloadURL!,key)
              })
            }
      </div>
    </div>
    
  )
}

const mapStateToProps = (state: RootState) => {
  return {
    sprites: state.assetManager.sprites
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => {

  return bindActionCreators(
    {
      getSprites
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(SpriteSideBar)



