import { contentTranslationType } from "./generalInterfaces"

export class Content {
  id: string = ""
  text: string = ""
  image: string = ""
  sound: string = ""
  sprite: string = ""
  autoTranslate: boolean = false
  translation: contentTranslationType = contentTranslationType.NO_TRANSLATION
}

export class AnswerOptionsContent extends Content {
  correct!: boolean
  action: AnswerAction | null = null
}

export class AnswerAction {
  type!: AnswerActionType
  inAppDestination?: InAppDestinationType
  externalUrl?: string
  shareAction?: ShareActionType
  shareImage?: string
  shareText?: string
  shareLinkType?: ShareLinkType
}

export enum AnswerActionType {
  IN_APP_NAVIGATION = "IN_APP_NAVIGATION",
  EXTERNAL_NAVIGATION = "EXTERNAL_NAVIGATION",
  SHARE = "SHARE",
  SKIP = "SKIP",
}

export enum InAppDestinationType {
  CONTENT = "CONTENT",
  STORE = "STORE",
  MAP = "MAP",
  BFF = "BFF",
}

export enum ShareActionType {
  SHARE_DID_YOU_KNOW = "SHARE_DID_YOU_KNOW",
  INTERNET_AWESOME = "INTERNET_AWESOME",
}

export enum ShareLinkType {
  NONE = "NONE",
  CHILD_REFERRER_LINK = "CHILD_REFERRER_LINK",
  CHILD_FRIEND_INVITE = "CHILD_FRIEND_INVITE",
  PARENT_INVITE = "PARENT_INVITE",
}
