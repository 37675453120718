import React from "react"
import { Bundle } from "../../../../types/Bundle"

interface IProps {
  packages: Bundle[] | undefined
}

const MAX_ACTIVE_BUNDLE_TO_SHOW = 3

const SQVActiveBundles = ({ packages }: IProps) => {
  const enabledPackages = packages || [] // ? packages.filter(pckg => pckg.status === packageStatusType.ENABLED) : []

  const getActiveBundles = () => {
    // get only the enabled bundles
    return enabledPackages
      .slice(0, MAX_ACTIVE_BUNDLE_TO_SHOW)
      .map((pckg, index) => {
        return (
          <div
            key={index}
            className="valign-wrapper"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              className="responsive-img"
              style={{
                width: "96%",
                maxWidth: "80px",
                height: "auto",
                margin: "0 2%",
              }}
              alt=""
              src={pckg.imageUrl}
            />
          </div>
        )
      })
  }

  return (
    <div className="center" style={{ height: "100%" }}>
      <span className="">Active bundles</span>
      <div
        className=""
        style={{
          display: "flex",
          flex: 1,
          height: "80%",
          justifyContent: "center",
        }}
      >
        {getActiveBundles()}
        {enabledPackages.length > MAX_ACTIVE_BUNDLE_TO_SHOW && (
          <div className="valign-wrapper">
            <div
              className="responsive-img"
              style={{
                width: "96%",
                height: "auto",
                margin: "0 2%",
                textAlign: "left",
              }}
            >
              <i className="material-icons">more_horiz</i>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default SQVActiveBundles
