const dev = {
  apiGateway: {
    URL: "https://us-central1-dynamo-dev-e7a25.cloudfunctions.net/api/",
    URL2: "https://us-central1-dynamo-dev-e7a25.cloudfunctions.net/api2/",
  },
  amplitude: {
    amplitudeAPIKey: "c5b02bcc63272fc2f1602aa7d014daf5",
  },
  firebase: {
    apiKey: "AIzaSyBiNM-MKGJTRZCme38_eJ7Cf3BRmH9XvC4",
    authDomain: "dynamo-dev-e7a25.firebaseapp.com",
    databaseURL: "https://dynamo-dev-e7a25.firebaseio.com",
    projectId: "dynamo-dev-e7a25",
    storageBucket: "dynamo-dev-e7a25.appspot.com",
    messagingSenderId: "669188164153",
    appId: "1:669188164153:web:2b5c86a2bcb02464ce9be8",
  },
  env: {
    baseUrl: "https://dynamo-dashboard-dev.firebaseapp.com",
  },
}

const stg = {
  apiGateway: {
    URL: "https://us-central1-dynamo-stg.cloudfunctions.net/api/",
    URL2: "https://us-central1-dynamo-stg.cloudfunctions.net/api2/",
  },
  amplitude: {
    amplitudeAPIKey: "296c27f1ee4aebcdab8b4a7863689fd1",
  },
  firebase: {
    apiKey: "AIzaSyBfZRkcWAzsmTzQR9wAJaZERLKBB8I49oE",
    authDomain: "dynamo-stg.firebaseapp.com",
    databaseURL: "https://dynamo-stg.firebaseio.com",
    projectId: "dynamo-stg",
    storageBucket: "dynamo-stg.appspot.com",
    messagingSenderId: "579868143457",
    appId: "1:579868143457:web:186594356b3e5982f11f20",
    measurementId: "G-5XP1SZ0EG6",
  },
  env: {
    baseUrl: "https://dynamo-dashboard-stg.firebaseapp.com",
  },
}

const prod = {
  apiGateway: {
    URL: "https://us-central1-dynamo-23ff7.cloudfunctions.net/api/",
    URL2: "https://us-central1-dynamo-23ff7.cloudfunctions.net/api2/",
  },
  amplitude: {
    amplitudeAPIKey: "7b8c784bc21f395e3509e47f40eba850",
  },
  firebase: {
    apiKey: "AIzaSyBky4ig4Xj3SpJtk5let5l44groG7oynjU",
    authDomain: "dynamo-23ff7.firebaseapp.com",
    databaseURL: "https://dynamo-23ff7.firebaseio.com",
    projectId: "dynamo-23ff7",
    storageBucket: "dynamo-23ff7.appspot.com",
    messagingSenderId: "342355164859",
    appId: "1:342355164859:web:c9cd2bffaea4640720f39e",
    measurementId: "G-V1R5P08ZB8",
  },
  env: {
    baseUrl: "https://dashboard.dynamo.fyi",
  },
}

const config =
  process.env.REACT_APP_ENV === "prod"
    ? prod
    : process.env.REACT_APP_ENV === "dev"
    ? dev
    : stg

export default {
  // Add common config values here
  ...config,
}
