import { LastAnswer } from "../LastAnswer"
import { ChildCurrentStatusInterface, ChildInstallLinkResponseInterface } from "../Child"

export enum EChildActionTypes {
  UPDATE_SELECTED_CHILD_ID = "UPDATE_SELECTED_CHILD_ID",
  CLEAR_SELECTED_SUPERVISED_ID = "CLEAR_SELECTED_SUPERVISED_ID",
  GET_CHILD_LAST_ANSWERS_START = "GET_CHILD_LAST_ANSWERS_START",
  GET_CHILD_LAST_ANSWERS_SUCCESS = "GET_CHILD_LAST_ANSWERS_SUCCESS",
  GET_CHILD_DATA_SUCCESS = "GET_CHILD_DATA_SUCCESS",
  ADD_REMOVE_PACKAGES_START = "ADD_REMOVE_PACKAGES_START",
  ADD_REMOVE_PACKAGES_SUCCESS = "ADD_REMOVE_PACKAGES_SUCCESS",
  GET_CHILD_CONNECT_DETAILS_START = "GET_CHILD_CONNECT_DETAILS_START",
  GET_CHILD_CONNECT_DETAILS_SUCCESS = "GET_CHILD_CONNECT_DETAILS_SUCCESS",
  GET_CHILD_CONNECT_DETAILS_FAIL = "GET_CHILD_CONNECT_DETAILS_FAIL",
}

export interface updateSelectedSupervisedId {
  type: typeof EChildActionTypes.UPDATE_SELECTED_CHILD_ID
  supervisedId: string
}

export interface clearSelectedSupervisedId {
  type: typeof EChildActionTypes.CLEAR_SELECTED_SUPERVISED_ID
}

export interface getChildLastAnswersStart {
  type: typeof EChildActionTypes.GET_CHILD_LAST_ANSWERS_START
  supervisedId: string
}

export interface getChildLastAnswersAction {
  type: typeof EChildActionTypes.GET_CHILD_LAST_ANSWERS_SUCCESS
  supervisedId: string
  lastAnswers: LastAnswer[]
  resetLastAnswers: boolean
}

export interface getChildDataSuccess {
  type: typeof EChildActionTypes.GET_CHILD_DATA_SUCCESS
  childCurrentStatus: {
    supervisedId: string
    data: ChildCurrentStatusInterface
  }
}

export interface addRemovePackagesStart {
  type: typeof EChildActionTypes.ADD_REMOVE_PACKAGES_START
  supervisedId: string
}

export interface addRemovePackagesSuccess {
  type: typeof EChildActionTypes.ADD_REMOVE_PACKAGES_SUCCESS
  supervisedId: string
}
export interface getChildConnectDetailsStartAction {
  type: typeof EChildActionTypes.GET_CHILD_CONNECT_DETAILS_START
  supervisedId: string
}
export interface getChildConnectDetailsSuccessAction {
  type: typeof EChildActionTypes.GET_CHILD_CONNECT_DETAILS_SUCCESS
  supervisedId: string
  connectDetails: ChildInstallLinkResponseInterface
}
export interface getChildConnectDetailsFailAction {
  type: typeof EChildActionTypes.GET_CHILD_CONNECT_DETAILS_FAIL
  supervisedId: string
}

export type ChildActionTypes =
  | updateSelectedSupervisedId
  | clearSelectedSupervisedId
  | getChildLastAnswersStart
  | getChildLastAnswersAction
  | getChildDataSuccess
  | addRemovePackagesStart
  | addRemovePackagesSuccess
  | getChildConnectDetailsStartAction
  | getChildConnectDetailsSuccessAction
  | getChildConnectDetailsFailAction
