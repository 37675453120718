import React from 'react'
import { Question } from '../../../../types/Question';
import ContentInput from './ContentInput';
import { ContentInputType, ECharacterCount, contentTranslationType } from '../../../../types/generalInterfaces';
import AnswerInputView from '../AnswerInputView';

interface Iprops {
  question: Question
  updateNotificationQuestion:Function,
  updateTopLevelQuestion:Function,
  updateAnswer:Function,
  deleteAnswer:Function
}

const GENERIC_DID_YOU_KNOW = ({ question, deleteAnswer,updateAnswer,updateNotificationQuestion,updateTopLevelQuestion }: Iprops) => {

  




  return (
    <div className="col s12">
      {/* <ContentInput id="[notification][text]" showTranslationSelector={true} inputType={ContentInputType.TEXT} characterCount={ECharacterCount.NOTIFICATION_CHAR_COUNT} content={question.contentData.notification} textLabel="Notification text" updateValue={(type:ContentInputType, val:string,translationType:contentTranslationType) => {updateNotificationQuestion(type,val,translationType)}}/> */}
      <ContentInput id="[text]" showTranslationSelector={false} inputType={ContentInputType.TEXT_AND_IMAGE_OR_SPRITE} characterCount={ECharacterCount.Q_TWO_LINES_CHAR_COUNT} content={question.contentData} textLabel="Question text" updateValue={(type:ContentInputType, val:string,translationType:contentTranslationType) => {updateTopLevelQuestion(type,val,translationType);updateNotificationQuestion(type,val,translationType)}}/>

{question.contentData.answers.map((answer, index) => {
        return (
          <AnswerInputView
            key={index}
            inputType={ContentInputType.TEXT}
            placeHolder="Answer Text"
            content={answer}
            index={index}
            updateAnswer={({ type,val, correct,translationType }: { type:ContentInputType, val: string, correct: boolean,translationType:contentTranslationType }) => updateAnswer({ type,val, correct, index,translationType })}
            deleteAnswer={() => deleteAnswer( index )}
            addAnotherAnswer={()=>{}}
            maxAmountOfAnswers={1}
            maxCharacters={ECharacterCount.ANSWER_CHAR_COUNT}
          />
        )
      })}
    </div>
  );
}

export default GENERIC_DID_YOU_KNOW