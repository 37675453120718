import _ from "lodash"
import { createSelector } from "reselect"
import { RootState } from "../reducers/rootReducer"
import {
  Child,
  ChildCurrentStatusInterface,
  Supervised,
} from "../../types/Child"
import { LastAnswer } from "../../types/LastAnswer"
import { getAllBundlesByIds } from "./kingdomsSelector"
import { Bundle } from "../../types/Bundle"
import { packageStatusType } from "../../types/generalInterfaces"

const getAllSupervised = (state: RootState) => state.user.supervised
const getSelectedSupervisedId = (state: RootState) =>
  state.child.selectedSupervisedId
const getChildrenData = (state: RootState) => state.child.childrenData
const getLastAnswers = (state: RootState) => state.child.childrenLastAnswers

export const getSelectedSupervised = createSelector(
  [getAllSupervised, getSelectedSupervisedId],
  (allSupervised, selectedSupervisedId) => {
    if (!allSupervised || allSupervised.length === 0) return null

    const supervised = _.find(allSupervised, (c) => {
      return c.id === selectedSupervisedId
    }) as Supervised

    return supervised
  }
)

export const getSelectedChild = createSelector(
  [getSelectedSupervised],
  (selectedSupervised) => {
    if (!selectedSupervised || !selectedSupervised.supervisedUser) return null

    return selectedSupervised.supervisedUser as Child
  }
)

export const getSelectedChildData = createSelector(
  [getChildrenData, getSelectedSupervisedId],
  (
    childrenData: { [key: string]: ChildCurrentStatusInterface },
    selectedSupervisedId: string
  ) => {
    return childrenData[selectedSupervisedId] || {}
  }
)

export const getSelectedChildLastAnswers = createSelector(
  [getLastAnswers, getSelectedSupervisedId],
  (
    lastAnswers: { [key: string]: LastAnswer[] },
    selectedSupervisedId: string
  ) => {
    return lastAnswers[selectedSupervisedId] || []
  }
)

export const getSelectedSupervisedBundles = createSelector(
  [getSelectedSupervised, getAllBundlesByIds],
  (selectedSupervised, allBundlesByIds) => {
    if (!selectedSupervised) return []

    const bundles: Bundle[] = []

    if (selectedSupervised.supervisedUser) {
      const userBundles = selectedSupervised.supervisedUser.packages || []
      bundles.push(...userBundles)
    } else if (selectedSupervised.profile.questionsPackageActions) {
      selectedSupervised.profile.questionsPackageActions.map(
        (packageAction) => {
          const bundle = allBundlesByIds[packageAction.id]

          if (bundle) {
            const bundleCopy = _.cloneDeep(bundle)
            bundleCopy.status = packageStatusType.ENABLED
            bundles.push(bundleCopy)
          }
        }
      )
    }

    return bundles.filter(
      (bundle) => bundle.status !== packageStatusType.RECOMMENDED
    )
  }
)
