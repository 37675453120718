import { Bundle } from "../Bundle"
import { Question } from "../Question"

export enum EBundleActionsTypes {
  UPDATE_SELECTED_PERSONALIZED_BUNDLE_ID = "UPDATE_SELECTED_PERSONALIZED_BUNDLE_ID",
  CLEAR_SELECTED_BUNDLE_ID = "CLEAR_SELECTED_BUNDLE_ID",
  GET_BUNDLES = "GET_BUNDLES",
  CREATE_OR_UPDATE_BUNDLE_START = "CREATE_OR_UPDATE_BUNDLE_START",
  CREATE_OR_UPDATE_BUNDLE_SUCCESS = "CREATE_OR_UPDATE_BUNDLE_SUCCESS",
  CREATE_OR_UPDATE_BUNDLE_FAIL = "CREATE_OR_UPDATE_BUNDLE_FAIL",
  UPDATE_QUESTIONS_START = "UPDATE_QUESTIONS_START",
  UPDATE_QUESTIONS_SUCCES = "UPDATE_QUESTIONS_SUCCES",
  UPDATE_QUESTIONS_ERROR = "UPDATE_QUESTIONS_ERROR",
}

export interface updateSelectedPersonalizedBundledId {
  type: typeof EBundleActionsTypes.UPDATE_SELECTED_PERSONALIZED_BUNDLE_ID
  bundleId: string
  isEditable?: boolean
}

export interface clearSelectedBundleId {
  type: typeof EBundleActionsTypes.CLEAR_SELECTED_BUNDLE_ID
}

export interface createOrUpdateBundleStartAction {
  type: typeof EBundleActionsTypes.CREATE_OR_UPDATE_BUNDLE_START
  bundleId: string
}

export interface createOrUpdateBundleSuccessAction {
  type: typeof EBundleActionsTypes.CREATE_OR_UPDATE_BUNDLE_SUCCESS
  bundleId: string
}

export interface createOrUpdateBundleFailAction {
  type: typeof EBundleActionsTypes.CREATE_OR_UPDATE_BUNDLE_FAIL
  bundleId: string
}

export interface updateQuestionsStartAction {
  type: typeof EBundleActionsTypes.UPDATE_QUESTIONS_START
  bundle: Bundle
  bundleId: string
}

export interface updateQuestionsSuccessAction {
  type: typeof EBundleActionsTypes.UPDATE_QUESTIONS_SUCCES
  bundle: Bundle
  bundleId: string
  questions: Question[]
}

export interface updateQuestionsErrorAction {
  type: typeof EBundleActionsTypes.UPDATE_QUESTIONS_ERROR
  bundle: Bundle
  bundleId: string
}

type createOrUpdateBundleActions =
  | createOrUpdateBundleStartAction
  | createOrUpdateBundleSuccessAction
  | createOrUpdateBundleFailAction
  | updateQuestionsStartAction
  | updateQuestionsSuccessAction
  | updateQuestionsErrorAction
  | updateSelectedPersonalizedBundledId
  | clearSelectedBundleId

export type BundleActionTypes = createOrUpdateBundleActions
