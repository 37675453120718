import React, { useState, useEffect } from 'react'
import { layoutTypes, ContentInputType, contentTranslationType, webLayoutTypes, WebLayoutTypeIds } from '../../../types/generalInterfaces';
import { Question } from '../../../types/Question';
import VERTICAL_TEXT_TWO_LINES_1_TO_4_ANSWERS_WHITE_BACKGROUND from './layouts/VERTICAL_TEXT_TWO_LINES_1_TO_4_ANSWERS_WHITE_BACKGROUND';
import GENERIC_DID_YOU_KNOW from './layouts/GENERIC_DID_YOU_KNOW';
import { initAnswer, getQuestionVersionForLayout } from '../../../utils/utils';
import * as _ from 'lodash'
import VERTICAL_TEXT_ONE_LINE_1_TO_4_ANSWERS_WHITE_BACKGROUND from './layouts/VERTICAL_TEXT_ONE_LINE_1_TO_4_ANSWERS_WHITE_BACKGROUND';
import HORIZONTAL_IMAGE_320PX_1_TO_4_ANSWERS_NO_BACKGROUND from './layouts/HORIZONTAL_IMAGE_320PX_1_TO_4_ANSWERS_NO_BACKGROUND';
import HORIZONTAL_TWO_ROWS_IMAGE_220PX_AND_BOTTOM_TEXT_1_TO_8_ANSWERS_WHITE_BACKGROUND from './layouts/HORIZONTAL_TWO_ROWS_IMAGE_220PX_AND_BOTTOM_TEXT_1_TO_8_ANSWERS_WHITE_BACKGROUND';
import { DYNAMO_COLOR } from '../../../constants/constants';
import QuestionLayoutSelector from './QuestionLayoutSelector';
import Q_TEXT_A_TEXT_3 from './layouts/webLayouts/Q_TEXT_A_TEXT_3';
import Q_TEXT_A_IMAGE_3 from './layouts/webLayouts/Q_TEXT_A_TEXT_HOR_3';
import Q_TEXT_A_IMAGE_4 from './layouts/webLayouts/Q_TEXT_A_IMAGE_4';
import Q_IMAGE_A_TEXT_HOR_3 from './layouts/webLayouts/Q_IMAGE_A_TEXT_HOR_3';
import Q_TEXT_A_TRUE_OR_FALSE from './layouts/webLayouts/Q_TEXT_A_TRUE_OR_FALSE';
import QuestionPreview from './QuestionPreview';



interface Iprops {
  saveQuestion: Function
  q: Question
  cancel: Function
  inEditMode: boolean
}

const QuestionDetails = ({ saveQuestion, q, cancel, inEditMode }: Iprops) => {


  const [question, setQuestion] = useState<Question>(q);
  const [webLayoutId, setWebLayoutId] = useState<string>(webLayoutTypes.Q_TEXT_A_TEXT_3.id);
  const [reset, setReset] = useState(false);

  useEffect(() => {
    if (reset) {
      M.updateTextFields();

      setReset(false);
    }
  }, [reset]);

  useEffect(() => {
    M.updateTextFields();
    const elem = document.getElementById("layout-select");
    M.FormSelect.init(elem as Element)
    setWebLayoutId(guessWebLayoutId(q))
    setQuestion(q);
  }, [q]);

  useEffect(() => {
    // const elems = document.querySelectorAll('.tooltipped');
    // M.Tooltip.init(elems);

  }, []);

  const guessWebLayoutId = (q:Question):string => {
    switch (q.layoutType) {
      case layoutTypes.GENERIC_DID_YOU_KNOW:
        return WebLayoutTypeIds.DID_YOU_KNOW
      case layoutTypes.HORIZONTAL_IMAGE_320PX_1_TO_4_ANSWERS_NO_BACKGROUND:
        return WebLayoutTypeIds.Q_TEXT_A_IMAGE_4
      case layoutTypes.VERTICAL_TEXT_TWO_LINES_1_TO_4_ANSWERS_WHITE_BACKGROUND:
      case layoutTypes.VERTICAL_TEXT_ONE_LINE_1_TO_4_ANSWERS_GREEN_BACKGROUND:
      case layoutTypes.VERTICAL_TEXT_ONE_LINE_1_TO_4_ANSWERS_WHITE_BACKGROUND:
      case layoutTypes.VERTICAL_TEXT_TWO_LINES_1_TO_4_ANSWERS_GREEN_BACKGROUND:
          return WebLayoutTypeIds.Q_TEXT_A_TEXT_3
      case layoutTypes.HORIZONTAL_TWO_ROWS_IMAGE_220PX_AND_BOTTOM_TEXT_1_TO_8_ANSWERS_GREEN_BACKGROUND:
      case layoutTypes.HORIZONTAL_TWO_ROWS_IMAGE_220PX_AND_BOTTOM_TEXT_1_TO_8_ANSWERS_GREEN_BACKGROUND:
        if (q.contentData.text !== ""){
          return WebLayoutTypeIds.Q_TEXT_A_TEXT_HOR_3
        }else{
          return WebLayoutTypeIds.Q_IMAGE_A_TEXT_HOR_3
        }
      default:
          return WebLayoutTypeIds.Q_TEXT_A_TEXT_3;
    }
  }

  const onWebLayoutSelectChange = (webLayoutId: string) => {
    const webLayout = webLayoutTypes[webLayoutId];
    const version = getQuestionVersionForLayout(webLayout.layoutType)
    setQuestion({ ...question, layoutType: webLayout.layoutType, version });
    setWebLayoutId(webLayoutId)
    if (webLayoutId === WebLayoutTypeIds.Q_TEXT_A_TRUE_OR_FALSE){
      updateAnswer({type:ContentInputType.TEXT,val:"True",correct:true,index:0,translationType:contentTranslationType.NO_TRANSLATION})
      if (question.contentData.answers.length !== 2) addAnotherAnswer()
      updateAnswer({type:ContentInputType.TEXT,val:"False",correct:false,index:1,translationType:contentTranslationType.NO_TRANSLATION})
    }
  }

  const updateQuestion = (question: Question) => {
    setQuestion({ ...question });
  }

  const updateAnswer = ({ type, val, correct, index, translationType }: { type: ContentInputType, val: string, correct: boolean, index: number, translationType: contentTranslationType }) => {

    switch (type) {
      case ContentInputType.TEXT:
        question.contentData.answers[index].text = val;
        question.contentData.answers[index].translation = translationType;
        break;
      case ContentInputType.IMAGE:
        question.contentData.answers[index].image = val;
        break;

      default:
        break;
    }
    question.contentData.answers[index].correct = correct;
    //check if I can change the answers to one line in case they were two lines:
    if (question.layoutType === layoutTypes.VERTICAL_TEXT_TWO_LINES_1_TO_4_ANSWERS_GREEN_BACKGROUND
      || question.layoutType === layoutTypes.VERTICAL_TEXT_ONE_LINE_1_TO_4_ANSWERS_GREEN_BACKGROUND
      || question.layoutType === layoutTypes.VERTICAL_TEXT_TWO_LINES_1_TO_4_ANSWERS_WHITE_BACKGROUND
      || question.layoutType === layoutTypes.VERTICAL_TEXT_ONE_LINE_1_TO_4_ANSWERS_WHITE_BACKGROUND) {
      //this is a two lines question.
      let makeOneLine = true;
      question.contentData.answers.map(answer => {
        if (answer.text && answer.text.length > 15) makeOneLine = makeOneLine && false;
      })
      if (makeOneLine) {
        //if this was a green bg
        if (question.layoutType === layoutTypes.VERTICAL_TEXT_TWO_LINES_1_TO_4_ANSWERS_GREEN_BACKGROUND
          || question.layoutType === layoutTypes.VERTICAL_TEXT_ONE_LINE_1_TO_4_ANSWERS_GREEN_BACKGROUND) {
          question.layoutType = layoutTypes.VERTICAL_TEXT_ONE_LINE_1_TO_4_ANSWERS_GREEN_BACKGROUND
        } else {//this is a white bg
          question.layoutType = layoutTypes.VERTICAL_TEXT_ONE_LINE_1_TO_4_ANSWERS_WHITE_BACKGROUND
        }
      } else {//esle this should be two lines
        //if this was a green bg
        if (question.layoutType === layoutTypes.VERTICAL_TEXT_TWO_LINES_1_TO_4_ANSWERS_GREEN_BACKGROUND
          || question.layoutType === layoutTypes.VERTICAL_TEXT_ONE_LINE_1_TO_4_ANSWERS_GREEN_BACKGROUND) {
          question.layoutType = layoutTypes.VERTICAL_TEXT_TWO_LINES_1_TO_4_ANSWERS_GREEN_BACKGROUND
        } else {//this is a white bg
          question.layoutType = layoutTypes.VERTICAL_TEXT_TWO_LINES_1_TO_4_ANSWERS_WHITE_BACKGROUND
        }
      }
    }
    updateQuestion(question)

  };

  const updateTopLevelQuestion = (type: ContentInputType, val: string, translationType: contentTranslationType) => {
    switch (type) {
      case ContentInputType.TEXT:
        question.contentData.text = val;
        question.contentData.translation = translationType;
        break;
      case ContentInputType.IMAGE:
        question.contentData.image = val;
        break;
      case ContentInputType.SPRITE:
        question.contentData.sprite = val;
        break;

      default:
        break;
    }
    updateQuestion(question)
  }

  const updateNotificationQuestion = (type: ContentInputType, val: string, translationType: contentTranslationType) => {
    switch (type) {
      case ContentInputType.TEXT:
        question.contentData.notification.text = val;
        question.contentData.notification.translation = translationType;
        break;
      case ContentInputType.IMAGE:
        question.contentData.notification.text = "Lets get smarter";
        question.contentData.notification.translation = contentTranslationType.NO_TRANSLATION;
        break;
      default:
        break;
    }
    updateQuestion(question)
  }

  const deleteAnswer = (index: number) => {
    question.contentData.answers.splice(index, 1)
    updateQuestion(question);
  };

  const addAnotherAnswer = () => {
    const answer = initAnswer({ correct: false, text: '' }, false)
    question.contentData.answers.push(answer)
    setQuestion({ ...question });
  }

  const localSaveQuestion = () => {
    setReset(true);
    //clean any answers that are empty
    const answers = question.contentData.answers;
    _.remove(answers, (answer) => { return (answer.text === "" && answer.image === "") })
    
    //save the question
    saveQuestion(question);
  }

  const enableSaveButton = () => {
    let atLeastOneCorrectAnswer = false;
    question.contentData.answers.map(answer => {
      atLeastOneCorrectAnswer = atLeastOneCorrectAnswer || answer.correct;
      return true;
    })
    return atLeastOneCorrectAnswer && (question.contentData.text.length || question.contentData.image.length || question.contentData.sprite.length) && (question.contentData.answers[0].text || question.contentData.answers[0].image) && question.contentData.notification.text ? "enabled" : "disabled"
  }

  const renderSwitch = () => {

    
    switch (webLayoutId) {
      case WebLayoutTypeIds.DID_YOU_KNOW:
        return <GENERIC_DID_YOU_KNOW
        question={question}
        updateAnswer={updateAnswer}
        updateNotificationQuestion={updateNotificationQuestion}
        updateTopLevelQuestion={updateTopLevelQuestion}
        deleteAnswer={deleteAnswer}
      />
      case WebLayoutTypeIds.Q_IMAGE_A_TEXT_HOR_3:
        return <Q_IMAGE_A_TEXT_HOR_3
        question={question}
        addAnotherAnswer={addAnotherAnswer}
        updateAnswer={updateAnswer}
        updateNotificationQuestion={updateNotificationQuestion}
        updateTopLevelQuestion={updateTopLevelQuestion}
        deleteAnswer={deleteAnswer}
      />;
        break;
      case WebLayoutTypeIds.Q_TEXT_A_TEXT_HOR_3:
        return <Q_TEXT_A_IMAGE_3
          question={question}
          addAnotherAnswer={addAnotherAnswer}
          updateAnswer={updateAnswer}
          updateNotificationQuestion={updateNotificationQuestion}
          updateTopLevelQuestion={updateTopLevelQuestion}
          deleteAnswer={deleteAnswer}
        />;
        break;
      case WebLayoutTypeIds.Q_TEXT_A_IMAGE_4:
        return <Q_TEXT_A_IMAGE_4
          question={question}
          addAnotherAnswer={addAnotherAnswer}
          updateAnswer={updateAnswer}
          updateNotificationQuestion={updateNotificationQuestion}
          updateTopLevelQuestion={updateTopLevelQuestion}
          deleteAnswer={deleteAnswer}
        />;
        
      case WebLayoutTypeIds.Q_TEXT_A_TEXT_3:

        return <Q_TEXT_A_TEXT_3
          question={question}
          addAnotherAnswer={addAnotherAnswer}
          updateAnswer={updateAnswer}
          updateNotificationQuestion={updateNotificationQuestion}
          updateTopLevelQuestion={updateTopLevelQuestion}
          deleteAnswer={deleteAnswer}
        />;
      case WebLayoutTypeIds.Q_TEXT_A_TRUE_OR_FALSE:
        return <Q_TEXT_A_TRUE_OR_FALSE
          question={question}
          addAnotherAnswer={addAnotherAnswer}
          updateAnswer={updateAnswer}
          updateNotificationQuestion={updateNotificationQuestion}
          updateTopLevelQuestion={updateTopLevelQuestion}
          deleteAnswer={deleteAnswer}
        />;
        

      default:
        return <Q_TEXT_A_TRUE_OR_FALSE
          question={question}
          addAnotherAnswer={addAnotherAnswer}
          updateAnswer={updateAnswer}
          updateNotificationQuestion={updateNotificationQuestion}
          updateTopLevelQuestion={updateTopLevelQuestion}
          deleteAnswer={deleteAnswer}
        />;
    }


    // 
  }



  return (
    <div className="">
      <div className="col s7">
      <QuestionLayoutSelector startWebLayoutId={webLayoutId} disabled={inEditMode} onLayoutSelect={(layoutType:string) => onWebLayoutSelectChange(layoutType)}/>
      
      {renderSwitch()}
      </div>
      <div className="col s5">
          <QuestionPreview question={question}/>
      </div>

      <div className="input-field col s6">
        <button
          type="button"
          className={`btn ${DYNAMO_COLOR} lighten-1 ${enableSaveButton()}`}
          onClick={localSaveQuestion}>
          Save Question
            </button>
      </div>

      <div className="input-field col s6">
        <button
          type="button"
          className={`btn pink lighten-1`}
          onClick={() => {
            setReset(true);
            cancel();
          }}>
          Cancel
            </button>
      </div>
    </div>
  )
}

export default QuestionDetails



//switch (question.layoutType) {
  //   case layoutTypes.VERTICAL_TEXT_TWO_LINES_1_TO_4_ANSWERS_WHITE_BACKGROUND:

  //     return <VERTICAL_TEXT_TWO_LINES_1_TO_4_ANSWERS_WHITE_BACKGROUND
  //       question={question}
  //       addAnotherAnswer={addAnotherAnswer}
  //       updateAnswer={updateAnswer}
  //       updateNotificationQuestion={updateNotificationQuestion}
  //       updateTopLevelQuestion={updateTopLevelQuestion}
  //       deleteAnswer={deleteAnswer}
  //     />
  //   //break;
  //   case layoutTypes.VERTICAL_TEXT_ONE_LINE_1_TO_4_ANSWERS_WHITE_BACKGROUND:

  //     return <VERTICAL_TEXT_ONE_LINE_1_TO_4_ANSWERS_WHITE_BACKGROUND
  //       question={question}
  //       addAnotherAnswer={addAnotherAnswer}
  //       updateAnswer={updateAnswer}
  //       updateNotificationQuestion={updateNotificationQuestion}
  //       updateTopLevelQuestion={updateTopLevelQuestion}
  //       deleteAnswer={deleteAnswer}
  //     />
  //   //break;
  //   case layoutTypes.GENERIC_DID_YOU_KNOW:
  //     return <GENERIC_DID_YOU_KNOW
  //       question={question}
  //       updateAnswer={updateAnswer}
  //       updateNotificationQuestion={updateNotificationQuestion}
  //       updateTopLevelQuestion={updateTopLevelQuestion}
  //       deleteAnswer={deleteAnswer}
  //     />
  //   //break;

  //   case layoutTypes.HORIZONTAL_TWO_ROWS_IMAGE_220PX_AND_BOTTOM_TEXT_1_TO_8_ANSWERS_WHITE_BACKGROUND:
  //     return <HORIZONTAL_TWO_ROWS_IMAGE_220PX_AND_BOTTOM_TEXT_1_TO_8_ANSWERS_WHITE_BACKGROUND
  //       question={question}
  //       addAnotherAnswer={addAnotherAnswer}
  //       updateAnswer={updateAnswer}
  //       updateNotificationQuestion={updateNotificationQuestion}
  //       updateTopLevelQuestion={updateTopLevelQuestion}
  //       deleteAnswer={deleteAnswer}
  //     />
  //   //break;


  //   case layoutTypes.HORIZONTAL_IMAGE_320PX_1_TO_4_ANSWERS_NO_BACKGROUND:
  //     return <HORIZONTAL_IMAGE_320PX_1_TO_4_ANSWERS_NO_BACKGROUND
  //       question={question}
  //       addAnotherAnswer={addAnotherAnswer}
  //       updateAnswer={updateAnswer}
  //       updateNotificationQuestion={updateNotificationQuestion}
  //       updateTopLevelQuestion={updateTopLevelQuestion}
  //       deleteAnswer={deleteAnswer}
  //     />
  //   //break;
  //   default:
  //     return <VERTICAL_TEXT_TWO_LINES_1_TO_4_ANSWERS_WHITE_BACKGROUND
  //       question={question}
  //       addAnotherAnswer={addAnotherAnswer}
  //       updateAnswer={updateAnswer}
  //       updateNotificationQuestion={updateNotificationQuestion}
  //       updateTopLevelQuestion={updateTopLevelQuestion}
  //       deleteAnswer={deleteAnswer}
  //     />
  //   //break;
  // }