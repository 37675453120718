import { EAuthActionTypes } from "../../types/actionTypes/AuthActionsTypes"

export interface IReduxAuthState {
  isLoggedIn: boolean
  uuid: string | null
}

const initState: IReduxAuthState = {
  isLoggedIn: false,
  uuid: null,
}

const authReducer = (state: IReduxAuthState = initState, action: any) => {
  switch (action.type) {
    case EAuthActionTypes.SET_IS_LOGGED_IN:
      return { ...state, isLoggedIn: action.isLoggedIn }

    case EAuthActionTypes.LOGOUT:
    case EAuthActionTypes.CLEAR_SENSITIVE_DATA: {
      return { ...initState }
    }

    case EAuthActionTypes.SET_UUID: {
      const { uuid } = action
      return { ...state, uuid }
    }

    default:
      break
  }
  return state
}

export default authReducer
