import React from "react"
import CSS from "csstype"
import { useSpring, animated } from "react-spring"
import { DYNAMO_COLOR } from "../../constants/constants"

const ACTIVE_STAGE_COLOR = DYNAMO_COLOR
const INACTIVE_STAGE_COLOR = "#d8d8d8"
const STAGE_STRINGS = ["להתחבר למורה", "תשלום", "לצפות בילדך מתקדם"]
const STAGE_SIZE = 76
const SEPERATOR_WIDTH = 40
const SEPERATOR_HEIGHT = 2

interface Props {
  stage: number
}

const PaywallStages = ({ stage = 0 }: Props) => {
  const seperatorAnimation1 = useSpring({ width: stage >= 1 ? 40 : 0 })
  const seperatorAnimation2 = useSpring({ width: stage === 2 ? 40 : 0 })

  const stageAnimation1 = useSpring({
    backgroundColor: stage >= 1 ? ACTIVE_STAGE_COLOR : INACTIVE_STAGE_COLOR,
    delay: stage >= 1 ? 200 : 0,
    duration: 200,
  })
  const stageAnimation2 = useSpring({
    backgroundColor: stage === 2 ? ACTIVE_STAGE_COLOR : INACTIVE_STAGE_COLOR,
    delay: stage === 2 ? 200 : 0,
    duration: 200,
  })

  const getSeperatorAnimation = (minStage: number) =>
    minStage === 1 ? seperatorAnimation1 : seperatorAnimation2

  const getStageAnimation = (minStage: number) => {
    // minStage >= 1 ? stageAnimation1 : stageAnimation2
    switch (minStage) {
      case 0:
        return { backgroundColor: DYNAMO_COLOR }
      case 1:
        return stageAnimation1
      case 2:
        return stageAnimation2
      default:
        return {}
    }
  }

  const renderStage = (minStage: number) => {
    return (
      <animated.div
        className="col valign-wrapper"
        style={{
          ...stageStyle,
          ...getStageAnimation(minStage),
        }}
      >
        <h6
          style={{
            width: "100%",
            height: "100%",
            fontSize: 12,
            display: "table",
            color: "#ffffff",
            fontWeight: 900,
            lineHeight:
              minStage === 1 ? "30px" : minStage === 2 ? "12px" : "initial",
          }}
        >
          {STAGE_STRINGS[minStage]}
        </h6>
      </animated.div>
    )
  }

  const renderSeperator = (minStage: number) => {
    return (
      <div className="col" style={seperatorWrapperStyle}>
        <div
          className="s2"
          style={{
            ...seperatorStyle,
          }}
        />
        <animated.div
          style={{
            ...animatedSeperatorStyle,
            ...getSeperatorAnimation(minStage),
          }}
        />
      </div>
    )
  }

  return (
    <div className="row s12">
      {renderStage(0)}
      {renderSeperator(1)}
      {renderStage(1)}
      {renderSeperator(2)}
      {renderStage(2)}
    </div>
  )
}

const stageStyle: CSS.Properties = {
  height: `${STAGE_SIZE}px`,
  width: `${STAGE_SIZE}px`,
  borderRadius: "50%",
  display: "inline-block",
  padding: "10px",
  float: "none",
}

const seperatorWrapperStyle: CSS.Properties = {
  height: `${STAGE_SIZE}px`,
  width: `${SEPERATOR_WIDTH}px`,
  display: "inline-block",
  padding: "0px",
  float: "none",
}

const seperatorStyle: CSS.Properties = {
  height: `${SEPERATOR_HEIGHT}px`,
  width: `${SEPERATOR_WIDTH}px`,
  marginTop: `${STAGE_SIZE / 2 - 1}px`,
  backgroundColor: INACTIVE_STAGE_COLOR,
}
const animatedSeperatorStyle: CSS.Properties = {
  position: "relative",
  width: "100%",
  height: `${SEPERATOR_HEIGHT}px`,
  background: ACTIVE_STAGE_COLOR,
  bottom: `${SEPERATOR_HEIGHT}px`,
}

export default PaywallStages
