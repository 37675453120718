import { Dispatch } from "redux"
import firebase from "../../configs/fbConfig"
import { showPopupById, hidePopupById } from "./popupActions"
import { User } from "../../types/User"
import { AppActions } from "../../types/actionTypes/actions"
import { EUserActionTypes } from "../../types/actionTypes/UserActionTypes"
import {
  requestWrapper,
  requestWrapper2,
} from "../../services/ServerConnection"
import { Bundle } from "../../types/Bundle"
import { Question } from "../../types/Question"
import { EKingdomActionsTypes } from "../../types/actionTypes/KingdomActionTypes"
import {
  Child,
  ChildInstallLinkResponseInterface,
  Supervised,
} from "../../types/Child"
import {
  questionPackageActionInterface,
  genderEnum,
} from "../../types/generalInterfaces"
import { history } from "../../App"
import { EPopupTypes } from "../../types/Popups"
import { updateSelectedSupervisedId } from "./childActions"

export const editUser = (user: User): AppActions => ({
  type: EUserActionTypes.EDIT_USER,
  user,
})

export const getSupervisorsList = (): any => async (
  dispatch: Dispatch<AppActions>
) => {
  try {
    const res = await requestWrapper2("getSupervisorsList")

    const supervisors = res.data.supervisors as User[]
    dispatch({
      type: EUserActionTypes.GET_SUPERVISORS_SUCCESS,
      supervisors,
    })
  } catch (error) {
    console.error(error)
  }
}

export const getSupervisedListMetadata = (): any => async (
  dispatch: Dispatch<AppActions>
) => {
  try {
    dispatch({
      type: EUserActionTypes.GET_CHILDREN_START,
    })
    const res = await requestWrapper2("getSupervisedListMetadata")

    const supervised = res.data.supervised as Supervised[]
    dispatch({
      type: EUserActionTypes.GET_CHILDREN_SUCCESS,
      supervised,
    })
  } catch (error) {
    dispatch({
      type: EUserActionTypes.GET_CHILDREN_FAIL,
    })
    console.error(error)
  }
}

export const getSupervisedList = (
  supervisedIds?: string | string[]
): any => async (dispatch: Dispatch<AppActions>) => {
  try {
    const reqData: { supervisedIds: string[] } = { supervisedIds: [] }
    if (typeof supervisedIds === "string") {
      reqData.supervisedIds.push(supervisedIds)
    } else if (supervisedIds && supervisedIds?.length > 0) {
      reqData.supervisedIds = supervisedIds
    }

    dispatch({
      type: EUserActionTypes.UPDATE_CHILDREN_DATA_START,
    })

    const res = await requestWrapper2("getSupervisedList", reqData)

    const supervised = res.data.supervised as Supervised[]

    if (supervisedIds) {
      dispatch({
        type: EUserActionTypes.UPDATE_CHILDREN_DATA_SUCCESS,
        supervised,
      })
    } else {
      dispatch({
        type: EUserActionTypes.GET_CHILDREN_SUCCESS,
        supervised,
      })
    }
  } catch (error) {
    dispatch({
      type: EUserActionTypes.UPDATE_CHILDREN_DATA_FAIL,
    })
    console.error(error)
  }
}

export interface addChildToFamilyRequestInterface {
  profile: {
    nickname: string
    age: number
    gender: genderEnum
    avatarName: string
  }
  questionsPackageActions: Array<questionPackageActionInterface>
  lockScreenFrequency: number
  enabledLockScreen?: boolean
  manualLocale: string
}

export const addChildToFamily = (
  reqData: addChildToFamilyRequestInterface
) => async (dispatch: Dispatch<AppActions>) => {
  try {
    dispatch({
      type: EUserActionTypes.ADD_CHILD_IN_PROGRESS,
    })

    dispatch(showPopupById(EPopupTypes.LOADING_POPUP))
    // const { nickname, parentId,age, familyId, questionsPackageActions, installId, appVersionName, appVersionNumber,anonymousCId, gender,lockScreenFrequency,parentType,manualLocale,enabledLockScreen } = req.body
    const res = await requestWrapper2("createChild", {
      ...reqData,
    })

    const connectDetails = res.data as ChildInstallLinkResponseInterface
    dispatch({
      type: EUserActionTypes.ADD_CHILD_SUCCESS,
      connectDetails,
    })

    dispatch(updateSelectedSupervisedId(connectDetails.id))
    history.push({
      pathname: "/child",
    })
    dispatch(hidePopupById(EPopupTypes.LOADING_POPUP))
  } catch (error) {
    console.error(error)
  }
}

export const getPersonalizedBundles = (): any => async (
  dispatch: Dispatch<AppActions>
) => {
  try {
    dispatch({
      type: EUserActionTypes.GET_PERSONALIZED_BUNDLES_START,
    })

    // parentId,familyId,packageId,name,imageName,questions,description,status,supportedLocales
    const res = await requestWrapper2("getPersonalizedBundles")

    const bundels = res.data as Bundle[]

    dispatch({
      type: EUserActionTypes.GET_PERSONALIZED_BUNDLES_SUCCESS,
      bundels,
    })
  } catch (error) {
    dispatch({
      type: EUserActionTypes.GET_PERSONALIZED_BUNDLES_FAIL,
    })
    console.error(error)
  }
}

export const getQuestionsForPackageId = (
  bundleId: string,
  kingdomId: string
) => async (dispatch: Dispatch<AppActions>) => {
  try {
    dispatch({
      type: EUserActionTypes.GET_QUESTIONS_FOR_PACKAGEID_START,
      bundleId,
    })
    // parentId,familyId,packageId,name,imageName,questions,description,status,supportedLocales
    const res = await requestWrapper("getQuestionsForPackageId", {
      packageId: bundleId,
      kingdomId,
    })

    const questions = res.data.questions as Question[]

    dispatch({
      type: EUserActionTypes.GET_QUESTIONS_FOR_PACKAGEID_SUCCESS,
      questions,
      bundleId,
    })
    dispatch({
      type: EKingdomActionsTypes.GET_QUESTIONS_FOR_PACKAGEID_KINGDOM_SUCCESS,
      questions,
      bundleId,
      kingdomId,
    })
  } catch (error) {
    console.log(error)
    dispatch({
      type: EUserActionTypes.GET_QUESTIONS_FOR_PACKAGEID_FAIL,
      bundleId,
    })
  }
}

export const sendSuperMessageToChild = (
  child: Child,
  message: string
) => async (dispatch: Dispatch<AppActions>) => {
  try {
    // childId, parentId, familyId, text,sendAtTimestamp,parentType
    await requestWrapper("sendDirectMessageToChild", {
      childId: child.id,
      parentId: child.familyId, // TODO
      familyId: child.familyId,
      text: message,
      sendAtTimestamp: 1591794129837, // TODO
      parentType: "Mom", // TODO
    })

    dispatch({
      type: EUserActionTypes.SUPER_MESSAGE_SENT_SUCCESS,
    })
  } catch (error) {
    console.log(error)
  }
}

export const sendForceRingToChild = (child: Child) => async (
  dispatch: Dispatch<AppActions>
) => {
  try {
    // childId, parentId, familyId, text,sendAtTimestamp,parentType
    await requestWrapper("activateForceRing", {
      childId: child.id,
      parentId: child.familyId, // TODO
      familyId: child.familyId,
      sendAtTimestamp: 1591794129837, // TODO
      parentType: "Mom", // TODO
    })

    dispatch({
      type: EUserActionTypes.SUPER_MESSAGE_SENT_SUCCESS,
    })
  } catch (error) {
    console.log(error)
  }
}

export const uploadUserProfileImage = ({
  userId,
  image,
}: {
  userId: string
  image: File
}) => async (dispatch: Dispatch<AppActions>) => {
  const { uid } = firebase.auth().currentUser!

  try {
    const uploadTask = await firebase
      .storage()
      .ref()
      .child(`/users/${uid}/${userId}/profileImage.jpg`)
      .put(image, { contentType: "image/jpg" })

    const photoUrl = await uploadTask.ref.getDownloadURL()
    dispatch({
      type: EUserActionTypes.UPDATE_USER_PHOTO_URL,
      photoUrl,
    })
    // Listen for state changes, errors, and completion of the upload.
  } catch (error) {
    console.log(error)
  }
}
