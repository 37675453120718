import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { ThunkDispatch } from "redux-thunk"
import { bindActionCreators } from "redux"
import * as _ from "lodash"
import { NavLink } from "react-router-dom"
import { RootState } from "../../store/reducers/rootReducer"
import { AppActions } from "../../types/actionTypes/actions"
import { DYNAMO_COLOR } from "../../constants/constants"
import BundleQuickRowView from "./BundleView/BundleQuickRowView"
import {
  getKingdoms,
  getPublicPersonalizedBundles,
} from "../../store/actions/kingdomActions"
import { updateSelectedBundleId } from "../../store/actions/bundleActions"
import Loader from "../layout/Loader"

interface IProps {}

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  IProps

const BundlesView = (props: Props) => {
  const {
    user: { personalizedBundles },
    kingdoms,
    publicPersonalizedBundles,
    getKingdoms,
    getPublicPersonalizedBundles,
    updateSelectedBundleId,
    gettingKingdomBundles,
    gettingPersonalizedBundles,
    gettingPublicPersonalizedBundles,
  } = props

  useEffect(() => {
    const elems = document.querySelectorAll(".tabs")
    M.Tabs.init(elems)
    const modalelems = document.querySelectorAll(".modal")
    M.Modal.init(modalelems)
  }, [])

  useEffect(() => {
    if (kingdoms.length === 0) {
      getKingdoms()
      getPublicPersonalizedBundles()
    }
  }, [getKingdoms, kingdoms.length])

  return (
    <>
      <div className="" style={{ width: "100%", paddingLeft: "50px" }}>
        <div className="row" style={{ paddingTop: "50px" }}>
          <div className="col s3">
            <h5>Bundles</h5>
          </div>
          <div className="col s3 offset-s3">
            <NavLink
              className="btn"
              to="/bundle"
              onClick={() =>
                updateSelectedBundleId({
                  bundleId: "",
                  isEditable: true,
                })
              }
              style={{ borderRadius: "5%", backgroundColor: DYNAMO_COLOR }}
            >
              <i className="material-icons left">add_circle</i>
              <span className="hide-on-med-and-down">Add new bundle</span>
            </NavLink>
          </div>
        </div>
        <div className="row">
          <div className="col s12">
            <ul className="tabs">
              <li className="tab col s4">
                <a href="#personalized">My Bundles</a>
              </li>
              <li className="tab col s4">
                <a href="#dynamoBundles">Dynamo Bundles</a>
              </li>
              <li className="tab col s4">
                <a href="#tutorBundles">Tutor Bundles</a>
              </li>
            </ul>
          </div>
          <div id="personalized" className="col s12">
            {gettingPersonalizedBundles && <Loader />}
            {personalizedBundles.map((bundle) => {
              return (
                <BundleQuickRowView
                  bundle={bundle}
                  key={bundle.id}
                  isEditable
                />
              )
            })}
          </div>
          <div id="dynamoBundles" className="col s12">
            {gettingKingdomBundles && <Loader />}
            {kingdoms.map((k) => {
              return k.packages.map((p) => {
                return (
                  <BundleQuickRowView
                    bundle={p}
                    key={p.id}
                    isEditable={false}
                  />
                )
              })
            })}
          </div>
          <div id="tutorBundles" className="col s12">
            {gettingPublicPersonalizedBundles && <Loader />}
            {publicPersonalizedBundles.map((publicPersonalizedBundle) => {
              return (
                <BundleQuickRowView
                  bundle={publicPersonalizedBundle}
                  key={publicPersonalizedBundle.id}
                  isEditable={false}
                />
              )
            })}
          </div>
        </div>
      </div>
    </>
  )
}

const mapStateToProps = (state: RootState) => {
  const {
    user,
    loaders: {
      gettingKingdomBundles,
      gettingPersonalizedBundles,
      gettingPublicPersonalizedBundles,
    },
    bundle: { selectedbundleId },
    kingdoms: { kingdoms, publicPersonalizedBundles },
  } = state

  return {
    user,
    selectedbundleId,
    kingdoms,
    publicPersonalizedBundles,
    gettingKingdomBundles,
    gettingPersonalizedBundles,
    gettingPublicPersonalizedBundles,
  }
}

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AppActions>) => {
  return bindActionCreators(
    {
      getKingdoms,
      getPublicPersonalizedBundles,
      updateSelectedBundleId,
    },
    dispatch
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(BundlesView)
