import {
  ChildActionTypes,
  EChildActionTypes,
} from "../../types/actionTypes/ChildActionTypes"
import { LastAnswer } from "../../types/LastAnswer"
import { ChildCurrentStatusInterface } from "../../types/Child"

const childReducerDefaultState: {
  selectedSupervisedId: string
  childrenLastAnswers: { [key: string]: LastAnswer[] }
  childrenData: { [key: string]: ChildCurrentStatusInterface }
} = {
  selectedSupervisedId: "",
  childrenLastAnswers: {},
  childrenData: {},
}

const childReducer = (
  state = childReducerDefaultState,
  action: ChildActionTypes
): {
  selectedSupervisedId: string
  childrenLastAnswers: {}
  childrenData: {}
} => {
  switch (action.type) {
    case EChildActionTypes.UPDATE_SELECTED_CHILD_ID: {
      return {
        ...state,
        selectedSupervisedId: action.supervisedId,
      }
    }

    case EChildActionTypes.CLEAR_SELECTED_SUPERVISED_ID: {
      return {
        ...state,
        selectedSupervisedId: "",
      }
    }

    case EChildActionTypes.GET_CHILD_LAST_ANSWERS_SUCCESS: {
      const { supervisedId, resetLastAnswers, lastAnswers } = action
      let selectedChildLastAnswers = []
      if (!resetLastAnswers && state.childrenLastAnswers[supervisedId]) {
        selectedChildLastAnswers = [
          ...state.childrenLastAnswers[supervisedId],
          ...action.lastAnswers,
        ]
      } else {
        selectedChildLastAnswers = lastAnswers
      }
      return {
        ...state,
        childrenLastAnswers: {
          ...state.childrenLastAnswers,
          [supervisedId]: selectedChildLastAnswers,
        },
      }
    }

    case EChildActionTypes.GET_CHILD_DATA_SUCCESS: {
      const {
        childCurrentStatus: { supervisedId, data },
      } = action

      const newState = {
        ...state,
        childrenData: { ...state.childrenData, [supervisedId]: data || {} },
      }
      return newState
    }

    default:
      return state
  }
}

export { childReducer }
