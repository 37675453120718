import React, { useState, useEffect } from "react"
import { connect } from "react-redux"
import { ThunkDispatch } from "redux-thunk"
import { bindActionCreators } from "redux"
import { NavLink } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { RootState } from "../../store/reducers/rootReducer"
import { AppActions } from "../../types/actionTypes/actions"
import { logout } from "../../store/actions/authActions"
import { showPopupById } from "../../store/actions/popupActions"
import { EPopupTypes } from "../../types/Popups"
import { EStorageKeys } from "../../types/generalInterfaces"
import { ImageWithFallback } from "../common"

interface IProps {
  hidden: boolean
}

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  IProps

const SideBar = ({
  hidden,
  user: { staticConnectCode, photoUrl, permissions, firstName, lastName },
  isLoggedIn,
  logout,
  showPopupById,
}: Props) => {
  const [isAdmin, setIsAdmin] = useState<boolean>(false)
  const { t } = useTranslation()
  useEffect(() => {
    setIsAdmin(!!localStorage.getItem(EStorageKeys.isAdmin))
  }, [permissions])

  return (
    <div
      className="sidenav sidenav-fixed"
      hidden={hidden}
      id="sidenav-left"
      style={{ marginTop: "64px" }}
    >
      <ul>
        <li
          className="user-view"
          style={{ marginLeft: "auto", marginRight: "auto" }}
        >
          <NavLink
            style={{
              width: "100%",
            }}
            to={{
              pathname: "/userProfile",
            }}
          >
            <div style={{ width: 90, marginLeft: "auto", marginRight: "auto" }}>
              <ImageWithFallback
                imageUrl={photoUrl}
                fallbackImageUrl={require("../../assets/images/default_profile_image.png")}
                customStyle={{
                  border: "2px solid #646464",
                }}
                width={80}
                height={80}
              />
            </div>
            <div
              className="subtitle"
              style={{ textAlign: "center" }}
            >{`${firstName} ${lastName}`}</div>
          </NavLink>
        </li>
        <li>
          <NavLink to={{ pathname: "/students" }}>
            <div className="collapsible-header">
              <i className="material-icons">person</i>
              {t("students")}
            </div>
          </NavLink>
        </li>
        <li>
          <NavLink to={{ pathname: "/bundles" }}>
            <div className="collapsible-header">
              <i className="material-icons">laptop</i>
              {t("bundles")}
            </div>
          </NavLink>
        </li>
        <li>
          <NavLink to={{ pathname: "/contentCreator" }}>
            <div className="collapsible-header">
              <i className="material-icons">laptop</i>
              {t("contentCreator")}
            </div>
          </NavLink>
        </li>
        {isAdmin && (
          <>
            <li>
              <NavLink
                style={{ marginLeft: "auto", marginRight: "auto" }}
                to={{ pathname: "/supervisors" }}
              >
                <div className="collapsible-header">
                  <i className="material-icons">directions_bike</i>
                  {t("supervisors")}
                </div>
              </NavLink>
            </li>
          </>
        )}
      </ul>

      <div
        style={{
          position: "fixed",
          width: "100%",
          paddingBottom: "129px",
          bottom: "0px",
        }}
      >
        <div className="divider" />
        <table className="highlight">
          <tbody>
            <tr className="blue lighten-5">
              <td>{t("appVersion")}</td>
              <td>{process.env.REACT_APP_VERSION}</td>
            </tr>
            <tr className="blue lighten-5">
              <td>{t("mentorCode")}</td>
              <td>{staticConnectCode}</td>
            </tr>
          </tbody>
        </table>
        {/* <div className="divider" /> */}
        <div className="center-align">
          {isLoggedIn && (
            <button onClick={() => logout()} className="btn-flat black-text">
              {t("logout")}
            </button>
          )}
          {!isLoggedIn && (
            <button
              onClick={() => showPopupById(EPopupTypes.LOGIN_POPUP)}
              className="btn-flat black-text"
            >
              {t("login")}
            </button>
          )}
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state: RootState) => {
  const {
    user,
    auth: { isLoggedIn },
  } = state

  return {
    user,
    isLoggedIn,
  }
}

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AppActions>) => {
  return bindActionCreators(
    {
      logout,
      showPopupById,
    },
    dispatch
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(SideBar)
