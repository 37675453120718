import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { ThunkDispatch } from "redux-thunk"
import { bindActionCreators } from "redux"
import { useLocation } from "react-router-dom"
import { RootState } from "../../store/reducers/rootReducer"
import { AppActions } from "../../types/actionTypes/actions"
import PaywallStages from "./PaywallStages"
import Details from "./Details"
import Payment from "./Payment"
import PaymentSuccess from "./PaymentSuccess"
import {
  getSupervisorSubscriptionInfo,
  submitSubscriptionContactUs,
  resetSubscriptionContactUs,
} from "../../store/actions/paymentActions"
import ContactUs from "./ContactUs"
import { DYNAMO_COLOR } from "../../constants/constants"
import config from "../../configs/config"
import { EPaymentStatus } from "../../types/generalInterfaces"

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>

const useQuery = () => {
  return new URLSearchParams(useLocation().search)
}

const Paywall = ({
  getSupervisorSubscriptionInfo,
  supervisorSubscriptionInfo,
  showContactUs,
  subscriptionContactUsStatus,
  supervisorSubscriptionInfoFailed,
  submitSubscriptionContactUs,
  resetSubscriptionContactUs,
  gettingSupervisorSubscriptionInfo,
  mentorNotExist,
}: Props) => {
  const [email, setEmail] = useState("")
  const [name, setName] = useState("")
  const [localData, setLocalData] = useState("")

  const [stage, setStage] = useState(0)
  const [showInfoFailed, setShowInfoFailed] = useState(false)
  const [showPaymentFailed, setShowPaymentFailed] = useState(false)
  const query = useQuery()
  const {
    supervisor: { firstName, lastName, photoUrl },
  } = supervisorSubscriptionInfo

  useEffect(() => {
    const receiveMessage = (event: any) => {
      if (event.origin !== config.env.baseUrl) return
      if (event.data === EPaymentStatus.success) {
        setStage(2)
        return
      }
      if (event.data === EPaymentStatus.failure) {
        setShowPaymentFailed(true)
        setStage(1)
      }
    }

    window.addEventListener("message", receiveMessage, false)
  }, [])

  useEffect(() => {
    // TODO - data from url params
    try {
      const data = query.get("data")
      if (!data) {
        setShowInfoFailed(true)
        return
      }
      getSupervisorSubscriptionInfo(data)
      setLocalData(data)
    } catch (error) {
      setShowInfoFailed(true)
    }
  }, [])

  useEffect(() => {
    if (supervisorSubscriptionInfoFailed) {
      setShowInfoFailed(true)
    }
  }, [supervisorSubscriptionInfoFailed])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [stage])

  const renderContent = () => {
    if (showPaymentFailed) {
      return (
        <>
          <div
            className="rtl"
            style={{ fontSize: 24, color: "#7a7a7a", marginTop: "20%" }}
          >
            אנחנו מצטערים אבל משהו לא הסתדר...
          </div>
          <button
            className="waves-effect"
            style={styles.button}
            onClick={() => setShowPaymentFailed(false)}
          >
            כדאי לנסות שוב עכשיו
          </button>
        </>
      )
    }

    if (gettingSupervisorSubscriptionInfo) {
      return (
        <div className="progress" style={{ backgroundColor: "#f8684b66" }}>
          <div
            className="indeterminate"
            style={{ backgroundColor: "#f8684b" }}
          />
        </div>
      )
    }

    if (showInfoFailed) {
      return (
        <div
          className="rtl"
          style={{ fontSize: 24, color: "#7a7a7a", marginTop: "20%" }}
        >
          מוזר אבל יש תקלה...
        </div>
      )
    }

    if (mentorNotExist) {
      return (
        <>
          <div style={{ fontSize: 24, color: "#7a7a7a", marginTop: "20%" }}>
            משהו קרה ונראה שהמנטור שבחרת כבר אינו במערכת
          </div>
        </>
      )
    }

    if (showContactUs) {
      return (
        <ContactUs
          status={subscriptionContactUsStatus}
          onClick={(name: string, email: string, message: string) => {
            submitSubscriptionContactUs({
              data: localData,
              name,
              email,
              text: message,
            })
          }}
          onError={() => resetSubscriptionContactUs()}
        />
      )
    }

    switch (stage) {
      case 0:
        return (
          <Details
            supervisorName={`${firstName} ${lastName}`}
            supervisorPhotoUrl={photoUrl}
            onSubmit={(firstName: string, lastName: string, email: string) => {
              setEmail(email)
              setName(`${firstName} ${lastName}`)
              setStage(stage + 1)
            }}
          />
        )
      case 1:
        return (
          <Payment
            nickname={supervisorSubscriptionInfo.supervisedName}
            mentorName={supervisorSubscriptionInfo.supervisor.firstName}
            url={`${supervisorSubscriptionInfo.monetizationLink}&email=${email}&contact=${name}`}
          />
        )
      case 2:
        return (
          <PaymentSuccess
            nickname={supervisorSubscriptionInfo.supervisedName}
            mentorName={supervisorSubscriptionInfo.supervisor.firstName}
          />
        )

      default:
        break
    }
  }

  return (
    <div
      className="container center-align"
      style={{ maxWidth: 530, width: "100%", paddingTop: "20px" }}
    >
      <div
        className="row center-align"
        style={{
          paddingTop: "20px",
        }}
      >
        <PaywallStages stage={stage} />
        {renderContent()}
      </div>
    </div>
  )
}

const styles = {
  button: {
    width: "90%",
    backgroundColor: DYNAMO_COLOR,
    borderWidth: 0,
    marginTop: 17,
    height: 48,
    fontSize: 15,
    color: "#ffffff",
    borderRadius: "8px",
  },
}

const mapStateToProps = (state: RootState) => {
  const {
    payment: {
      supervisorSubscriptionInfo,
      showContactUs,
      subscriptionContactUsStatus,
      supervisorSubscriptionInfoFailed,
      gettingSupervisorSubscriptionInfo,
      mentorNotExist,
    },
  } = state
  return {
    supervisorSubscriptionInfo,
    showContactUs,
    subscriptionContactUsStatus,
    supervisorSubscriptionInfoFailed,
    gettingSupervisorSubscriptionInfo,
    mentorNotExist,
  }
}

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AppActions>) => {
  return bindActionCreators(
    {
      getSupervisorSubscriptionInfo,
      submitSubscriptionContactUs,
      resetSubscriptionContactUs,
    },
    dispatch
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(Paywall)
