import React from "react"
import { NavLink } from "react-router-dom"

interface Props {
  onClick: Function
  title: string
  showTutorial?: boolean
  tutorialText?: string
}

const AddStudentListItemBtn = ({
  onClick,
  title,
  showTutorial,
  tutorialText,
}: Props) => {
  return (
    <>
      <button
        onClick={() => onClick()}
        style={{
          width: "100%",
          border: "none",
          background: "transparent",
          padding: 0,
        }}
      >
        <div className="valign-wrapper flexListItem grey lighten-3">
          <div className="valign-wrapper section center small">
            <i
              className="material-icons black-text"
              style={{
                fontSize: 70,
                backgroundColor: "#fff",
                borderRadius: "50%",
                padding: "10px",
                // margin: "10px"
              }}
            >
              add
            </i>
          </div>
          <div className="valign-wrapper section center">
            <div className="title bold">{title}</div>
          </div>
          <div className="valign-wrapper section center hide-on-small-only" />
          <div className="valign-wrapper section center hide-on-small-only" />
          <div className="valign-wrapper section center hide-on-small-only" />
        </div>
      </button>
      {showTutorial && (
        <div className="row center title">
          <img
            className="responsive-img"
            style={{
              width: 44,
              height: 60,
              marginRight: 10,
              paddingBottom: 10,
            }}
            src={require("../../assets/images/illustrated_arrow.png")}
          />
          {tutorialText}
        </div>
      )}
    </>
  )
}

AddStudentListItemBtn.defaultProps = {
  showTutorial: false,
  tutorialText: "",
}

export default AddStudentListItemBtn
