import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { ThunkDispatch } from "redux-thunk"
import { bindActionCreators } from "redux"
import { useTranslation } from "react-i18next"
import Select from "react-select"
import { RootState } from "../../store/reducers/rootReducer"
import { AppActions } from "../../types/actionTypes/actions"
import { isRtl } from "../../locales/localesService"
import CreateBundleInputSection from "./CreateBundleInputSection"
import ImageSelector from "../bundles/BundleView/layouts/ImageSelector"

interface Option {
  value: string
  label: string
}

interface SubTopics {
  [key: string]: Option[]
}

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>

const TOPICS_OPTIONS: Option[] = [
  { value: "eng", label: "English" },
  { value: "math", label: "Math" },
]

const SUB_TOPICS_OPTIONS: SubTopics = {
  eng: [
    { value: "abc", label: "ABC" },
    { value: "vocabulary", label: "Vocabulary" },
    { value: "spelling", label: "Spelling" },
  ],
  math: [
    { value: "adding10", label: "Adding & Sustracting up to 10" },
    { value: "charecteristics", label: "Charecteristics numbers" },
    { value: "multiplication", label: "Multiplication table" },
  ],
}

const PRIVACY_OPTIONS: Option[] = [
  { value: "public", label: "Public" },
  { value: "private", label: "Private" },
]

const PAYED_OPTIONS: Option[] = [
  { value: "free", label: "Free" },
  { value: "payed", label: "Payed" },
]

const selectCustomStyle = {
  container: (base: any) => {
    return {
      ...base,
      flex: 1,
    }
  },
  input: (base: any) => ({
    ...base,
  }),
  control: (base: any) => ({
    ...base,
    borderRadius: "10px",
    border: "1px solid rgb(151, 151, 151)",
  }),
}

const CreateBundleView = (props: Props) => {
  const { t } = useTranslation()
  const [bundleName, setBundleName] = useState("")
  const [bundleImage, setBundleImage] = useState("")
  const [bundleImageUrl, setBundleImageUrl] = useState("")
  const [bundleDescription, setBundleDescription] = useState("")
  const [topic, setTopic] = useState(TOPICS_OPTIONS[0].value)
  const [selectedSubTopic, setSelectedSubTopic] = useState<string>("abc")
  const [subTopics, setSubTopics] = useState<Option[]>(SUB_TOPICS_OPTIONS.eng)
  const [privacy, setPrivacy] = useState(PRIVACY_OPTIONS[0].value)
  const [payed, setPayed] = useState(PAYED_OPTIONS[0].value)
  const [tags, setTags] = useState("")

  useEffect(() => {
    console.log("new topic", topic)

    const subTopics: Option[] = SUB_TOPICS_OPTIONS[topic]
    setSelectedSubTopic(subTopics[0].value)
    setSubTopics(subTopics)
  }, [topic])

  useEffect(() => {
    console.log("new topic sub", selectedSubTopic)
  }, [selectedSubTopic])

  return (
    <div className="contentContainer contentCreator">
      <span className="header title">
        {`${t("bundles")} >`}
        <span className="bold">&nbsp;{`${t("createBundle")}`}</span>
      </span>

      <CreateBundleInputSection title={t("typeBundleName")}>
        <input
          value={bundleName}
          type="text"
          id="bundleName"
          minLength={3}
          onChange={(e) => setBundleName(e.target.value)}
          style={{
            border: "1px solid #979797",
            borderRadius: "10px",
            width: "100%",
            margin: 0,
          }}
        />
      </CreateBundleInputSection>
      <CreateBundleInputSection title={t("chooseBundleIcon")}>
        <ImageSelector
          contentId="bundleImageSelector"
          imageName={bundleImage}
          showAddButton
          updateImageSelected={(val: string) => {
            setBundleImage(val)
            setBundleImageUrl(val)
          }}
        />
      </CreateBundleInputSection>

      <CreateBundleInputSection title={t("bundleDescription")}>
        <textarea
          style={{
            height: "130px",
            border: "1px solid #979797",
            borderRadius: "10px",
            width: "100%",
            margin: 0,
            resize: "none",
          }}
          spellCheck
          value={bundleDescription}
          id="bundleDescription"
          onChange={(e) => setBundleDescription(e.target.value)}
        />
      </CreateBundleInputSection>

      <CreateBundleInputSection title={t("topic")}>
        <Select
          options={TOPICS_OPTIONS}
          defaultValue={TOPICS_OPTIONS[0]}
          onChange={(value: any) => {
            value && value.value && setTopic(value.value)
          }}
          isRtl={isRtl()}
          styles={selectCustomStyle}
        />
      </CreateBundleInputSection>

      <CreateBundleInputSection title={t("subTopic")}>
        <Select
          options={subTopics}
          defaultValue={subTopics[0]}
          value={subTopics.find((option) => option.value === selectedSubTopic)}
          onChange={(value: any) => {
            value && value.value && setSelectedSubTopic(value.value)
          }}
          isRtl={isRtl()}
          styles={selectCustomStyle}
        />
      </CreateBundleInputSection>
      <CreateBundleInputSection title={t("privacy")}>
        <Select
          options={PRIVACY_OPTIONS}
          defaultValue={PRIVACY_OPTIONS[0]}
          onChange={(value: any) => {
            value && value.value && setPrivacy(value.value)
          }}
          isRtl={isRtl()}
          styles={selectCustomStyle}
        />
      </CreateBundleInputSection>
      <CreateBundleInputSection title={t("payed")}>
        <Select
          options={PAYED_OPTIONS}
          defaultValue={PAYED_OPTIONS[0]}
          onChange={(value: any) => {
            value && value.value && setPayed(value.value)
          }}
          isRtl={isRtl()}
          styles={selectCustomStyle}
        />
      </CreateBundleInputSection>

      <CreateBundleInputSection title={t("tags")}>
        <textarea
          style={{
            height: "130px",
            border: "1px solid #979797",
            borderRadius: "10px",
            width: "100%",
            margin: 0,
            resize: "none",
          }}
          spellCheck
          value={tags}
          id="tags"
          onChange={(e) => setTags(e.target.value)}
        />
      </CreateBundleInputSection>
    </div>
  )
}

const mapStateToProps = (state: RootState) => {
  const {} = state

  return {}
}

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AppActions>) => {
  return bindActionCreators({}, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateBundleView)
