import {
  activeStatus,
  genderEnum,
  questionPackageActionInterface,
} from "./generalInterfaces"
import { Bundle } from "./Bundle"
import { LastAnswer } from "./LastAnswer"
import { EPlatform } from "../constants/constants"

export enum ESupervisorMonetizationType {
  SUBSCRIPTION = "SUBSCRIPTION"
}

export enum MonetizationStatus {
  PENDING = "PENDING",
  TRIAL_BEFORE_YOU_BUY = "TRIAL_BEFORE_YOU_BUY",
  IN_TRIAL = "IN_TRIAL",
  PAYWALL = "PAYWALL",
  SUBSCRIBED = "SUBSCRIBED",
  RENEWEL_SUBSCRIBED = "RENEWEL_SUBSCRIBED",
  CANCELLED_SUBSCRIPTION = "CANCELLED_SUBSCRIPTION",
  CANCELLED_TRIAL = "CANCELLED_TRIAL",
  CANCELLED_BILLING_ERROR = "CANCELLED_BILLING_ERROR",
  BILLING_ISSUE = "BILLING_ISSUE",
  PAYWALL_AFTER_CANCELATION = "PAYWALL_AFTER_CANCELATION"
}

export interface Supervised {
  id: string
  supervisedUser?: Child
  monetization: ISupervisedMonetization
  profile: ChileProfile
  stats?:ISupervisedStats
}

export interface ISupervisedMonetization {
  
    monetizationType: ESupervisorMonetizationType
    paywallLink: string;
    status: MonetizationStatus
    createdTimestamp?: number;
    updatedTimestamp?: number;
    payingUserDetails?: { email: string, name: string }
  
}

export interface ISupervisedStats {
  
    totalAnswers?:number
    lastSeen?:number;
    batteryPercentage?:number;
    hasAppearOnTop?:boolean;
    platform?:EPlatform;

}

export interface ChileProfile {
  nickname: string
  locale: string
  lockScreenFrequency: number
  avatarName?: string
  age: number
  enableLockscreen: boolean
  gender: genderEnum,
  connectDetails?: { code: string; shortLink: string }
  questionsPackageActions: questionPackageActionInterface[]
}

export interface Child {
  id: string
  familyId: string
  nickname: string
  state: activeStatus
  stateTimestamp:number
  photoUrl?: string
  packages: Bundle[]
  quickCalls: ClientQuickCallsInterface[]
  lockScreenEnabled: boolean
  hasAppearOnTop: boolean
  moodIndicator?: boolean
  lockScreenFrequency: number
  locale: string
  devices: { model: string; manufacturer: string }[]
  connectDetails?: { code: string; link: string }
  lastAnswers?: LastAnswer[]
}

export interface ClientQuickCallsInterface {
  name: string
  value: string
}

export interface ChildCurrentStatusInterface {
  batteryPercentage?: number
  hasAppearOnTop?: boolean
  lastSeen?: number
  platform?: EPlatform
  totalAnswers?: number
  totalAnswersToday?: number
  totalAnswersLastWeek?: number
  joinDate?: string
}

export interface dailyReportInterface {
  [key: string]: {
    totalAnswers: number
    totalAnswersFromLockScreen: number
    kingdoms?: {
      [key: string]: {
        packages: {
          [key: string]: {
            totalAnswers: number
            totalCorrectAnswers: number
          }
        }
        totalAnswers: number
        totalCorrectAnswers: number
      }
    }
  }
}

export interface ChildInstallLinkResponseInterface {
  childInstallLink: string
  code: string
  id: string
  skipCodeScreen: boolean
}
