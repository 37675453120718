import React from "react"
import { NavLink } from "react-router-dom"
import { connect } from "react-redux"
import { ThunkDispatch } from "redux-thunk"
import { bindActionCreators } from "redux"
import { pathOr } from "ramda"
import { history } from "../../../../App"
import { Supervised } from "../../../../types/Child"
import SQVPhotoName from "./SQVPhotoName"
import SQVActiveBundles from "./SQVActiveBundles"
import SQVTotalQuestions from "./SQVTotalQuestions"
import SQVGrade from "./SQVGrade"
import SQVTrend from "./SQVTrend"
import SQVPlatform from "./SQVPlatform"
import SQVStatus from "./SQVStatus"
import avatar from "../../../../assets/images/av_1_on.png"
import { AppActions } from "../../../../types/actionTypes/actions"
import {
  updateSelectedSupervisedId,
  getSupervisedInstallLink,
} from "../../../../store/actions/childActions"
import { RootState } from "../../../../store/reducers/rootReducer"
import { AVATARS, EPlatform } from "../../../../constants/constants"
import { Bundle } from "../../../../types/Bundle"

interface IProps {
  supervised: Supervised
  bundles: Bundle[]
  disabled?: boolean
}

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  IProps

const StudentQuickView = ({
  supervised,
  bundles,
  updateSelectedSupervisedId,
  disabled,
  getSupervisedInstallLink,
  updatingConnectionDetails,
  gettingConnectionDetails,
}: Props) => {
  const getSupervisedAvatar = () => {
    let res = null
    const avatarName = pathOr(null, ["profile", "avatarName"], supervised)
    if (avatarName) {
      res = AVATARS.find((avatar) => avatar.name === avatarName)?.url
    }
    return res || avatar
  }

  return (
    <NavLink
      style={{ color: "black", cursor: disabled ? "default" : "pointer" }}
      onClick={() => {
        !disabled && updateSelectedSupervisedId(supervised.id)
      }}
      to={{
        pathname: "/child",
      }}
    >
      <div className="valign-wrapper flexListItem grey lighten-3">
        <div
          className="valign-wrapper section center small"
          style={{ position: "relative" }}
        >
          <SQVPhotoName
            nickname={supervised.profile.nickname}
            showBundles={bundles.length > 0}
            photoUrl={getSupervisedAvatar()}
            status={
              supervised.supervisedUser && supervised.supervisedUser.state
            }
          />
          <button
            type="button"
            className="btn-floating"
            style={{
              backgroundColor: "#bababa",
              position: "absolute",
              top: 10,
              right: 0,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: 25,
              height: 25,
            }}
            onClick={(e) => {
              e.preventDefault()
              e.stopPropagation()
              history.push({
                pathname: "/createChild",
                state: { isEdit: true, supervised },
              })
            }}
          >
            <i className="material-icons" style={{ fontSize: 15 }}>
              edit
            </i>
          </button>
        </div>
        <div className="valign-wrapper section center hide-on-small-only">
          {bundles?.length > 0 ? (
            <SQVActiveBundles packages={bundles} />
          ) : (
            <div className="flow-text title">{supervised.profile.nickname}</div>
          )}
        </div>

        <div className="valign-wrapper section center hide-on-med-and-down">
          <SQVTotalQuestions
            title="Total answers"
            value={supervised.stats && supervised.stats.totalAnswers}
          />
        </div>
        <div className="valign-wrapper section center hide-on-med-and-down">
          <SQVPlatform
            platform={supervised?.stats?.platform}
            hasApeareOnTop={supervised?.supervisedUser?.hasAppearOnTop}
          />
        </div>
        <div className="valign-wrapper section large center">
          <SQVStatus
            supervised={supervised}
            onRefresh={() => {
              getSupervisedInstallLink(supervised.id)
            }}
            refreshing={
              updatingConnectionDetails[supervised.id] &&
              updatingConnectionDetails[supervised.id].isUpdating
            }
            loading={gettingConnectionDetails}
          />
        </div>
      </div>
    </NavLink>
  )
}

StudentQuickView.defaultProps = {
  bundles: [],
  disabled: false,
}

const mapStateToProps = (state: RootState) => {
  const {
    loaders: { updatingConnectionDetails, gettingConnectionDetails },
  } = state

  return {
    updatingConnectionDetails,
    gettingConnectionDetails,
  }
}

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AppActions>) => {
  return bindActionCreators(
    {
      updateSelectedSupervisedId,
      getSupervisedInstallLink,
    },
    dispatch
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(StudentQuickView)
