import { Bundle } from "../Bundle"
import { Kingdom } from "../Kingdom"
import { Question } from "../Question"

export enum EKingdomActionsTypes {
  GET_KINGDOMS = "GET_KINGDOMS",
  GET_KINGDOMS_FAIL = "GET_KINGDOMS_FAIL",
  UPDATE_KINGDOMS = "UPDATE_KINGDOMS",
  GET_QUESTIONS_FOR_PACKAGEID_KINGDOM_SUCCESS = "GET_QUESTIONS_FOR_PACKAGEID_KINGDOM_SUCCESS",
  GET_PUBLIC_PERSONALIZED_BUNDLES_START = "GET_PUBLIC_PERSONALIZED_BUNDLES_START",
  GET_PUBLIC_PERSONALIZED_BUNDLES_SUCCESS = "GET_PUBLIC_PERSONALIZED_BUNDLES_SUCCESS",
  GET_PUBLIC_PERSONALIZED_BUNDLES_FAIL = "GET_PUBLIC_PERSONALIZED_BUNDLES_FAIL",
}

export interface getKingdomsAction {
  type: typeof EKingdomActionsTypes.GET_KINGDOMS
}

export interface getKingdomsFailAction {
  type: typeof EKingdomActionsTypes.GET_KINGDOMS_FAIL
}

export interface updateKingdomsAction {
  type: typeof EKingdomActionsTypes.UPDATE_KINGDOMS
  kingdoms: Kingdom[]
}

export interface getQuestionsForPackageIdAction {
  type: typeof EKingdomActionsTypes.GET_QUESTIONS_FOR_PACKAGEID_KINGDOM_SUCCESS
  questions: Question[]
  bundleId: string
  kingdomId: string
}

export interface getPublicPersonalizedBundlesStartAction {
  type: typeof EKingdomActionsTypes.GET_PUBLIC_PERSONALIZED_BUNDLES_START
}

export interface getPublicPersonalizedBundlesSuccessAction {
  type: typeof EKingdomActionsTypes.GET_PUBLIC_PERSONALIZED_BUNDLES_SUCCESS
  bundels: Bundle[]
}

export interface getPublicPersonalizedBundlesFailAction {
  type: typeof EKingdomActionsTypes.GET_PUBLIC_PERSONALIZED_BUNDLES_FAIL
}

export type KingdomActionTypes =
  | getKingdomsAction
  | getKingdomsFailAction
  | updateKingdomsAction
  | getQuestionsForPackageIdAction
  | getPublicPersonalizedBundlesStartAction
  | getPublicPersonalizedBundlesSuccessAction
  | getPublicPersonalizedBundlesFailAction
