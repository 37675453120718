import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { ThunkDispatch } from "redux-thunk"
import { bindActionCreators } from "redux"
import * as _ from "lodash"
import copy from "clipboard-copy"
import { animated, useSpring } from "react-spring"
import { NavLink } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { pathOr } from "ramda"
import { RootState } from "../../../store/reducers/rootReducer"
import { AppActions } from "../../../types/actionTypes/actions"
import {
  getSelectedSupervised,
  getSelectedSupervisedBundles,
  getSelectedChildLastAnswers,
} from "../../../store/selectors/supervisedSelector"
import StudentQuickView from "./StudentQuickView/StudentQuickView"
import BundleQuickView from "../BundleQuickView"
import {
  questionPackageActionInterface,
  packageActionType,
} from "../../../types/generalInterfaces"
import {
  addRemoveBundlesToChild,
  updateSelectedSupervisedId,
} from "../../../store/actions/childActions"
import Loader from "../../layout/Loader"
import AddStudentListItemBtn from "../AddStudentListItemBtn"
import ChangeBundles from "../ChangeBundles"
import { DynaButton } from "../../common/DynaButton"
import { Bundle } from "../../../types/Bundle"
import { getAllBundlesByIds } from "../../../store/selectors/kingdomsSelector"
import QuestionQuickView from "../../bundles/BundleView/QuestionQuickView"
import { DYNAMO_COLOR } from "../../../constants/constants"

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>

let timeoutRef: NodeJS.Timer | null = null

const SingleStudentView = ({
  gettingStudentData,
  selectedSupervised,
  selectedSupervisedBundles,
  addRemoveBundlesToChild,
  allBundlesByIds,
  selectedSupervisedLastAnswers,
  updatingLastAnswers,
  updateSelectedSupervisedId,
}: Props) => {
  const { t } = useTranslation()
  const [showAddMoreBundles, setShowAddMoreBundles] = useState(false)
  const [gettingLastAnswers, setGettingLastAnswers] = useState(false)
  const [addRemoveArray, setAddRemoveArray] = useState<
    questionPackageActionInterface[]
  >([])

  const [addRemoveNewBundles, setAddRemoveNewBundles] = useState<
    questionPackageActionInterface[]
  >([])

  const fadeAnimationTabs = useSpring({
    opacity: showAddMoreBundles ? 0 : 1,
    duration: 200,
  })

  const fadeAnimationMoreBundles = useSpring({
    opacity: showAddMoreBundles ? 1 : 0,
    duration: 200,
  })

  useEffect(() => {
    if (
      selectedSupervised &&
      updatingLastAnswers[selectedSupervised?.id] &&
      updatingLastAnswers[selectedSupervised?.id].isUpdating
    ) {
      setGettingLastAnswers(true)
    } else {
      setGettingLastAnswers(false)
    }
  }, [selectedSupervised, updatingLastAnswers])

  useEffect(() => {
    const elems = document.querySelectorAll(".tabs")
    M.Tabs.init(elems)
  }, [showAddMoreBundles])

  useEffect(() => {
    timeoutRef && clearTimeout(timeoutRef)

    if (selectedSupervised && addRemoveArray.length > 0) {
      timeoutRef = setTimeout(() => {
        addRemoveBundlesToChild(selectedSupervised?.id, addRemoveArray)
        setAddRemoveArray([])
      }, 2000)
    }
  }, [addRemoveArray])

  const renderHeader = () => {
    const shortLink = pathOr(
      "",
      ["profile", "connectDetails", "shortLink"],
      selectedSupervised
    )
    return (
      <div className="header" style={{ justifyContent: "space-between" }}>
        <div className="title bold">
          {`Students >`}
          <span className="bold">
            &nbsp;{selectedSupervised?.profile.nickname}
          </span>
        </div>
        <div style={{ display: "flex" }}>
          <DynaButton
            text={t("pairingLink")}
            onClick={() => {
              M.toast({ html: t("linkCopied") })
              shortLink && copy(shortLink)
            }}
            customContainerStyle={{ margin: "0 auto" }}
          />
          <button
            className="btn dynamoBackground"
            onClick={() =>
              selectedSupervised &&
              updateSelectedSupervisedId(selectedSupervised?.id)
            }
            style={{
              borderRadius: "10px",
              margin: "0 10px",
              background: DYNAMO_COLOR,
              height: "50px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <i className="material-icons" style={{ fontSize: 20 }}>
              refresh
            </i>
          </button>
          <DynaButton
            onClick={() => setShowAddMoreBundles(!showAddMoreBundles)}
            text={showAddMoreBundles ? "Cancel" : "Add more bundles"}
          />
        </div>
      </div>
    )
  }

  const handleChange = ({
    bundleId,
    add,
  }: {
    bundleId: string
    add: boolean
  }) => {
    const action = add ? packageActionType.ADD : packageActionType.REMOVE

    // firts find if there is a packageId for this that aleady exists
    const pIdExistsIndex = _.findIndex(addRemoveArray, (obj) => {
      return obj.id === bundleId
    })

    if (pIdExistsIndex !== -1) {
      // it exists
      const newArray = addRemoveArray.filter((_, i) => i !== pIdExistsIndex)
      setAddRemoveArray(newArray)
      return
    }

    // add the bundle to the array
    const newBundleAction = {
      id: bundleId,
      action,
    } as questionPackageActionInterface /// /TODO practiced language

    const newAddRemoveArray = [...addRemoveArray, newBundleAction]
    setAddRemoveArray(newAddRemoveArray)
  }

  const renderTabs = () => {
    return (
      <animated.div style={{ flex: 1, ...fadeAnimationTabs }}>
        <div style={{ flex: 1 }}>
          <ul className="tabs">
            <li className="tab col s6">
              <a href="#report">Answered questions</a>
            </li>
            <li className="tab col s6">
              <a href="#bundles">Student&apos;s Bundles</a>
            </li>
          </ul>
        </div>
        <div
          id="report"
          style={{
            flex: 1,
            height: "100vh",
            overflow: "auto",
            padding: "0 10%",
          }}
        >
          {gettingLastAnswers && <Loader />}
          {!gettingLastAnswers && selectedSupervisedLastAnswers.length === 0 && (
            <div
              className="row title bold"
              style={{ margin: "40px 0", textAlign: "center" }}
            >
              No last answers
            </div>
          )}
          {!gettingLastAnswers &&
            selectedSupervisedLastAnswers.map((q, index) => {
              return (
                <QuestionQuickView
                  key={index}
                  question={q.question}
                  choosenAnswerId={q.answerTries[0]?.id}
                  index={selectedSupervisedLastAnswers.length - index}
                  viewOnlyMode
                  timestamp={q.timestamp}
                />
              )
            })}
        </div>
        <div id="bundles" style={{ flex: 1, alignItems: "center" }}>
          {gettingStudentData && <Loader />}
          {!gettingStudentData &&
            selectedSupervisedBundles.map((bundle, index) => {
              return (
                <BundleQuickView
                  key={index}
                  name={bundle.name}
                  id={bundle.id}
                  imageUrl={bundle.imageUrl}
                  totalQuestions={bundle.answeredQuestions}
                  enabled={
                    bundle.status === "ENABLED" || bundle.status === "FRESH"
                  }
                  onClick={({
                    bundleId,
                    add,
                  }: {
                    bundleId: string
                    add: boolean
                  }) => handleChange({ bundleId, add })}
                />
              )
            })}
          <AddStudentListItemBtn
            onClick={() => setShowAddMoreBundles(true)}
            title="Add more bundles"
          />
        </div>
      </animated.div>
    )
  }

  const renderSelectedBundles = () => {
    return addRemoveNewBundles.map((o, index) => {
      const bundle: Bundle | undefined | null = allBundlesByIds[o.id]
      const added = o.action === packageActionType.ADD
      if (bundle) {
        return (
          <div key={index} style={{ position: "relative" }}>
            <div
              className="center"
              style={{
                position: "absolute",
                top: 0,
                right: 1,
                width: 24,
                height: 24,
                borderRadius: 12,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: added ? "green" : "red",
              }}
            >
              <i className="material-icons white-text" style={{ fontSize: 20 }}>
                {added ? "add" : "remove"}
              </i>
            </div>
            <img
              className="responsive-img"
              style={{
                width: 80,
                height: 80,
                margin: "0 10px",
              }}
              src={bundle.imageUrl}
              alt=""
            />
          </div>
        )
      }

      return null
    })
  }

  const renderAddMoreBundles = () => {
    return (
      <animated.div
        style={{
          ...fadeAnimationMoreBundles,
          height: "100vh",
          overflow: "auto",
        }}
      >
        <div className="row">
          <div
            className="col s12 l4"
            style={{ padding: 0, margin: "48px 0 0 0" }}
          >
            <div
              className="center"
              style={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "center",
                flex: 1,
                minHeight: 200,
                backgroundColor: "#eeeeee",
                padding: 20,
              }}
            >
              {addRemoveNewBundles.length === 0 ? (
                <div className="subtitle bold">
                  Please select bundles to update for student
                </div>
              ) : (
                renderSelectedBundles()
              )}
            </div>

            <div
              style={{
                display: "flex",
                paddingTop: 18,
              }}
            >
              <DynaButton
                text="Update bundles"
                disabled={addRemoveNewBundles.length === 0}
                onClick={() => {
                  selectedSupervised &&
                    addRemoveBundlesToChild(
                      selectedSupervised.id,
                      addRemoveNewBundles
                    )
                  setAddRemoveNewBundles([])
                  setShowAddMoreBundles(false)
                }}
                customContainerStyle={{ margin: "0 auto" }}
              />
              <div style={{ flex: 0.5 }} />
              <DynaButton
                text="Cancel"
                onClick={() => {
                  setAddRemoveNewBundles([])
                  setShowAddMoreBundles(false)
                }}
                customContainerStyle={{ margin: "0 auto" }}
              />
            </div>
          </div>
          <div className="col s12 l1" />
          <div className="col s12 l7" style={{ padding: 0 }}>
            <ChangeBundles
              childBundles={selectedSupervisedBundles}
              addRemoveBundlesToChild={(
                addRemoveArrayTemp: questionPackageActionInterface[]
              ) => {
                setAddRemoveNewBundles(addRemoveArrayTemp)
              }}
              addRemoveArray={addRemoveNewBundles}
            />
          </div>
        </div>
      </animated.div>
    )
  }

  return (
    <div className="row contentContainer">
      {renderHeader()}
      {selectedSupervised && (
        <StudentQuickView
          supervised={selectedSupervised}
          bundles={selectedSupervisedBundles}
          disabled
        />
      )}
      {showAddMoreBundles ? renderAddMoreBundles() : renderTabs()}
    </div>
  )
}

const mapStateToProps = (state: RootState) => {
  const {
    loaders: { gettingStudentData, updatingLastAnswers },
  } = state
  const selectedSupervised = getSelectedSupervised(state)
  const selectedSupervisedBundles = getSelectedSupervisedBundles(state)
  const selectedSupervisedLastAnswers = getSelectedChildLastAnswers(state)
  const allBundlesByIds = getAllBundlesByIds(state)

  return {
    gettingStudentData,
    selectedSupervised,
    selectedSupervisedBundles,
    allBundlesByIds,
    selectedSupervisedLastAnswers,
    updatingLastAnswers,
  }
}

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AppActions>) => {
  return bindActionCreators(
    {
      addRemoveBundlesToChild,
      updateSelectedSupervisedId,
    },
    dispatch
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(SingleStudentView)
