import React, { useEffect } from "react"
import { connect } from "react-redux"
import { ThunkDispatch } from "redux-thunk"
import { bindActionCreators } from "redux"
import * as _ from "lodash"
import { NavLink } from "react-router-dom"
import { RootState } from "../../store/reducers/rootReducer"
import { AppActions } from "../../types/actionTypes/actions"
import { DYNAMO_COLOR } from "../../constants/constants"
import { showPopupById } from "../../store/actions/popupActions";
import { EPopupTypes } from "../../types/Popups";
import {
  getSupervisorsList
} from "../../store/actions/userActions"
import SupervisorQuickView from "./SupervisorQuickView";

interface IProps {}

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  IProps

const SupervisorsView = (props: Props) => {
  const {
    user: { supervisors },
    showPopupById,
    getSupervisorsList
  } = props

  useEffect(() => {
    getSupervisorsList()
  }, [getSupervisorsList])

  return (
    <div className="" style={{ width: "100%", paddingLeft: "50px" }}>
      <div className="row" style={{ paddingTop: "50px" }}>
        <div className="col s3">
          <h5>Supervisors</h5>
        </div>
        <div className="col s3 offset-s3 center-align cyan">
        
              <button
                onClick={() =>
                  showPopupById(EPopupTypes.MANAGE_SUPERVISORS_POPUP)
                }
                className="btn-flat"
              >
                <h6 className="white-text">Add new supervisors</h6>
              </button>
            
        </div>
      </div>

      {supervisors && supervisors.map((sup, index) => {
        return <SupervisorQuickView supervisor={sup} key={sup.id} />
      })}
    </div>
  )
}

const mapStateToProps = (state: RootState) => {
  const { user } = state

  return {
    user,
  }
}

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AppActions>) => {
  return bindActionCreators(
    {
      showPopupById,
      getSupervisorsList
    },
    dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(SupervisorsView)
