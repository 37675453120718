export enum EPopupTypes {
  LOGIN_POPUP = "LOGIN_POPUP",
  LOGOUT_POPUP = "LOGOUT_POPUP",
  LOADING_POPUP = "LOADING_POPUP",
  GENERAL_ERROR_POPUP = "GENERAL_ERROR_POPUP",
  ACCOUNT_EXISTS_POPUP = "ACCOUNT_EXISTS_POPUP",
  MANAGE_SUPERVISORS_POPUP = "MANAGE_SUPERVISORS_POPUP",
  SELECT_SUPERVISED_POPUP = "SELECT_SUPERVISED_POPUP",
  UPLOAD_PROFILE_IMAGE_POPUP = "UPLOAD_PROFILE_IMAGE_POPUP",
  SELECT_STUDENT_AVATAR_POPUP = "SELECT_STUDENT_AVATAR_POPUP",
}
