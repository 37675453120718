export enum EAssetsActionTypes {
  UPLOADING = "UPLOADING",
  DOWNLOADING = "DOWNLOADING",
  UPLOAD_SUCCESS = "UPLOAD_SUCCESS",
  UPLOAD_FAILED = "UPLOAD_FAILED",
  GET_IMAGE_URL_SUCCESS = "GET_IMAGE_URL_SUCCESS",
  GET_IMAGE_URL_FAILED = "GET_IMAGE_URL_FAILED",
  GET_SPRITE_SUCCESS = "GET_SPRITE_SUCCESS",
  GET_SPRITE_FAILED = "GET_SPRITE_FAILED",
  GET_IMAGES_SUCCESS = "GET_IMAGES_SUCCESS",
  GET_IMAGES_FAILED = "GET_IMAGES_FAILED",
}

export interface uploadImage {
  type: typeof EAssetsActionTypes.UPLOADING
  progress: number
  imageName: string
}

export interface downloadingImage {
  type: typeof EAssetsActionTypes.DOWNLOADING
  imageName: string
}

export interface uploadImageSuccess {
  type: typeof EAssetsActionTypes.UPLOAD_SUCCESS
  imageName: string
  downloadURL: string
}

export interface uploadImageFailed {
  type: typeof EAssetsActionTypes.UPLOAD_FAILED
  imageName: string
}

export interface getImageURLSuccess {
  type: typeof EAssetsActionTypes.GET_IMAGE_URL_SUCCESS
  imageName: string
  downloadURL: string
}

export interface getImageURLFailed {
  type: typeof EAssetsActionTypes.GET_IMAGE_URL_FAILED
  imageName: string
}

export interface getSpriteSuccess {
  type: typeof EAssetsActionTypes.GET_SPRITE_SUCCESS
  spritesData: { [key: string]: { fileName: string; link: string } }
}

export interface getSpriteFailed {
  type: typeof EAssetsActionTypes.GET_SPRITE_FAILED
}

export interface getImagesSuccess {
  type: typeof EAssetsActionTypes.GET_IMAGES_SUCCESS
  imagesData: { [key: string]: { fileName: string; link: string } }
}

export interface getImagesFailed {
  type: typeof EAssetsActionTypes.GET_IMAGES_FAILED
}

export type AssetsActionTypes =
  | getImagesFailed
  | getImagesSuccess
  | getSpriteSuccess
  | getSpriteFailed
  | downloadingImage
  | uploadImage
  | uploadImageSuccess
  | uploadImageFailed
  | getImageURLSuccess
  | getImageURLFailed
