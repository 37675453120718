import React from "react"
import ContentInput from "./layouts/ContentInput"
import {
  ContentInputType,
  ECharacterCount,
  contentTranslationType,
} from "../../../types/generalInterfaces"
import { AnswerOptionsContent } from "../../../types/Content"
import { CharacterCounter } from "materialize-css";

interface IProps {
  placeHolder: string
  index: number
  updateAnswer: Function
  addAnotherAnswer: Function
  deleteAnswer: Function
  maxAmountOfAnswers: number
  inputType: ContentInputType
  content: AnswerOptionsContent,
  maxCharacters:ECharacterCount
}

const AnswerInputView = ({
  placeHolder,
  index,
  content,
  updateAnswer,
  addAnotherAnswer,
  deleteAnswer,
  maxAmountOfAnswers,
  inputType,
  maxCharacters
}: IProps) => {
  const enableAddButton =
    content.text !== "" || content.image !== "" ? "enabled" : "disabled"
  const hideDeleteAnswer = index === 0 ? "hide" : ""
  const hideAddAnswer = index < maxAmountOfAnswers - 1 ? "" : "hide"

  const update = (
    type: ContentInputType,
    val: string,
    translationType: contentTranslationType
  ) => {
    updateAnswer({ type, val, correct: content.correct, translationType })
  }

  return (
    <div className="row">
      
      <div className="switch col s2 valign-wrapper add-height">
        <div className="row">
          <label className="col s12" style={{textAlign:"center"}}>
            {content.correct ? "True" : "False"}
          </label>
          <label className="col s12">
            <input type="checkbox" defaultChecked={content.correct} onClick={(e) => {
              updateAnswer({
                text: content.text,
                correct: e.currentTarget.checked,
              })
            }} />
            <span className="lever"></span>
          </label>
        </div>
      </div>

      <div className="col s8">
        <ContentInput
          id={`answer${index}`}
          showTranslationSelector={false}
          inputType={inputType}
          characterCount={maxCharacters}
          content={content}
          textLabel={placeHolder}
          updateValue={(
            type: ContentInputType,
            val: string,
            translationType: contentTranslationType
          ) => {
            update(type, val, translationType)
          }}
        />
      </div>

      <div className="col s1">
        <button
          type="button"
          className={`btn-floating btn-small green ${enableAddButton} ${hideAddAnswer}`}
          onClick={() => addAnotherAnswer()}
        >
          <i className="material-icons">add</i>
        </button>
      </div>
      <div className="col s1">
        <button
          type="button"
          className={`btn-floating btn-small red ${hideDeleteAnswer}`}
          onClick={() => deleteAnswer()}
        >
          <i className="material-icons">clear</i>
        </button>
      </div>
    </div>
  )
}

export default AnswerInputView
