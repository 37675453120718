import React, { useState, useEffect } from "react"

interface Props {
  imageUrl?: string
  fallbackImageUrl: string
  customStyle?: object
  width?: number
  height?: number
}

const ImageWithFallback = ({
  imageUrl,
  fallbackImageUrl = require("../../assets/images/default_profile_image.png"),
  customStyle = {},
  width = 120,
  height = 120,
}: Props) => {
  const [localImageUrl, setLocalImageUrl] = useState("")

  useEffect(() => {
    setLocalImageUrl(imageUrl || fallbackImageUrl)
  }, [imageUrl])

  return (
    <div>
      <img
        alt=""
        style={{
          width,
          height,
          ...customStyle,
        }}
        className="circle"
        onError={() => setLocalImageUrl(fallbackImageUrl)}
        src={localImageUrl}
      />
    </div>
  )
}

export { ImageWithFallback }
