import { Dispatch } from "redux"
import { EKingdomActionsTypes } from "../../types/actionTypes/KingdomActionTypes"
import { AppActions } from "../../types/actionTypes/actions"
import { requestWrapper2 } from "../../services/ServerConnection"
import { Kingdom } from "../../types/Kingdom"
import { Bundle } from "../../types/Bundle"

export const updateKingdoms = (kingdoms: Kingdom[]): AppActions => ({
  type: EKingdomActionsTypes.UPDATE_KINGDOMS,
  kingdoms,
})

export const getKingdoms = (): any => async (dispatch: Dispatch) => {
  try {
    dispatch({
      type: EKingdomActionsTypes.GET_KINGDOMS,
    })

    const res = await requestWrapper2("getBundles")

    if (res.status === 200) {
      const kingdoms = res.data as Kingdom[]
      dispatch(updateKingdoms(kingdoms))
    }
  } catch (error) {
    dispatch({
      type: EKingdomActionsTypes.GET_KINGDOMS_FAIL,
    })
    console.error(error)
  }
}

export const getPublicPersonalizedBundles = (): any => async (
  dispatch: Dispatch<AppActions>
) => {
  try {
    dispatch({
      type: EKingdomActionsTypes.GET_PUBLIC_PERSONALIZED_BUNDLES_START,
    })

    // parentId,familyId,packageId,name,imageName,questions,description,status,supportedLocales
    const res = await requestWrapper2("getPublicPersonalizedBundles")
    const bundels = res.data as Bundle[]

    dispatch({
      type: EKingdomActionsTypes.GET_PUBLIC_PERSONALIZED_BUNDLES_SUCCESS,
      bundels,
    })
  } catch (error) {
    dispatch({
      type: EKingdomActionsTypes.GET_PUBLIC_PERSONALIZED_BUNDLES_FAIL,
    })
    console.error(error)
  }
}
