import { SupervisorSubscriptionInfo } from "../Payments"

export enum EPaymentActionTypes {
  GET_SUPERVISOR_SUBSCRIPTION_INFO_START = "GET_SUPERVISOR_SUBSCRIPTION_INFO",
  GET_SUPERVISOR_SUBSCRIPTION_INFO_FAILED = "GET_SUPERVISOR_SUBSCRIPTION_INFO_FAILED",
  GET_SUPERVISOR_SUBSCRIPTION_INFO_SUCCESS = "GET_SUPERVISOR_SUBSCRIPTION_INFO_SUCCESS",
  SHOW_SUBSCRIPTION_CONTACT_US = "SHOW_SUBSCRIPTION_CONTACT_US",
  SUBMIT_SUBSCRIPTION_CONTACT_US_START = "SUBMIT_SUBSCRIPTION_CONTACT_US_START",
  SUBMIT_SUBSCRIPTION_CONTACT_US_FAILED = "SUBMIT_SUBSCRIPTION_CONTACT_US_FAILED",
  SUBMIT_SUBSCRIPTION_CONTACT_US_SUCCESS = "SUBMIT_SUBSCRIPTION_CONTACT_US_SUCCESS",
  RESET_SUBMIT_SUBSCRIPTION_CONTACT_US = "RESET_SUBMIT_SUBSCRIPTION_CONTACT_US",
  SET_MENTOR_NOT_EXIST = "SET_MENTOR_NOT_EXIST",
}

export interface getSupervisorSubscriptionInfoStart {
  type: typeof EPaymentActionTypes.GET_SUPERVISOR_SUBSCRIPTION_INFO_START
}

export interface getSupervisorSubscriptionInfoFailed {
  type: typeof EPaymentActionTypes.GET_SUPERVISOR_SUBSCRIPTION_INFO_FAILED
}

export interface getSupervisorSubscriptionInfoSuccess {
  type: typeof EPaymentActionTypes.GET_SUPERVISOR_SUBSCRIPTION_INFO_SUCCESS
  supervisorSubscriptionInfo: SupervisorSubscriptionInfo
}

export interface showSubscriptionContactUs {
  type: typeof EPaymentActionTypes.SHOW_SUBSCRIPTION_CONTACT_US
}

export interface submitSubscriptionContactUsStart {
  type: typeof EPaymentActionTypes.SUBMIT_SUBSCRIPTION_CONTACT_US_START
}

export interface submitSubscriptionContactUsFailed {
  type: typeof EPaymentActionTypes.SUBMIT_SUBSCRIPTION_CONTACT_US_FAILED
}

export interface submitSubscriptionContactUsSuccess {
  type: typeof EPaymentActionTypes.SUBMIT_SUBSCRIPTION_CONTACT_US_SUCCESS
}

export interface resetSubmitSubscriptionContactUs {
  type: typeof EPaymentActionTypes.RESET_SUBMIT_SUBSCRIPTION_CONTACT_US
}

export interface setMentorNotExist {
  type: typeof EPaymentActionTypes.SET_MENTOR_NOT_EXIST
}

export type PaymentActionTypes =
  | getSupervisorSubscriptionInfoStart
  | getSupervisorSubscriptionInfoFailed
  | getSupervisorSubscriptionInfoSuccess
  | showSubscriptionContactUs
  | submitSubscriptionContactUsStart
  | submitSubscriptionContactUsFailed
  | submitSubscriptionContactUsSuccess
  | resetSubmitSubscriptionContactUs
  | setMentorNotExist
