import React from "react"
import { Question } from "../../../types/Question"
import ContentView, { EContentViewSize } from "./layouts/ContentView"
import { layoutTypes } from "../../../types/generalInterfaces"
import { getTimeByFormat } from "../../../utils/utils"

interface IProps {
  question: Question
  index: number
  viewOnlyMode: boolean
  choosenAnswerId?: string
  editQuestion?: Function
  deleteQuestion?: Function
  duplicateQuestion?: Function
  timestamp?: number
}

const QuestionQuickView = ({
  question,
  editQuestion,
  deleteQuestion,
  index,
  viewOnlyMode,
  choosenAnswerId,
  duplicateQuestion,
  timestamp,
}: IProps) => {
  const renderAnswers = () => {
    let isVertical = true
    switch (question.layoutType) {
      // vertical
      case layoutTypes.GENERIC_DID_YOU_KNOW:
      case layoutTypes.VERTICAL_TEXT_TWO_LINES_1_TO_4_ANSWERS_WHITE_BACKGROUND:
      case layoutTypes.VERTICAL_TEXT_ONE_LINE_1_TO_4_ANSWERS_WHITE_BACKGROUND:
      case layoutTypes.DID_YOU_KNOW_VERTICAL_A:
      case layoutTypes.BE_INTERNET_AWESOME_VERTICAL_A:
      case layoutTypes.VERTICAL_TEXT_ONE_LINE_1_TO_4_ANSWERS_GREEN_BACKGROUND:
      case layoutTypes.VERTICAL_TEXT_TWO_LINES_1_TO_4_ANSWERS_GREEN_BACKGROUND: {
        isVertical = true
        break
      }
      // horizontal
      case layoutTypes.HORIZONTAL_A:
      case layoutTypes.HORIZONTAL_IMAGE_320PX_1_TO_4_ANSWERS_NO_BACKGROUND:
      case layoutTypes.HORIZONTAL_TWO_ROWS_IMAGE_220PX_AND_BOTTOM_TEXT_1_TO_8_ANSWERS_GREEN_BACKGROUND:
      case layoutTypes.HORIZONTAL_TWO_ROWS_IMAGE_220PX_AND_BOTTOM_TEXT_1_TO_8_ANSWERS_WHITE_BACKGROUND: {
        isVertical = false
        break
      }
      default:
        // vertical
        isVertical = true
        break
    }
    return (
      <div
        style={{
          flexGrow: 3,
          flexDirection: isVertical ? "column" : "row",
          display: "flex",
          flexWrap: "wrap",
        }}
      >
        {question.contentData.answers.map((answer, index) => {
          const showIndicatorIcon =
            answer.id === choosenAnswerId ||
            (!choosenAnswerId && answer.correct)

          return (
            <div
              key={index}
              style={{ position: "relative", flex: 1, padding: 10 }}
            >
              {showIndicatorIcon && (
                <div
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                  }}
                >
                  <img
                    style={{ width: 20, height: 20 }}
                    src={
                      answer.correct
                        ? require("../../../assets/images/chk_box_correct.png")
                        : require("../../../assets/images/chk_box_wrong.png")
                    }
                  />
                </div>
              )}
              <div
                key={answer.id}
                style={{
                  borderRadius: "5px",
                  border: "1px solid #979797",
                  flex: 1,
                  color: "#455d16",
                  minHeight: 85,
                  minWidth: 85,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <ContentView content={answer} size={EContentViewSize.SMALL} />
              </div>
            </div>
          )
        })}
      </div>
    )
  }

  return (
    <div
      className="row valign-wrapper"
      style={{
        backgroundColor: "#eeeeee",
        paddingLeft: "10px",
        borderBottom: "thin black solid",
      }}
    >
      <div className={`col ${viewOnlyMode ? "s12" : "s10"}`} style={{}}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div
            className="smallText"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "30px",
              height: "30px",
              textAlign: "center",
              color: "white",
              borderRadius: "50%",
              backgroundColor: "#d8d8d8",
            }}
          >
            {index + 1}
          </div>
          {timestamp && (
            <div>{getTimeByFormat(timestamp, "dddd, MMMM Do YYYY, HH:mm")}</div>
          )}
        </div>
        <div className="row" style={{ marginBottom: "0px" }}>
          <div className="col s1" style={{ fontSize: "20px" }}>
            Q:
          </div>
          <div className="col s10" style={{ minHeight: "72px" }}>
            <ContentView
              content={question.contentData}
              size={EContentViewSize.HEADER}
              style={{
                justifyContent: "flex-start",
                alignItems: "flex-start",
                fontSize: 20,
                flexDirection: "row",
              }}
            />
          </div>
        </div>
        <div className="row" style={{ marginBottom: "0px" }}>
          <div className="col s1" style={{ fontSize: "20px" }}>
            A:
          </div>
          <div className="col s10" style={{}}>
            {renderAnswers()}
          </div>
        </div>
      </div>

      <div className={`col s2 ${viewOnlyMode ? "hide" : "show"}`}>
        <div className="row">
          <button
            className="col s6"
            style={{ borderStyle: "none" }}
            type="button"
            onClick={() => {
              editQuestion && editQuestion(index)
            }}
          >
            <i className="material-icons">mode_edit</i>
          </button>
          <button
            className="col s6"
            style={{ borderStyle: "none" }}
            type="button"
            onClick={() => {
              if (
                window.confirm("Are you sure you want to delete this question?")
              ) {
                // delete it!
                deleteQuestion && deleteQuestion(index)
              } else {
                // Do nothing!
              }
            }}
          >
            <i className="material-icons lighten-3">delete</i>
          </button>
        </div>
        <div className="row">
          <button
            className="col s12"
            style={{ borderStyle: "none" }}
            type="button"
            onClick={() => {
              duplicateQuestion && duplicateQuestion(index)
            }}
          >
            <i className="material-icons">content_copy</i>
          </button>
        </div>
      </div>
    </div>
  )
}

QuestionQuickView.defaultProps = {
  editQuestion: () => {},
  deleteQuestion: () => {},
  duplicateQuestion: () => {},
}

export default QuestionQuickView
