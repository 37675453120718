import React, { useState } from "react"
import { ESubscriptionContactUsStatus } from "../../types/generalInterfaces"
import { DYNAMO_COLOR } from "../../constants/constants"

interface Props {
  status: ESubscriptionContactUsStatus
  onClick: Function
  onError: Function
}

const ContactUs = ({ status, onClick, onError }: Props) => {
  const [email, setEmail] = useState("")
  const [name, setName] = useState("")
  const [message, setMessage] = useState("")

  const submitFormHandler = (event: any) => {
    event.preventDefault()
    onClick(name, email, message)
  }

  const renderContactUs = () => {
    return (
      <>
        <div style={{ fontSize: 20, color: "#7a7a7a" }}>
          ראינו שיש לך כבר חיבור עם המנטור שביקשת.
        </div>
        <div style={{ fontSize: 20, color: "#7a7a7a", marginBottom: 17 }}>
          אם רוצים עכשיו זה זמן מצוין לכתוב לנו בקשה ושאלה. הנה כאן למטה
        </div>
        <form className="row" onSubmit={submitFormHandler}>
          <div style={styles.inputWrapper}>
            <div className="row" style={{ paddingRight: 10, paddingLeft: 10 }}>
              <div
                className="input-field col s10"
                style={{ marginTop: 0, marginBottom: 0 }}
              >
                <input
                  value={name}
                  type="text"
                  id="name"
                  required
                  minLength={2}
                  className="validate"
                  onChange={(e) => setName(e.target.value)}
                  style={{
                    color: "#7a7a7a",
                    borderBottom: 0,
                    backgroundColor: "#fff",
                    borderRadius: "8px",
                    paddingLeft: "5%",
                    paddingRight: "5%",
                    width: "90%",
                    margin: 0,
                  }}
                />
                <span className="helper-text" data-error="2 תוים לפחות" />
              </div>
              <div
                className="col s2 left-align"
                style={{
                  ...styles.contentText,
                  padding: 0,
                  lineHeight: "15px",
                }}
              >
                שם
              </div>
            </div>

            <div className="row" style={{ paddingRight: 10, paddingLeft: 10 }}>
              <div
                className="input-field col s10"
                style={{ marginTop: 0, marginBottom: 0 }}
              >
                <input
                  value={email}
                  type="email"
                  id="email"
                  required
                  className="validate"
                  onChange={(e) => setEmail(e.target.value)}
                  style={{
                    color: "#7a7a7a",
                    borderBottom: 0,
                    backgroundColor: "#fff",
                    borderRadius: "8px",
                    paddingLeft: "5%",
                    paddingRight: "5%",
                    width: "90%",
                    margin: 0,
                  }}
                />
                <span className="helper-text" data-error="אימייל לא תקין" />
              </div>
              <div
                className="col s2 left-align"
                style={{
                  ...styles.contentText,
                  padding: 0,
                  lineHeight: "15px",
                }}
              >
                אימייל
              </div>
            </div>
            <div
              className="input-field"
              style={{
                marginTop: 0,
                marginBottom: 0,
                width: "90%",
                margin: "0 auto",
              }}
            >
              <textarea
                className="materialize-textarea"
                value={message}
                id="message"
                required
                onChange={(e) => setMessage(e.target.value)}
                placeholder="לכתוב את ההודעה כאן"
                style={{
                  color: "#7a7a7a",
                  borderBottom: 0,
                  backgroundColor: "#fff",
                  borderRadius: "8px",
                  paddingLeft: "5%",
                  paddingRight: "5%",
                  width: "100%",
                  margin: 0,
                  minHeight: "220px",
                }}
              />
            </div>
          </div>
          <button className="waves-effect" type="submit" style={styles.button}>
            לשלוח לנו את ההודעה כאן
          </button>
        </form>
      </>
    )
  }

  const renderContent = () => {
    switch (status) {
      case ESubscriptionContactUsStatus.FAILED: {
        return (
          <>
            <div style={{ fontSize: 24, color: "#7a7a7a", marginTop: "20%" }}>
              מוזר אבל יש תקלה...
            </div>
            <button
              className="waves-effect"
              style={styles.button}
              onClick={() => onError()}
            >
              נא לנסות שוב
            </button>
          </>
        )
      }
      case ESubscriptionContactUsStatus.NONE:
        return renderContactUs()
      case ESubscriptionContactUsStatus.SENT:
        return (
          <>
            <div style={{ fontSize: 24, color: "#7a7a7a" }}>מעולה ששלחת.</div>
            <div style={{ fontSize: 24, color: "#7a7a7a" }}>
              אנחנו בודקים וחוזרים אלייך במייל.
            </div>
          </>
        )
      case ESubscriptionContactUsStatus.SENDING: {
        return (
          <div className="progress" style={{ backgroundColor: "#f8684b66" }}>
            <div
              className="indeterminate"
              style={{ backgroundColor: "#f8684b" }}
            />
          </div>
        )
      }

      default:
        return null
    }
  }

  return <div className="container s12 rtl">{renderContent()}</div>
}

const styles = {
  contentText: {
    fontSize: 15,
    color: "#7a7a7a",
    marginBottom: 15,
    marginTop: 15,
  },
  inputWrapper: {
    borderRadius: "8px",
    backgroundColor: "#f1f1f1",
    width: "90%",
    paddingTop: 20,
    paddingBottom: 20,
    margin: "0 auto",
  },
  button: {
    width: "90%",
    backgroundColor: DYNAMO_COLOR,
    borderWidth: 0,
    marginTop: 17,
    height: 48,
    fontSize: 15,
    color: "#ffffff",
    borderRadius: "8px",
  },
}

export default ContactUs
