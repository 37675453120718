import React, { useState } from "react"
import { DYNAMO_COLOR } from "../../constants/constants"
import { ImageWithFallback } from "../common"

interface Props {
  onSubmit: Function
  supervisorName: string
  supervisorPhotoUrl?: string
}

const Details = ({ onSubmit, supervisorName, supervisorPhotoUrl }: Props) => {
  const [email, setEmail] = useState("")
  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [emailValid, setEmailValid] = useState(true)

  const submitFormHandler = (event: any) => {
    event.preventDefault()
    if (!emailValid) return
    onSubmit(firstName, lastName, email)
  }

  const checkEmailValifation = (emailValue: string) => {
    const validEmailReg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/gi

    if (!emailValue || !emailValue.length || validEmailReg.test(emailValue)) {
      return setEmailValid(true)
    }

    return setEmailValid(false)
  }

  return (
    <div className="row s12 rtl">
      <div style={{ ...styles.title, fontSize: 40 }}>ברוכים הבאים לדינאמו!</div>
      <div style={styles.contentText}>הוזמנת לדינאמו על ידי:</div>
      <div
        style={{
          position: "relative",
          top: 0,
          height: 120,
          width: "100%",
        }}
      >
        <ImageWithFallback
          imageUrl={supervisorPhotoUrl}
          fallbackImageUrl={require("../../assets/images/default_profile_image.png")}
          customStyle={{
            border: "2px solid #646464",
          }}
        />
      </div>
      <div style={{ height: 20 }}>
        <div style={{ ...styles.title, fontFamily: "Avenir-Black" }}>
          {supervisorName}
        </div>
      </div>
      <form className="row" onSubmit={submitFormHandler}>
        <div style={{ ...styles.contentText, marginTop: 25 }}>
          נא להקליד את פרטייך כאן:
        </div>
        <div className="row" style={styles.inputWrapper}>
          <div style={{ marginTop: 13 }}>
            <div className="row" style={{ paddingRight: 10, paddingLeft: 10 }}>
              <div
                className="input-field col s9"
                style={{ marginTop: 0, marginBottom: 0 }}
              >
                <input
                  value={firstName}
                  type="text"
                  id="firstName"
                  required
                  minLength={2}
                  className="validate"
                  onChange={(e) => setFirstName(e.target.value)}
                  style={{
                    borderBottom: 0,
                    backgroundColor: "#fff",
                    borderRadius: "8px",
                    paddingLeft: "5%",
                    paddingRight: "5%",
                    width: "90%",
                    margin: 0,
                  }}
                />
                <span className="helper-text" data-error="2 תוים לפחות" />
              </div>
              <div
                className="col s3 left-align"
                style={{
                  ...styles.contentText,
                  padding: 0,
                  lineHeight: "15px",
                }}
              >
                שם פרטי
              </div>
            </div>

            <div className="row" style={{ paddingRight: 10, paddingLeft: 10 }}>
              <div
                className="input-field col s9"
                style={{ marginTop: 0, marginBottom: 0 }}
              >
                <input
                  value={lastName}
                  type="text"
                  id="lastName"
                  required
                  minLength={2}
                  className="validate"
                  onChange={(e) => setLastName(e.target.value)}
                  style={{
                    borderBottom: 0,
                    backgroundColor: "#fff",
                    borderRadius: "8px",
                    paddingLeft: "5%",
                    paddingRight: "5%",
                    width: "90%",
                    margin: 0,
                  }}
                />
                <span className="helper-text" data-error="2 תוים לפחות" />
              </div>
              <div
                className="col s3 left-align"
                style={{
                  ...styles.contentText,
                  padding: 0,
                  lineHeight: "15px",
                }}
              >
                שם משפחה
              </div>
            </div>

            <div className="row" style={{ paddingRight: 10, paddingLeft: 10 }}>
              <div
                className="input-field col s9"
                style={{ marginTop: 0, marginBottom: 0 }}
              >
                <input
                  value={email}
                  type="text"
                  id="email"
                  required
                  className={
                    email.length === 0 ? "" : emailValid ? "valid" : "invalid"
                  }
                  onBlur={(e) => checkEmailValifation(e.target.value)}
                  onFocus={(e) => checkEmailValifation(e.target.value)}
                  onChange={(e) => {
                    checkEmailValifation(e.target.value)
                    setEmail(e.target.value)
                  }}
                  style={{
                    borderBottom: 0,
                    backgroundColor: "#fff",
                    borderRadius: "8px",
                    paddingLeft: "5%",
                    paddingRight: "5%",
                    width: "90%",
                    margin: 0,
                  }}
                />
                <span className="helper-text" data-error="אימייל לא תקין" />
              </div>
              <div
                className="col s3 left-align"
                style={{
                  ...styles.contentText,
                  padding: 0,
                  lineHeight: "15px",
                }}
              >
                אימייל לשליחת חשבונית
              </div>
            </div>
          </div>
        </div>
        <button
          className="waves-effect"
          type="submit"
          style={styles.button}
          onClick={() => {}}
        >
          המשך
        </button>
      </form>
    </div>
  )
}

const styles = {
  title: {
    fontSize: 24,
    color: "#7a7a7a",
    marginBottom: 15,
  },
  contentText: {
    fontSize: 15,
    color: "#7a7a7a",
    marginBottom: 15,
    marginTop: 15,
  },
  inputWrapper: {
    borderRadius: "8px",
    backgroundColor: "#f1f1f1",
    width: "90%",
    paddingTop: 20,
    paddingBottom: 20,
  },
  button: {
    width: "90%",
    backgroundColor: DYNAMO_COLOR,
    borderWidth: 0,
    height: 48,
    fontSize: 15,
    color: "#ffffff",
    borderRadius: "8px",
  },
}

export default Details
