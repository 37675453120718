import React, { useState, useEffect } from "react"
import { DYNAMO_COLOR } from "../../../constants/constants";
import { webLayoutTypes } from "../../../types/generalInterfaces";
import * as _ from 'lodash'

const ql1 = require("../../../assets/images/questionLayouts/q_type_1.png")
const ql2 = require("../../../assets/images/questionLayouts/q_type_2.png")
const ql3 = require("../../../assets/images/questionLayouts/q_type_3.png")
const ql4 = require("../../../assets/images/questionLayouts/q_type_4.png")
const ql5 = require("../../../assets/images/questionLayouts/q_type_5.png")
const ql6 = require("../../../assets/images/questionLayouts/q_type_6.png")





interface IProps {
  id:string;
  submitActions:Function
}





const SelectQuestionLayoutPopup = ({id, submitActions  }: IProps) => {

  const [selectedLayoutId, setSelectedLayoutId] = useState<string>(webLayoutTypes.Q_TEXT_A_TEXT_3.id);
  const orderedWebLayoutTypes =  Object.entries(webLayoutTypes).map(([key,value]) => {
      return value
  })
  
  return (
    <div
      id={id}
      className="modal modal-fixed-footer"
    >
      <div className="modal-content row">
        <div className="row">
        <h3>Choose Question type</h3>
        <div className="col s3">
          image/text/number
        </div>
        <div className="col s8">
          {
            orderedWebLayoutTypes.map(webLayout => {
              return (
                <div className="col s4" key={webLayout.id}
                  style={{ width: "168px",backgroundColor: webLayout.id === selectedLayoutId ? "orange":"white" }}
                  onClick={(e)=> setSelectedLayoutId(webLayout.id)}
                >
                  <img className="responsive-img center"  alt="" src={require(`../../../assets/images/questionLayouts/${webLayout.imageLayoutName}`)} />
                </div>
              )
            })
          }
        </div>

        </div>
        
      <div className="btn col s2 offset-s5"  
            style={{ borderRadius: "8px", backgroundColor: DYNAMO_COLOR }}
            onClick={() => submitActions(selectedLayoutId)}
            >
            Select
          </div>
      </div>
      
    </div>
  )
}



export default (SelectQuestionLayoutPopup)
