import React from "react"
import { connect } from "react-redux"
import { ThunkDispatch } from "redux-thunk"
import { bindActionCreators } from "redux"
import { RootState } from "../../store/reducers/rootReducer"
import { AppActions } from "../../types/actionTypes/actions"
import { EPopupTypes } from "../../types/Popups"
import {
  signInWithGoogle,
  signInWithFacebook,
  login,
} from "../../store/actions/authActions"
import ManageSupervisorsPopup from "./popups/ManageSupervisorsPopup"
import SelectSupervisedPopup from "./popups/SelectSupervisedPopup"
import { hidePopupById } from "../../store/actions/popupActions"

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>

const Popups = ({
  signInWithGoogle,
  signInWithFacebook,
  login,
  hidePopupById,
}: Props) => {
  return (
    <>
      <div id={EPopupTypes.LOGIN_POPUP} className="modal">
        <div className="modal-content">
          <h4
            className="black-text"
            style={{ textAlign: "center", marginBottom: "40px" }}
          >
            Login
          </h4>
          <div className="row">
            <button
              className="waves-effect waves-light btn col s6 push-s3"
              style={{ backgroundColor: "#4285F4", margin: "auto" }}
              onClick={() => signInWithGoogle()}
            >
              Login with google
            </button>
          </div>

          <div className="row">
            <button
              className="waves-effect waves-light btn col s6 push-s3"
              style={{ backgroundColor: "#3b5998" }}
              onClick={() => signInWithFacebook()}
            >
              Login with Facebook
            </button>
          </div>
        </div>
      </div>

      <div id={EPopupTypes.LOGOUT_POPUP} className="modal">
        <div className="modal-content">
          <h4 className="black-text">Logged out</h4>
          <p className="black-text">Come back soon</p>
        </div>
        <div className="modal-footer">
          <a
            href="#!"
            className="modal-close waves-effect waves-green btn-flat"
          >
            Agree
          </a>
        </div>
      </div>

      <div
        id={EPopupTypes.LOADING_POPUP}
        className="modal"
        style={{ boxShadow: "none", backgroundColor: "rgba(0, 0, 0, 0)" }}
      >
        <div className="row center-align center-justify">
          <div className="preloader-wrapper large active">
            <div className="spinner-layer spinner-blue-only">
              <div className="circle-clipper left">
                <div className="circle" />
              </div>
              <div className="gap-patch">
                <div className="circle" />
              </div>
              <div className="circle-clipper right">
                <div className="circle" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id={EPopupTypes.GENERAL_ERROR_POPUP} className="modal">
        <div className="modal-content">
          <h3
            className="black-text"
            style={{ textAlign: "center", marginBottom: "40px" }}
          >
            Oops
          </h3>
          <h5
            className="black-text"
            style={{ textAlign: "center", marginBottom: "40px" }}
          >
            Something went wrong, please try again
          </h5>
        </div>
      </div>

      <div id={EPopupTypes.ACCOUNT_EXISTS_POPUP} className="modal">
        <div className="modal-content">
          <h5
            className="black-text"
            style={{ textAlign: "center", marginBottom: "40px" }}
          >
            An account already exists with the same email address but different
            sign-in credentials. Sign in using a provider associated with this
            email address.
          </h5>
        </div>
      </div>

      <ManageSupervisorsPopup
        onClick={(
          userEmail: string,
          userFirstName: string,
          userLastName: string
        ) => {
          login(userEmail, userFirstName, userLastName)
          hidePopupById(EPopupTypes.MANAGE_SUPERVISORS_POPUP)
        }}
      />
    </>
  )
}

const mapStateToProps = (state: RootState) => {
  return {
    isLoggedIn: state.auth.isLoggedIn,
  }
}

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AppActions>) => {
  return bindActionCreators(
    {
      signInWithGoogle,
      signInWithFacebook,
      login,
      hidePopupById,
    },
    dispatch
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(Popups)
