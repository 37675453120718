import React, { useEffect } from "react"
// @ts-ignore
import { SpriteAnimator } from "react-sprite-animator"
import { connect } from "react-redux"
import { Dispatch, bindActionCreators } from "redux"
import { RootState } from "../../../../store/reducers/rootReducer"
import { getImages, uploadImage } from "../../../../store/actions/assetsActions"
import { validateAndChnageFilenameForStorage } from "../../../../utils/utils"

interface Props {
  updateImageSelected: Function
  contentId: string
}

type IProps = ReturnType<typeof mapDispatchToProps> &
  ReturnType<typeof mapStateToProps> &
  Props

const ImagesSideBar = ({
  updateImageSelected,
  getImages,
  images,
  contentId,
  uploadImage,
  userId,
}: IProps) => {
  useEffect(() => {
    getImages()
  }, [])

  const onClickHandler = (imageName: string) => {
    updateImageSelected(imageName)
    // close the sideBar
    const elem = document.getElementById(`images-slide-out-${contentId}`)
    const instance = M.Sidenav.getInstance(elem as Element)
    instance.close()
  }

  const handleImageAsFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    const image = e.target.files && e.target.files[0]
    if (image) {
      // remove any dots or slashs from the file name can not contain ".", "#", "$", "[", or "]"
      const myNewFile = new File(
        [image],
        validateAndChnageFilenameForStorage(image.name, userId),
        { type: image.type }
      )
      uploadImage(myNewFile)
      onClickHandler(myNewFile.name)
    }
  }

  const imageInput = (
    <div className="row">
      <div className="input-field file-field col s10">
        <div className="btn col s2">
          <span>File</span>
        </div>
        <div className="col s10">
          <input type="file" onChange={handleImageAsFile} />
          <div className="file-path-wrapper">
            <input className="file-path validate" type="text" />
          </div>
        </div>
      </div>
      {/* <div className="col s2">
      <img className="materialboxed" width="100%" src={imageChosenURL || ""} />
    </div>
    <div className={`progress ${imageUploadProgress === 0 || imageUploadProgress === 100 ? "hide" : "show"}`}>
      <div className="determinate" style={{ width: `${imageUploadProgress}%` }}></div>
    </div> */}
    </div>
  )

  return (
    <div id={`images-slide-out-${contentId}`} className="sidenav">
      {imageInput}
      <div className="col s12">
        {Object.keys(images).map((key) => {
          return (
            <div
              className="col s3"
              key={key}
              onClick={() => onClickHandler(key)}
            >
              <img
                className="img"
                width="100%"
                src={images[key].downloadURL!}
              />
            </div>
          )
        })}
      </div>
    </div>
  )
}

const mapStateToProps = (state: RootState) => {
  return {
    images: state.assetManager.images,
    userId: state.user.id,
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      getImages,
      uploadImage,
    },
    dispatch
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(ImagesSideBar)
