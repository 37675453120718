import {
  EAssetsActionTypes,
  AssetsActionTypes,
} from "../../types/actionTypes/assetsActionTypes"
import {
  EAuthActionTypes,
  AuthActionTypes,
} from "../../types/actionTypes/AuthActionsTypes"

export enum EImageStatus {
  READY = "READY",
  DOWNLOADING = "DOWNLOADING",
  UPLOADING = "UPLOADING",
  ERROR = "ERROR",
}

export interface imageStats {
  progress: number
  downloadURL?: string
  status: EImageStatus
}

export interface AssetsReducerDefaultStateInterface {
  sprites: { [key: string]: imageStats }
  images: { [key: string]: imageStats }
}

const assetsReducerDefaultState: AssetsReducerDefaultStateInterface = {
  images: {},
  sprites: {},
}

const assetsReducer = (
  state = assetsReducerDefaultState,
  action: AssetsActionTypes | AuthActionTypes
): AssetsReducerDefaultStateInterface => {
  let obj
  switch (action.type) {
    case EAssetsActionTypes.UPLOADING: {
      const { progress, imageName } = action
      obj = { [imageName]: { progress, status: EImageStatus.UPLOADING } }
      return { ...state, images: { ...state.images, ...obj } }
    }
    case EAssetsActionTypes.UPLOAD_FAILED: {
      const { imageName } = action
      obj = { [imageName]: { progress: 0, status: EImageStatus.ERROR } }
      return { ...state, images: { ...state.images, ...obj } }
    }
    case EAssetsActionTypes.UPLOAD_SUCCESS: {
      const { imageName, downloadURL } = action
      obj = {
        [imageName]: { progress: 0, status: EImageStatus.READY, downloadURL },
      }
      return { ...state, images: { ...state.images, ...obj } }
    }
    case EAssetsActionTypes.DOWNLOADING: {
      const { imageName } = action
      obj = { [imageName]: { progress: 0, status: EImageStatus.DOWNLOADING } }
      return { ...state, images: { ...state.images, ...obj } }
    }
    case EAssetsActionTypes.GET_IMAGE_URL_SUCCESS:
      {
        const { imageName, downloadURL } = action
        obj = {
          [imageName]: { progress: 0, downloadURL, status: EImageStatus.READY },
        }
      }
      return { ...state, images: { ...state.images, ...obj } }
    case EAssetsActionTypes.GET_SPRITE_SUCCESS:
      {
        const { spritesData } = action
        obj = {} as { [key: string]: imageStats }
        for (const [key, value] of Object.entries(spritesData)) {
          obj[value.fileName] = {
            downloadURL: value.link,
            status: EImageStatus.READY,
            progress: 0,
          }
        }
      }
      const t: { [key: string]: imageStats } = { ...obj }
      return { ...state, sprites: { ...t } }

    case EAssetsActionTypes.GET_IMAGES_SUCCESS:
      {
        const { imagesData } = action
        obj = {} as { [key: string]: imageStats }
        for (const [key, value] of Object.entries(imagesData)) {
          obj[value.fileName] = {
            downloadURL: value.link,
            status: EImageStatus.READY,
            progress: 0,
          }
        }
      }
      const t1: { [key: string]: imageStats } = { ...obj }
      return { ...state, images: { ...state.images, ...t1 } }

    default:
      return state
  }
}

export { assetsReducer }
