import React from 'react'
import { Question } from '../../../../../types/Question';
import AnswerInputView from '../../AnswerInputView';
import * as _ from 'lodash'
import ContentInput from '../ContentInput';
import { ContentInputType, ECharacterCount, contentTranslationType } from '../../../../../types/generalInterfaces';
interface Iprops {
  question: Question
  addAnotherAnswer: Function,
  updateNotificationQuestion:Function,
  updateTopLevelQuestion:Function,
  updateAnswer:Function,
  deleteAnswer:Function
}

const maxAmountOfAnswers = 4


const Q_TEXT_A_TEXT_3 = ({ question, deleteAnswer, addAnotherAnswer,updateNotificationQuestion,updateAnswer,updateTopLevelQuestion }: Iprops) => {

  
  return (
    <div className="col s12">
      
      <ContentInput id="[text]" showTranslationSelector={false} inputType={ContentInputType.TEXT} characterCount={ECharacterCount.Q_TWO_LINES_CHAR_COUNT} content={question.contentData} textLabel="Question text" updateValue={(type:ContentInputType, val:string,translationType:contentTranslationType) => {updateTopLevelQuestion(type,val,translationType);updateNotificationQuestion(type,val,translationType)}}/>
      
      {question.contentData.answers.map((answer, index) => {
        return (
          <AnswerInputView
            key={index}
            inputType={ContentInputType.TEXT}
            placeHolder="Answer Text"
            content={answer}
            index={index}
            updateAnswer={({ type,val, correct,translationType }: { type:ContentInputType, val: string, correct: boolean,translationType:contentTranslationType }) => updateAnswer({ type,val, correct, index,translationType })}
            deleteAnswer={() => deleteAnswer( index )}
            addAnotherAnswer={addAnotherAnswer}
            maxAmountOfAnswers={maxAmountOfAnswers}
            maxCharacters={ECharacterCount.ANSWER_CHAR_COUNT}
          />
        )
      })}

    </div>
  )
}

export default Q_TEXT_A_TEXT_3

