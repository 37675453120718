import { combineReducers } from "redux"
import authReducer from "./authReducer"
import { userReducer } from "./userReducer"
import { childReducer } from "./childReducer"
import { bundleReducer } from "./bundleReducer"
import { kingdomsReducer } from "./kingdomsReducer"
import { assetsReducer } from "./assetsReducer"
import { loadersReducer } from "./loadersReducer"
import { paymentReducer } from "./paymentReducer"

export const rootReducer = combineReducers({
  auth: authReducer,
  user: userReducer,
  kingdoms: kingdomsReducer,
  assetManager: assetsReducer,
  child: childReducer,
  loaders: loadersReducer,
  bundle: bundleReducer,
  payment: paymentReducer,
})

export type RootState = ReturnType<typeof rootReducer>
