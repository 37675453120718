import { hot } from "react-hot-loader/root"
import React, { useEffect } from "react"
import { Router, Switch, Route, Redirect } from "react-router-dom"
import { connect } from "react-redux"
import { ThunkDispatch } from "redux-thunk"
import { bindActionCreators } from "redux"
import { createBrowserHistory } from "history"
import firebase from "./configs/fbConfig"
import { RootState } from "./store/reducers/rootReducer"
import { AppActions } from "./types/actionTypes/actions"
import { getLoggedInUser, setUUID } from "./store/actions/authActions"
import {
  getKingdoms,
  getPublicPersonalizedBundles,
} from "./store/actions/kingdomActions"
import {
  getSupervisedListMetadata,
  getPersonalizedBundles,
} from "./store/actions/userActions"
import NavBar from "./components/layout/NavBar"
import Login from "./components/LoginComponent"
import UserProfile from "./components/userProfile/UserProfile"
import BundleView from "./components/bundles/BundleView/BundleView"
import ChildView from "./components/child/ChildView"
import SingleStudentView from "./components/child/Students/SingleStudentView"
import StudentsView from "./components/child/StudentsView"
import CreateChild from "./components/child/CreateChild/CreateChild"
import SideBar from "./components/layout/SideBar"
import Popups from "./components/layout/Popups"
import { initAmplitude } from "./services/analyticsService"
import Paywall from "./components/payments/Paywall"
import BundlesView from "./components/bundles/BundlesView"
import config from "./configs/config"
import { EPaymentStatus } from "./types/generalInterfaces"
import SupervisorsView from "./components/Supervisor/SupervisorsView"
import ContentCreatorView from "./components/ContentCreator/ContentCreatorView"
import CreateBundleView from "./components/ContentCreator/CreateBundleView"
import { hideSideBar } from "./utils/utils"

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>

export const history = createBrowserHistory()

const hideNavBar = (pathName: string) => {
  if (
    pathName === "/payments/subscription/success" ||
    pathName === "/payments/subscription/failure"
  ) {
    return true
  }
  return false
}

const getNavbarLogo = (pathName: string) => {
  if (pathName.indexOf("payments/subscription") === -1) {
    return require("./assets/images/logo_dash.png")
  }
  return require("./assets/images/logo_nav.png")
}

const App = ({
  isLoggedIn,
  uuid,
  id,
  setUUID,
  getLoggedInUser,
  getSupervisedListMetadata,
  getPersonalizedBundles,
  getPublicPersonalizedBundles,
  getKingdoms,
}: Props) => {
  useEffect(() => {
    if (isLoggedIn && id) {
      // get initial data (childrenMetadata, kingdoms, personalizedPackages)
      getSupervisedListMetadata()
      getPersonalizedBundles()
      getPublicPersonalizedBundles()
      getKingdoms()
    }
  }, [isLoggedIn, id])

  useEffect(() => {
    !uuid && setUUID(uuid)
  }, [uuid])

  useEffect(() => {
    // initialize materializecss elements
    M.AutoInit()
    console.log("app init")
    initAmplitude()
    // get logged in user
    firebase.auth().onAuthStateChanged((user) => {
      getLoggedInUser(user)
    })
  }, [])

  return (
    <Router history={history}>
      <NavBar
        logo={getNavbarLogo(history?.location?.pathname)}
        hidden={hideNavBar(history?.location?.pathname)}
      />
      <Switch>
        <Route exact path="/payments/subscription" component={Paywall} />
        <Route
          exact
          path="/payments/subscription/success"
          render={() => {
            window.parent.postMessage(
              EPaymentStatus.success,
              config.env.baseUrl
            )
            return null
          }}
        />
        <Route
          exact
          path="/payments/subscription/failure"
          render={() => {
            window.parent.postMessage(
              EPaymentStatus.failure,
              config.env.baseUrl
            )
            return null
          }}
        />
      </Switch>
      <div className={`${isLoggedIn ? "App" : ""}`}>
        <SideBar
          hidden={!isLoggedIn || hideSideBar(history?.location?.pathname)}
        />
        <Switch>
          <Route
            exact
            path="/"
            render={() =>
              isLoggedIn ? (
                <Redirect to="/students" />
              ) : (
                <Redirect to="/login" />
              )
            }
          />
          <Route exact path="/login" component={Login} />

          <PrivateRoute path="/userProfile" isLoggedIn={isLoggedIn}>
            <UserProfile />
          </PrivateRoute>
          <PrivateRoute path="/createChild" isLoggedIn={isLoggedIn}>
            <CreateChild />
          </PrivateRoute>
          <PrivateRoute path="/bundle" isLoggedIn={isLoggedIn}>
            <BundleView />
          </PrivateRoute>
          <PrivateRoute path="/child" isLoggedIn={isLoggedIn}>
            <SingleStudentView />
          </PrivateRoute>
          <PrivateRoute path="/students" isLoggedIn={isLoggedIn}>
            <StudentsView />
          </PrivateRoute>
          <PrivateRoute path="/bundles" isLoggedIn={isLoggedIn}>
            <BundlesView />
          </PrivateRoute>
          <PrivateRoute path="/supervisors" isLoggedIn={isLoggedIn}>
            <SupervisorsView />
          </PrivateRoute>
          <PrivateRoute path="/contentCreator" isLoggedIn={isLoggedIn}>
            <ContentCreatorView />
          </PrivateRoute>
          <PrivateRoute path="/createBundle" isLoggedIn={isLoggedIn}>
            <CreateBundleView />
          </PrivateRoute>
        </Switch>
        <Popups />
      </div>
    </Router>
  )
}

type PrivateRouteProps = {
  children: object
  path: string
  isLoggedIn: boolean
}

const PrivateRoute = ({ children, isLoggedIn }: PrivateRouteProps) => {
  return (
    <Route
      render={({ location }) =>
        isLoggedIn ? (
          children
        ) : (
          <Redirect to={{ pathname: "/login", state: { from: location } }} />
        )
      }
    />
  )
}

const mapStateToProps = (state: RootState) => {
  const {
    auth: { isLoggedIn, uuid },
    user: { id },
  } = state
  return {
    isLoggedIn,
    uuid,
    id,
  }
}

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AppActions>) => {
  return bindActionCreators(
    {
      getLoggedInUser,
      setUUID,
      getSupervisedListMetadata,
      getPersonalizedBundles,
      getPublicPersonalizedBundles,
      getKingdoms,
    },
    dispatch
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(hot(App))
