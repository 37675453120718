import amplitude from "amplitude-js"
import AsyncLock from "async-lock"
import config from "../configs/config"
import storeFactory from "../store/store"

const store = storeFactory()
const lock = new AsyncLock()

export const initAmplitude = () => {
  const { amplitudeAPIKey } = config.amplitude
  if (amplitudeAPIKey.length > 0) {
    console.log("Amplitude init")
    amplitude.getInstance().init(amplitudeAPIKey)
  } else {
    console.log(`No Amplitude API key for env ${process.env.REACT_APP_ENV}`)
  }
}

export const registerEvent = (name: string, data = {}) => {
  try {
    lock.acquire("analyticsEvent", async (done) => {
      amplitude.logEvent(name, data)
      // AppEventsLogger.logEvent(name, data);
      console.log(`Event sent: ${name}`, data)
      done()
    })
  } catch (error) {
    console.log("Amplitude register event error", error)
  }
}

export const identifyUser = () =>
  new Promise((resolve) => {
    try {
      const traits: {
        type?: string
        email?: string
        firstName?: string
        lastName?: string
      } = {}
      const {
        user,
        auth: { uuid },
      } = store.getState()
      const { id, firstName, lastName, email } = user
      if (user) {
        traits.type = "SUPERVISOR"
        traits.email = email
        traits.firstName = firstName
        traits.lastName = lastName
      }

      id && amplitude.setUserId(id)
      amplitude.setUserProperties({
        ...traits,
      })
      resolve()
    } catch (error) {
      resolve()
    }
  })
