import React from "react"
import { activeStatus } from "../../../../types/generalInterfaces"

interface IProps {
  nickname: string
  photoUrl: string
  status?: activeStatus | undefined
  showStatus?: boolean
  showBundles?: boolean
}

const SQVPhotoName = ({
  nickname,
  photoUrl,
  status,
  showStatus,
  showBundles,
}: IProps) => {
  const dotColor =
    status === activeStatus.ACTIVE
      ? "green"
      : status === activeStatus.INACTIVE
      ? "red"
      : "orange"

  return (
    <div className="" style={{ flex: 1, position: "relative" }}>
      {showStatus && (
        <div style={{ position: "absolute", left: 15 }}>
          <svg height="15" width="15" style={{ overflow: "visible" }}>
            <circle r="6" fill={dotColor} />
          </svg>
        </div>
      )}
      <div
        className="center"
        style={{
          width: "90px",
          paddingTop: "11px",
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        <img
          className="responsive-img"
          style={{ maxHeight: "80px" }}
          alt=""
          src={photoUrl}
        />
      </div>
      <div
        className={`center smallText ${!showBundles && "hide-on-med-and-up"}`}
        style={{ height: "1.1rem", lineHeight: "1.1rem", overflow: "hidden" }}
      >
        {nickname}
      </div>
    </div>
  )
}

SQVPhotoName.defaultProps = {
  showStatus: true,
  showBundles: false,
}

export default SQVPhotoName
