import React from "react"
import { isRtl } from "../../locales/localesService"

interface Props {
  title: string
  children: React.ReactNode
}

const CreateBundleInputSection = ({ title, children }: Props) => {
  return (
    <div
      className="bundleDetailSection"
      style={{ flexDirection: isRtl() ? "row-reverse" : "row" }}
    >
      <div
        className="sideSection"
        style={{ justifyContent: isRtl() ? "flex-start" : "flex-end" }}
      >
        <div
          className="title"
          style={{ [isRtl() ? "marginLeft" : "marginRight"]: 14 }}
        >
          {title}
        </div>
      </div>
      <div className="middleSection">{children}</div>
      <div className="sideSection hide-on-med-and-down" />
    </div>
  )
}

export default CreateBundleInputSection
