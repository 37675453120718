import { Dispatch } from "redux"
import { AppActions } from "../../types/actionTypes/actions"
import { unAuthedRequestWrapper } from "../../services/ServerConnection"
import { EPaymentActionTypes } from "../../types/actionTypes/paymentActionTypes"
import {
  SupervisorSubscriptionInfo,
  EMonetizationStatus,
} from "../../types/Payments"

export const getSupervisorSubscriptionInfo = (data: string): any => async (
  dispatch: Dispatch<AppActions>
) => {
  try {
    dispatch({
      type: EPaymentActionTypes.GET_SUPERVISOR_SUBSCRIPTION_INFO_START,
    })
    const res = await unAuthedRequestWrapper("getSupervisorSubscriptionInfo", {
      data,
    })
    const supervisorSubscriptionInfo = res.data as SupervisorSubscriptionInfo

    if (res.status === 204) {
      dispatch({
        type: EPaymentActionTypes.SET_MENTOR_NOT_EXIST,
      })
      return
    }

    if (
      supervisorSubscriptionInfo.monetizationStatus ===
      EMonetizationStatus.subscribed
    ) {
      dispatch({
        type: EPaymentActionTypes.SHOW_SUBSCRIPTION_CONTACT_US,
      })
    }
    dispatch({
      type: EPaymentActionTypes.GET_SUPERVISOR_SUBSCRIPTION_INFO_SUCCESS,
      supervisorSubscriptionInfo,
    })
  } catch (error) {
    dispatch({
      type: EPaymentActionTypes.GET_SUPERVISOR_SUBSCRIPTION_INFO_FAILED,
    })
    console.log(error)
  }
}

export const submitSubscriptionContactUs = ({
  data,
  name,
  email,
  text,
}: {
  data: string
  name: string
  email: string
  text: string
}): any => async (dispatch: Dispatch<AppActions>) => {
  try {
    dispatch({
      type: EPaymentActionTypes.SUBMIT_SUBSCRIPTION_CONTACT_US_START,
    })
    await unAuthedRequestWrapper("submitSubscriptionContactUs", {
      data,
      text,
      email,
      name,
    })

    setTimeout(() => {
      dispatch({
        type: EPaymentActionTypes.SUBMIT_SUBSCRIPTION_CONTACT_US_SUCCESS,
      })
    }, 5000)
  } catch (error) {
    dispatch({
      type: EPaymentActionTypes.SUBMIT_SUBSCRIPTION_CONTACT_US_FAILED,
    })
    console.log(error)
  }
}

export const resetSubscriptionContactUs = (): any => async (
  dispatch: Dispatch<AppActions>
) => {
  dispatch({
    type: EPaymentActionTypes.RESET_SUBMIT_SUBSCRIPTION_CONTACT_US,
  })
}
