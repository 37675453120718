import React, { useState, useEffect } from "react"
import { Bundle } from "../../../types/Bundle"
import { packageStatusType } from "../../../types/generalInterfaces"
import SQVPhotoName from "../Students/StudentQuickView/SQVPhotoName"
import { DynaDropdown } from "../../common"

interface Iprops {
  bundle: Bundle
  initState: packageStatusType
  addRemoveBundle: Function
}

const BundleRowView = ({ bundle, initState, addRemoveBundle }: Iprops) => {
  const [isChecked, setIsChecked] = useState<boolean>(false)
  const [practicedLanguage, setPracticedLanguage] = useState<string>("")
  const [practicedLanguagesOptions, setPracticedLanguagesOptions] = useState<
    Array<{ value: string; text: string }>
  >([])

  useEffect(() => {
    setIsChecked(initState === packageStatusType.ENABLED)
  }, [initState])

  useEffect(() => {
    if (!bundle.supportedLocales || bundle.supportedLocales.length === 0) {
      return
    }

    const practicedLanguagesOptions = bundle.supportedLocales.map((locale) => {
      return { value: locale, text: locale.toUpperCase() }
    })

    const enLocaleIndex = practicedLanguagesOptions.findIndex(
      (locale) => locale.value === "en"
    )

    if (enLocaleIndex > -1) {
      const enLocale = practicedLanguagesOptions.splice(enLocaleIndex, 1)[0]
      if (enLocale) {
        practicedLanguagesOptions.unshift(enLocale)
      }
    }

    setPracticedLanguage(practicedLanguagesOptions[0].value)
    setPracticedLanguagesOptions(practicedLanguagesOptions)
  }, [])

  const onSelectChange = (e: any) => {
    setIsChecked(e.target.checked)
    addRemoveBundle(bundle.id, e.target.checked, practicedLanguage)
  }

  const renderPracticedLanguageSelector = () => {
    if (practicedLanguagesOptions.length === 0) {
      return "- -"
    }

    if (practicedLanguagesOptions.length === 1) {
      return <div className="title">{practicedLanguagesOptions[0].text}</div>
    }

    return (
      <DynaDropdown
        options={practicedLanguagesOptions}
        id={bundle.id}
        onChange={(e: any) => {
          setPracticedLanguage(e.target.value)
        }}
        required
      />
    )
  }

  const bgColor = isChecked
    ? "#b5df87"
    : initState === packageStatusType.ENABLED
    ? "#ffdad2"
    : "#eeeeee"

  return (
    <div
      className="valign-wrapper flexListItem"
      style={{ backgroundColor: bgColor }}
    >
      <div className="valign-wrapper section center small">
        <img
          className="responsive-img"
          style={{ maxHeight: "80px" }}
          src={bundle.imageUrl}
        />
      </div>
      <div className="valign-wrapper section smallText">{bundle.name}</div>
      <div className="valign-wrapper section smallText hide-on-med-and-down">
        {bundle.description}
      </div>
      <div className="valign-wrapper section center">
        {renderPracticedLanguageSelector()}
      </div>
      <div className="valign-wrapper section center small">
        <label>
          <input
            type="checkbox"
            checked={isChecked}
            onChange={(e) => onSelectChange(e)}
          />
          <span />
        </label>
      </div>
    </div>
  )
}

export default BundleRowView
