export enum EAuthActionTypes {
  SET_IS_LOGGED_IN = "SET_IS_LOGGED_IN",
  LOGOUT = "LOGOUT",
  CLEAR_SENSITIVE_DATA = "CLEAR_SENSITIVE_DATA",
  SET_UUID = "SET_UUID",
}

export interface setIsLoggedInAction {
  type: typeof EAuthActionTypes.SET_IS_LOGGED_IN
  isLoggedIn: boolean
}

export interface logoutAction {
  type: typeof EAuthActionTypes.LOGOUT
}

export interface clearSensitiveDataAction {
  type: typeof EAuthActionTypes.CLEAR_SENSITIVE_DATA
}

export interface setUUIDAction {
  type: typeof EAuthActionTypes.SET_UUID
  uuid: string
}

export type AuthActionTypes =
  | setIsLoggedInAction
  | logoutAction
  | clearSensitiveDataAction
  | setUUIDAction
