import React, { useEffect } from 'react'

import { contentTranslationType } from '../../../../types/generalInterfaces';



interface Iprops {
  id:string,
  updateSelection: Function
  value:contentTranslationType
}

const TranslationSelector = ({ updateSelection,id,value }: Iprops) => {


  useEffect(() => {
    const elem = document.getElementById(`layout-select-translation_${id}`);
    M.FormSelect.init(elem as Element)
  }, []);


  const onSelectChange = (e: any) => {
    updateSelection(e.target.value as contentTranslationType)
  }

  return (
    <div className="input-field">
      <select id={`layout-select-translation_${id}`} onChange={onSelectChange} defaultValue={value}  >
        <option value={contentTranslationType.NO_TRANSLATION}>No Translation</option>
        <option value={contentTranslationType.NATIVE_LANGUAGE}>To child native language</option>
        <option value={contentTranslationType.PRACTICED_LANGUAGE}>To child practiced language</option>
      </select>
      <label>Translation type</label>
    </div>
  );
}

export default TranslationSelector