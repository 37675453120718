import {
  PaymentActionTypes,
  EPaymentActionTypes,
} from "../../types/actionTypes/paymentActionTypes"
import {
  SupervisorSubscriptionInfo,
  EMonetizationStatus,
} from "../../types/Payments"
import { ESubscriptionContactUsStatus } from "../../types/generalInterfaces"

const paymentDefaultState: {
  supervisorSubscriptionInfo: SupervisorSubscriptionInfo
  gettingSupervisorSubscriptionInfo: boolean
  showContactUs: boolean
  supervisorSubscriptionInfoFailed: boolean
  mentorNotExist: boolean
  subscriptionContactUsStatus: ESubscriptionContactUsStatus
} = {
  supervisorSubscriptionInfo: {
    supervisedName: "",
    supervisor: {
      email: "",
      firstName: "",
      lastName: "",
      photoUrl: "",
    },
    monetizationStatus: EMonetizationStatus.pending,
    monetizationLink: "",
  },
  gettingSupervisorSubscriptionInfo: false,
  showContactUs: false,
  supervisorSubscriptionInfoFailed: false,
  mentorNotExist: false,
  subscriptionContactUsStatus: ESubscriptionContactUsStatus.NONE,
}

const paymentReducer = (
  state = paymentDefaultState,
  action: PaymentActionTypes
): {
  supervisorSubscriptionInfo: SupervisorSubscriptionInfo
  gettingSupervisorSubscriptionInfo: boolean
  showContactUs: boolean
  supervisorSubscriptionInfoFailed: boolean
  mentorNotExist: boolean
  subscriptionContactUsStatus: ESubscriptionContactUsStatus
} => {
  switch (action.type) {
    case EPaymentActionTypes.GET_SUPERVISOR_SUBSCRIPTION_INFO_START: {
      return { ...state, gettingSupervisorSubscriptionInfo: true }
    }

    case EPaymentActionTypes.GET_SUPERVISOR_SUBSCRIPTION_INFO_FAILED: {
      return {
        ...state,
        supervisorSubscriptionInfoFailed: true,
        gettingSupervisorSubscriptionInfo: false,
      }
    }

    case EPaymentActionTypes.GET_SUPERVISOR_SUBSCRIPTION_INFO_SUCCESS: {
      const { supervisorSubscriptionInfo } = action
      return {
        ...state,
        supervisorSubscriptionInfo,
        gettingSupervisorSubscriptionInfo: false,
      }
    }

    case EPaymentActionTypes.SHOW_SUBSCRIPTION_CONTACT_US: {
      return {
        ...state,
        showContactUs: true,
      }
    }

    case EPaymentActionTypes.SUBMIT_SUBSCRIPTION_CONTACT_US_START: {
      return {
        ...state,
        subscriptionContactUsStatus: ESubscriptionContactUsStatus.SENDING,
      }
    }

    case EPaymentActionTypes.SUBMIT_SUBSCRIPTION_CONTACT_US_FAILED: {
      return {
        ...state,
        subscriptionContactUsStatus: ESubscriptionContactUsStatus.FAILED,
      }
    }

    case EPaymentActionTypes.SUBMIT_SUBSCRIPTION_CONTACT_US_SUCCESS: {
      return {
        ...state,
        subscriptionContactUsStatus: ESubscriptionContactUsStatus.SENT,
      }
    }

    case EPaymentActionTypes.RESET_SUBMIT_SUBSCRIPTION_CONTACT_US: {
      return {
        ...state,
        subscriptionContactUsStatus: ESubscriptionContactUsStatus.NONE,
      }
    }

    case EPaymentActionTypes.SET_MENTOR_NOT_EXIST: {
      return {
        ...state,
        mentorNotExist: true,
        gettingSupervisorSubscriptionInfo: false,
      }
    }

    default:
      return state
  }
}

export { paymentReducer }
