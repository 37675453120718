import React from "react"
import { buttonTypes } from "../../types/generalInterfaces"
import { DYNAMO_COLOR } from "../../constants/constants"

interface Props {
  text: string
  onClick?: Function
  type?: buttonTypes | undefined
  disabled?: boolean
  customBtnStyle?: object
  customContainerStyle?: object
}

const DynaButton = ({
  text,
  onClick = () => {},
  type = buttonTypes.button,
  disabled = false,
  customBtnStyle = {},
  customContainerStyle = {},
}: Props) => {
  return (
    <div
      style={{
        padding: 0,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        maxWidth: 300,
        ...customContainerStyle,
      }}
    >
      <button
        className="btn"
        style={{
          background: DYNAMO_COLOR,
          borderRadius: "10px",
          height: "50px",
          textTransform: "none",
          flex: 1,
          ...customBtnStyle,
        }}
        type={type}
        disabled={disabled}
        onClick={(e) => {
          e.preventDefault()
          e.stopPropagation()
          onClick(e)
        }}
      >
        <div className="smallText white-text">{text}</div>
      </button>
    </div>
  )
}

export { DynaButton }
