import React from "react"

interface Props {
  nickname: string
  mentorName: string
}

const PaymentSuccess = ({ nickname, mentorName }: Props) => {
  return (
    <div className="rtl">
      <div style={{ fontSize: 24, color: "#7a7a7a", marginTop: "20%" }}>
        {`תודה רבה לך ול${nickname}`}
      </div>
      <div
        style={{ fontSize: 24, color: "#7a7a7a" }}
      >{`שלחנו לך חשבונית. ${mentorName} ואנחנו נשלח לך עדכונים שונים לגבי ${nickname}.`}</div>
    </div>
  )
}

export default PaymentSuccess
