import { genderEnum } from "../types/generalInterfaces"

export const LOCK_SCREEN_FREQUENCY_NEVER = "NEVER"
export const LOCK_SCREEN_FREQUENCY_OPTIONS = [
  {
    value: "1",
    text: "Every unlock",
  },
  {
    value: "2",
    text: "Every other unlock",
  },
  {
    value: "3",
    text: "Every 3 unlocks",
  },
  {
    value: LOCK_SCREEN_FREQUENCY_NEVER,
    text: "Never",
  },
]

export const AGE_OPTIONS = [
  {
    value: 7,
    text: "7",
  },
  {
    value: 8,
    text: "8",
  },
  {
    value: 9,
    text: "9",
  },
  {
    value: 10,
    text: "10",
  },
  {
    value: 11,
    text: "11",
  },
  {
    value: 12,
    text: "12",
  },
  {
    value: 13,
    text: "13",
  },
  {
    value: 14,
    text: "14",
  },
  {
    value: 15,
    text: "15",
  },
]

export const GENDER_OPTIONS = [
  {
    value: genderEnum.MALE,
    text: "Male",
  },
  {
    value: genderEnum.FEMALE,
    text: "Female",
  },
  {
    value: genderEnum.UNKNOWN,
    text: "Unknown",
  },
]

export const LOCALE_OPTIONS = [
  {
    value: "en",
    text: "English",
  },
  {
    value: "he",
    text: "Hebrew",
  },
  {
    value: "pt-br",
    text: "Portoguese(BR)",
  },
]

export enum EPlatform {
  IOS = "IOS",
  ANDROID = "ANDROID",
}

export const DYNAMO_COLOR = "#f8684b"

export const AVATARS = [
  {
    url: require("../assets/images/avatars/kid_avatar_09.png"),
    name: "kid_avatar_09.png",
  },
  {
    url: require("../assets/images/avatars/kid_avatar_10.png"),
    name: "kid_avatar_10.png",
  },
  {
    url: require("../assets/images/avatars/kid_avatar_02.png"),
    name: "kid_avatar_02.png",
  },
  {
    url: require("../assets/images/avatars/kid_avatar_03.png"),
    name: "kid_avatar_03.png",
  },
  {
    url: require("../assets/images/avatars/kid_avatar_04.png"),
    name: "kid_avatar_04.png",
  },
  {
    url: require("../assets/images/avatars/kid_avatar_05.png"),
    name: "kid_avatar_05.png",
  },
  {
    url: require("../assets/images/avatars/kid_avatar_01.png"),
    name: "kid_avatar_01.png",
  },
  {
    url: require("../assets/images/avatars/kid_avatar_06.png"),
    name: "kid_avatar_06.png",
  },
  {
    url: require("../assets/images/avatars/kid_avatar_07.png"),
    name: "kid_avatar_07.png",
  },
  {
    url: require("../assets/images/avatars/kid_avatar_08.png"),
    name: "kid_avatar_08.png",
  },
]
