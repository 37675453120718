import axios, { AxiosResponse } from "axios"
import moment from "moment"
import config from "../configs/config"
import firebase from "../configs/fbConfig"
import storeFactory from "../store/store"

const store = storeFactory()

const deviceDetails = {
  platform: "web",
  locale: "en-US", // TODO
  timezone: "Asia/Jerusalem",
  // advertisingId: "", // TODO
  // appsFlyerId: "", // TODO
}

const requestInstance = axios.create({
  baseURL: config.apiGateway.URL,
  headers: {
    "content-type": "application/json",
  },
  timeout: 60000,
})

const requestInstance2 = axios.create({
  baseURL: config.apiGateway.URL2,
  headers: {
    "content-type": "application/json",
  },
  timeout: 60000,
})

const getBaseBody = () =>
  new Promise((resolve) => {
    resolve({
      // installId: UUID, TODO
      platform: "WEB",
      appVersionName: process.env.REACT_APP_VERSION,
      appVersionNumber: 144, // DO NOT lower then 144
      clientSource: "WEB_APP",
      supportedContentVersion: 1,
      locale: "en-US", // TODO
    })
  })

export const requestWrapper = async (
  url: string,
  params: any
): Promise<AxiosResponse> => {
  try {
    const { currentUser } = firebase.auth()
    const idToken = await currentUser!.getIdToken()
    const timestamp = moment().valueOf()

    const headers = {
      headers: {
        authorization: `Bearer ${idToken}`,
      },
    }

    const baseBody = await getBaseBody()
    const body = { ...params, ...(baseBody as object), timestamp }
    body.deviceDetails = deviceDetails

    console.log("requestWrapper", body.installId)
    return requestRetryWrapper(
      async () => requestInstance.post(url, body, headers),
      url
    )
  } catch (error) {
    return Promise.reject(error)
  }
}

export const requestWrapper2 = async (
  url: string,
  params?: any,
  unAuthed?: boolean
): Promise<AxiosResponse> => {
  try {
    const { currentUser } = firebase.auth()
    const idToken = await currentUser!.getIdToken()
    const timestamp = moment().valueOf()

    const {
      user: { id: userId, userType },
      auth: { uuid },
    } = store.getState()

    const headers = {
      headers: {
        authorization: `Bearer ${idToken}`,
      },
    }

    const baseBody = await getBaseBody()
    const body = { ...params, ...(baseBody as object), timestamp }
    body.deviceDetails = deviceDetails

    if (userId) {
      body.userId = userId
    }

    if (userType) {
      body.userType = userType
    }

    if (uuid) {
      body.installId = uuid
    } else {
      console.log("something went wrong - no install id")
    }

    return requestRetryWrapper(
      async () => requestInstance2.post(url, body, headers),
      url
    )
  } catch (error) {
    return Promise.reject(error)
  }
}

export const unAuthedRequestWrapper = async (
  url: string,
  params?: any
): Promise<AxiosResponse> => {
  try {
    const timestamp = moment().valueOf()

    const baseBody = await getBaseBody()
    const body = { ...params, ...(baseBody as object), timestamp }
    body.deviceDetails = deviceDetails

    return requestRetryWrapper(
      async () => requestInstance2.post(url, body),
      url
    )
  } catch (error) {
    return Promise.reject(error)
  }
}

const requestRetryWrapper = (
  ajaxRequest: any,
  url: any
): Promise<AxiosResponse> =>
  new Promise(async (resolve, reject) => {
    // lock.acquire('ajaxWithRetry', async (done) => {
    const secondRetry = async () => {
      try {
        const retry = await ajaxRequest()
        console.log(`${url} - second retry success`)
        // done();
        return resolve(retry)
      } catch (error) {
        console.log(`${url} - second retry failed`)
        // done();
        return reject(error)
      }
    }

    const firstRetry = async () => {
      try {
        const retry = await ajaxRequest()
        console.log(`${url} - first retry success`)
        // done();
        return resolve(retry)
      } catch (error) {
        console.log(`${url} - first retry failed`)
        await secondRetry()
      }
    }

    try {
      const response = await ajaxRequest()
      console.log(`${url} - success`)
      // done();
      return resolve(response)
    } catch (error) {
      console.log(`${url} - failed`)
      await firstRetry()
    }
  })
