import React from "react"
import { EPopupTypes } from "../../../types/Popups"
import { AVATARS } from "../../../constants/constants"
import { ImageWithFallback } from "../../common"

interface Props {
  onClick: Function
  handleImageAsFile: Function
}

const StudentAvatarPopup = ({ onClick, handleImageAsFile }: Props) => {
  return (
    <div id={EPopupTypes.SELECT_STUDENT_AVATAR_POPUP} className="modal center">
      <div className="modal-content title" style={{ borderBottom: 1 }}>
        Choose avatar
      </div>
      <div
        className="flex-container"
        style={{ padding: "0 20px 40px 20px", width: 600 }}
      >
        {AVATARS.map((avatar, index) => {
          return (
            <button
              key={index}
              type="button"
              className="waves-effect transparent noPadding"
              style={{ border: 0, margin: "8px" }}
              onClick={() => onClick(avatar)}
            >
              <ImageWithFallback
                imageUrl={avatar.url}
                fallbackImageUrl={require("../../../assets/images/profile_student_placeholder.png")}
                width={90}
                height={90}
              />
            </button>
          )
        })}
        {/* <div className="file-field input-field">
          <ImageWithFallback
            imageUrl={require("../../../assets/images/profile_student_placeholder.png")}
            fallbackImageUrl={require("../../../assets/images/profile_student_placeholder.png")}
            width={100}
            height={100}
          />
          <input type="file" onChange={(e) => handleImageAsFile(e)} />
        </div> */}
      </div>
    </div>
  )
}

export default StudentAvatarPopup
