import { User } from "../User"
import { Bundle } from "../Bundle"
import { Question } from "../Question"
import { Child, ChildInstallLinkResponseInterface, Supervised } from "../Child"

export enum EUserActionTypes {
  EDIT_USER = "EDIT_USER",
  GET_SUPERVISORS_SUCCESS = "GET_SUPERVISORS_SUCCESS",
  GET_CHILDREN_START = "GET_CHILDREN_START",
  GET_CHILDREN_SUCCESS = "GET_CHILDREN_SUCCESS",
  GET_CHILDREN_FAIL = "GET_CHILDREN_FAIL",
  UPDATE_CHILDREN_DATA_START = "UPDATE_CHILDREN_DATA_START",
  UPDATE_CHILDREN_DATA_SUCCESS = "UPDATE_CHILDREN_DATA_SUCCESS",
  UPDATE_CHILDREN_DATA_FAIL = "UPDATE_CHILDREN_DATA_FAIL",
  ADD_CHILD_SUCCESS = "ADD_CHILD_SUCCESS",
  ADD_CHILD_IN_PROGRESS = "ADD_CHILD_IN_PROGRESS",
  GET_PERSONALIZED_BUNDLES_START = "GET_PERSONALIZED_BUNDLES_START",
  GET_PERSONALIZED_BUNDLES_SUCCESS = "GET_PERSONALIZED_BUNDLES_SUCCESS",
  GET_PERSONALIZED_BUNDLES_FAIL = "GET_PERSONALIZED_BUNDLES_FAIL",
  GET_QUESTIONS_FOR_PACKAGEID_START = "GET_QUESTIONS_FOR_PACKAGEID_START",
  GET_QUESTIONS_FOR_PACKAGEID_SUCCESS = "GET_QUESTIONS_FOR_PACKAGEID_SUCCESS",
  GET_QUESTIONS_FOR_PACKAGEID_FAIL = "GET_QUESTIONS_FOR_PACKAGEID_FAIL",
  SUPER_MESSAGE_SENT_SUCCESS = "SUPER_MESSAGE_SENT_SUCCESS",
  FORCE_RING_SENT_SUCCESS = "FORCE_RING_SENT_SUCCESS",
  UPDATE_USER_PHOTO_URL = "UPDATE_USER_PHOTO_URL",
}

export interface editUserAction {
  type: typeof EUserActionTypes.EDIT_USER
  user: User
}

export interface getChildrenStartAction {
  type: typeof EUserActionTypes.GET_CHILDREN_START
}
export interface getChildrenSuccessAction {
  type: typeof EUserActionTypes.GET_CHILDREN_SUCCESS
  supervised: Supervised[]
}
export interface getChildrenFailAction {
  type: typeof EUserActionTypes.GET_CHILDREN_FAIL
}

export interface getSupervisorsAction {
  type: typeof EUserActionTypes.GET_SUPERVISORS_SUCCESS
  supervisors: User[]
}

export interface updateChildrenDataStartAction {
  type: typeof EUserActionTypes.UPDATE_CHILDREN_DATA_START
}
export interface updateChildrenDataSuccessAction {
  type: typeof EUserActionTypes.UPDATE_CHILDREN_DATA_SUCCESS
  supervised: Supervised[]
}
export interface updateChildrenDataFailAction {
  type: typeof EUserActionTypes.UPDATE_CHILDREN_DATA_FAIL
}

export interface addChildInProgressAction {
  type: typeof EUserActionTypes.ADD_CHILD_IN_PROGRESS
}

export interface addChildToFamilyAction {
  type: typeof EUserActionTypes.ADD_CHILD_SUCCESS
  connectDetails: ChildInstallLinkResponseInterface
}

export interface getPersonalizedBundlesStartAction {
  type: typeof EUserActionTypes.GET_PERSONALIZED_BUNDLES_START
}

export interface getPersonalizedBundlesSuccessAction {
  type: typeof EUserActionTypes.GET_PERSONALIZED_BUNDLES_SUCCESS
  bundels: Bundle[]
}

export interface getPersonalizedBundlesFailAction {
  type: typeof EUserActionTypes.GET_PERSONALIZED_BUNDLES_FAIL
}

export interface getQuestionsForPackageIdStart {
  type: typeof EUserActionTypes.GET_QUESTIONS_FOR_PACKAGEID_START
  bundleId: string
}

export interface getQuestionsForPackageIdAction {
  type: typeof EUserActionTypes.GET_QUESTIONS_FOR_PACKAGEID_SUCCESS
  questions: Question[]
  bundleId: string
}

export interface getQuestionsForPackageIdFail {
  type: typeof EUserActionTypes.GET_QUESTIONS_FOR_PACKAGEID_FAIL
  bundleId: string
}

export interface sendSuperMessageSuccessAction {
  type: typeof EUserActionTypes.SUPER_MESSAGE_SENT_SUCCESS
}

export interface sendForceRingSuccessAction {
  type: typeof EUserActionTypes.FORCE_RING_SENT_SUCCESS
}

export interface updateUserPhotoUrlAction {
  type: typeof EUserActionTypes.UPDATE_USER_PHOTO_URL
  photoUrl: string
}

export type UserActionTypes =
  | addChildInProgressAction
  | editUserAction
  | getPersonalizedBundlesStartAction
  | getPersonalizedBundlesSuccessAction
  | getPersonalizedBundlesFailAction
  | getQuestionsForPackageIdStart
  | getQuestionsForPackageIdAction
  | getQuestionsForPackageIdFail
  | sendSuperMessageSuccessAction
  | sendForceRingSuccessAction
  | getChildrenStartAction
  | getChildrenSuccessAction
  | getChildrenFailAction
  | updateChildrenDataStartAction
  | updateChildrenDataSuccessAction
  | updateChildrenDataFailAction
  | addChildToFamilyAction
  | updateUserPhotoUrlAction
  | getSupervisorsAction
