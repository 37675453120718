import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { ThunkDispatch } from "redux-thunk"
import { bindActionCreators } from "redux"
import { useHistory } from "react-router"
import { RootState } from "../../store/reducers/rootReducer"
import { AppActions } from "../../types/actionTypes/actions"
import { uploadUserProfileImage } from "../../store/actions/userActions"
import { DYNAMO_COLOR } from "../../constants/constants"
import { ImageWithFallback, DynaInput } from "../common"
import { DynaButton } from "../common/DynaButton"
import { buttonTypes } from "../../types/generalInterfaces"
import { EPopupTypes } from "../../types/Popups"
import { showPopupById, hidePopupById } from "../../store/actions/popupActions"
import { login } from "../../store/actions/authActions"

interface IProps {}

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  IProps

const UserProfile = ({
  userId,
  firstName,
  lastName,
  photoUrl,
  email,
  uploadUserProfileImage,
  showPopupById,
  hidePopupById,
  login,
}: Props) => {
  const history = useHistory()
  const [isFromSignUp, setIsFromSignUp] = useState(false)
  const [localFirstName, setLocalFirstName] = useState("")
  const [localLastName, setLocalLastName] = useState("")

  useEffect(() => {
    const elems = document.querySelectorAll("select")
    M.FormSelect.init(elems)
    M.updateTextFields()

    const modalElems = document.querySelectorAll(
      `#${EPopupTypes.UPLOAD_PROFILE_IMAGE_POPUP}`
    )
    M.Modal.init(modalElems)
  }, [])

  useEffect(() => {
    setLocalFirstName(firstName)
  }, [firstName])

  useEffect(() => {
    setLocalLastName(lastName)
  }, [lastName])

  useEffect(() => {
    const state = history.location.state as { isFromSignUp: boolean }
    if (state && state.isFromSignUp) {
      setIsFromSignUp(true)
    }
  }, [])

  const handleImageAsFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    const image = e.target.files && e.target.files[0]
    if (image) {
      uploadUserProfileImage({ userId, image })
    }
  }

  const submitFormHandler = (event: any) => {
    event.preventDefault()
    if (!isFromSignUp) {
      login(email, localFirstName, localLastName, true)
    }

    if (!photoUrl) {
      showPopupById(EPopupTypes.UPLOAD_PROFILE_IMAGE_POPUP)
    } else {
      history.push("/students")
    }
  }

  return (
    <div
      className="container center s12 l4 offset-l4"
      style={{ paddingTop: "100px" }}
    >
      <div style={{ display: "flex", flex: 1, flexDirection: "column" }}>
        <div style={{ flex: 1 }} className="title">{`${
          isFromSignUp ? "Create" : "Update"
        } your profile`}</div>

        <div className="file-field input-field">
          <ImageWithFallback
            imageUrl={photoUrl}
            fallbackImageUrl={require("../../assets/images/default_upload_image.png")}
            width={100}
            height={100}
            customStyle={
              photoUrl
                ? {
                    border: "2px solid #646464",
                  }
                : {}
            }
          />
          <input type="file" onChange={handleImageAsFile} />
        </div>

        <div style={{ flex: 1 }} className="title">
          Upload your profile image*
        </div>

        <div style={{ flex: 1, padding: 0, marginTop: 30 }}>
          <DynaInput
            value={localFirstName}
            id="firstname"
            onChange={(value: string) => setLocalFirstName(value)}
            title="First name"
          />
        </div>

        <div className="col s12 l6 offset-l3" style={{ padding: 0 }}>
          <DynaInput
            value={localLastName}
            id="lastName"
            onChange={(value: string) => setLocalLastName(value)}
            title="Last name"
          />
        </div>

        <div style={{ display: "flex", flex: 1 }}>
          <div style={{ flex: 1 }} />
          <div style={{ flex: 1, marginTop: "20px", marginBottom: "20px" }}>
            * It’s important to upload a good profile image, when you’ll invite
            your student’s parents, it’s the first thing they’ll see.
          </div>
          <div style={{ flex: 1 }} />
        </div>

        <div style={{ padding: 0 }}>
          <DynaButton
            text={`${isFromSignUp ? "Create" : "Update"} account`}
            customContainerStyle={{ margin: "0 auto" }}
            onClick={submitFormHandler}
            disabled={
              firstName.length === 0 ||
              lastName.length === 0 ||
              localFirstName.length === 0 ||
              localLastName.length === 0 ||
              (!isFromSignUp &&
                firstName === localFirstName &&
                lastName === localLastName)
            }
          />
        </div>
      </div>

      <div
        id={EPopupTypes.UPLOAD_PROFILE_IMAGE_POPUP}
        className="modal"
        style={{ padding: "30px 0" }}
      >
        <div className="modal-content">
          <div className="title">Wait...</div>
          <div className="subtitle" style={{ margin: "20px 0" }}>
            You need to upload a profile image in order to continue
          </div>
          <div className="file-field input-field">
            <DynaButton
              text="Upload profile image"
              customBtnStyle={{ padding: "0 60px" }}
              customContainerStyle={{ margin: "0 auto" }}
            />
            <input
              type="file"
              onChange={(e) => {
                hidePopupById(EPopupTypes.UPLOAD_PROFILE_IMAGE_POPUP)
                handleImageAsFile(e)
              }}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state: RootState) => {
  const {
    user: { id, firstName, lastName, photoUrl, email },
  } = state
  return {
    email,
    userId: id,
    firstName,
    lastName,
    photoUrl,
  }
}

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AppActions>) => {
  return bindActionCreators(
    {
      login,
      uploadUserProfileImage,
      showPopupById,
      hidePopupById,
    },
    dispatch
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(UserProfile)
