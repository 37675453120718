import React, { useState, useEffect } from 'react'
import { ContentInputType, contentTranslationType } from '../../../../types/generalInterfaces';
import { connect } from 'react-redux';
import { RootState } from '../../../../store/reducers/rootReducer';
import { Dispatch, bindActionCreators } from 'redux';
import { uploadImage} from '../../../../store/actions/assetsActions'
import { Content } from '../../../../types/Content';
import  TranslationSelector  from './TranslationSelector'
import SpriteSelector from './SpriteSelector'
import ImageSelector from './ImageSelector'
import { DynaInput } from '../../../common';

interface Props {
  id: string;
  inputType: ContentInputType
  characterCount:number
  content: Content
  textLabel: string,
  updateValue: Function,
  showTranslationSelector:boolean
}

type IProps = ReturnType<typeof mapDispatchToProps> & ReturnType<typeof mapStateToProps> & Props



const ContentInput = ({ content , updateValue, textLabel, inputType, id,assetManager,uploadImage,characterCount,showTranslationSelector  }: IProps) => {

  const [currentInputSelected, setCurrentInputSelected] = useState<ContentInputType>(ContentInputType.TEXT);
  const [imageUploadProgress, setImageUploadProgress] = useState<number>(0)
  const [imageNameChosen, setImageNameChosen] = useState<string>("")
  const [imageChosenURL, setImageChosenURL] = useState<string | null>(null)
  const [translationSelection, setTranslationSelection] = useState<contentTranslationType>(contentTranslationType.NO_TRANSLATION)

  useEffect(() => {
    var elems = document.querySelectorAll('textarea');
    M.CharacterCounter.init(elems)
    const startWithContentInputType = (content.text !== "" || inputType === ContentInputType.TEXT) ? ContentInputType.TEXT : (content.image !== "" || inputType === ContentInputType.IMAGE) ? ContentInputType.IMAGE : (content.sprite !== "" || inputType === ContentInputType.SPRITE) ? ContentInputType.SPRITE : ContentInputType.TEXT
    setCurrentInputSelected(startWithContentInputType)
    if (content.image !== "" && assetManager.images[content.image] && assetManager.images[content.image].downloadURL){
      const url = assetManager.images[content.image].downloadURL || ""
      setImageChosenURL(url)
    }
    setTranslationSelection(content.translation)
  }, []);
  
  useEffect(() => {
    M.updateTextFields();
  }, [currentInputSelected]);

  useEffect(() => {
    const imageUploadObj = assetManager.images[imageNameChosen]
    if (imageUploadObj) {
      setImageUploadProgress(assetManager.images[imageNameChosen].progress)
      setImageChosenURL(assetManager.images[imageNameChosen].downloadURL || null)
      if (assetManager.images[imageNameChosen].progress === 100) {
        myUpdateValue(ContentInputType.IMAGE, imageNameChosen)
      }
    }
  }, [assetManager]);

  const handleRadioChange = (e: any) => {
    setCurrentInputSelected(e.target.value)
  }

  const handleImageAsFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    const image = e.target.files && e.target.files[0]
    if (image) {
      setImageNameChosen(image.name)
      if (image) {
        uploadImage(image);
      }
    }

  }

  const handleTranslationSelectorChange = (translationType:contentTranslationType) => {
    setTranslationSelection(translationType)
  }

  const myUpdateValue = (type: ContentInputType, val: string) => {
    //console.log(`${type} --> ${val}`);
    switch (inputType) {
      case ContentInputType.TEXT_OR_IMAGE:
      case ContentInputType.TEXT_AND_IMAGE_OR_SPRITE:
        //if this is text AND image or sprite
        if (type === ContentInputType.TEXT) {
          //if this is a text update, then update text and make sure image is deleted
          updateValue(ContentInputType.TEXT, val,translationSelection);
          updateValue(ContentInputType.IMAGE, "");
          //updateValue(ContentInputType.SPRITE, "");
        } else if (type === ContentInputType.IMAGE) {
          //if this is an image update, then update image and make sure text is deleted
          updateValue(ContentInputType.TEXT, "");
          updateValue(ContentInputType.IMAGE, val);
          //updateValue(ContentInputType.SPRITE, "");
        }else if (type === ContentInputType.SPRITE) {
          updateValue(ContentInputType.SPRITE, val);
        }
        break;
      case ContentInputType.TEXT_AND_IMAGE:
        //if this is text OR image
        if (type === ContentInputType.TEXT) {
          updateValue(ContentInputType.TEXT, val,translationSelection);
        } else if (type === ContentInputType.IMAGE) {
          updateValue(ContentInputType.IMAGE, val);
        }
        break;
      case ContentInputType.TEXT:
        //if this is text OR image
        if (type === ContentInputType.TEXT) {
          //if this is a text update, then update text and make sure image is deleted
          updateValue(ContentInputType.TEXT, val,translationSelection);
        }
        break;
      case ContentInputType.IMAGE:
        //if this is text OR image
        if (type === ContentInputType.IMAGE) {
          //if this is a text update, then update text and make sure image is deleted
          updateValue(ContentInputType.IMAGE, val);
        }
        break;

      default:
        break;
    }
  }

  const textInput = <div >
    {/* <DynaInput
            value={content.text}
            id={id}
            onChange={(value: string) => myUpdateValue(ContentInputType.TEXT, value)}
            placeholder={textLabel}
          /> */}
    <div className="input-field">
      <textarea
        id={id}
        className="materialize-textarea"
        data-length={characterCount}
        value={content.text}
        onChange={(e) => {
          myUpdateValue(ContentInputType.TEXT, e.target.value);
        }}
      />
      <label htmlFor={id} >{textLabel}</label>
    </div>
    {showTranslationSelector && <TranslationSelector id={id} value={content.translation} updateSelection={handleTranslationSelectorChange} />}
  </div>

  
  const currentInputSelectedView = () => {
    switch (currentInputSelected) {
      case ContentInputType.TEXT:
        return textInput
      case ContentInputType.IMAGE:
        return <ImageSelector contentId={content.id} imageName={content.image} updateImageSelected={(val:string) => {myUpdateValue(ContentInputType.IMAGE,val)}}/> //imageInput
      case ContentInputType.SPRITE:
        return <SpriteSelector spriteName={content.sprite} updateSpriteSelected={(val:string) => {myUpdateValue(ContentInputType.SPRITE,val)}}/>
      default:
        return textInput;
    }
  }

  const renderView = () => {
    switch (inputType) {
      case ContentInputType.TEXT_OR_IMAGE:
        return (
          <div>
            <div className="row" >
              <p className="col s2">
                <label >
                  <input name={id} type="radio" value={ContentInputType.TEXT} checked={currentInputSelected === ContentInputType.TEXT} onChange={handleRadioChange} />
                  <span>Text</span>
                </label>
              </p>
              <p className="col s2 offset-s1">
                <label>
                  <input name={id} type="radio" value={ContentInputType.IMAGE} checked={currentInputSelected === ContentInputType.IMAGE} onChange={handleRadioChange} />
                  <span>Image</span>
                </label>
              </p>
            </div>
            {currentInputSelectedView()}
          </div>
        )
      case ContentInputType.TEXT_AND_IMAGE_OR_SPRITE:
        return (
          <div className="row" >
            <SpriteSelector spriteName={content.sprite} updateSpriteSelected={(val:string) => {myUpdateValue(ContentInputType.SPRITE,val)}}/>
            {textInput}
              
          </div>
        )
      case ContentInputType.TEXT:
        return (
          <div>
            {currentInputSelectedView()}
          </div>
        )
      case ContentInputType.IMAGE:
        return (
          <div>
            {currentInputSelectedView()}
          </div>
        )
      case ContentInputType.TEXT_AND_IMAGE:
        return (
          <div>
            Not supported yet...
              </div>
        )


      default:
        break;
    }
  }

  return (
    <div >
      {renderView()}
    </div>
  )
}

const mapStateToProps = (state: RootState) => {
  return {
    assetManager: state.assetManager
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => {

  return bindActionCreators(
    {
      uploadImage
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ContentInput)

