import React, { useEffect, useState } from 'react'
import { DYNAMO_COLOR } from '../../../constants/constants';
import SelectQuestionLayoutPopup from '../../layout/popups/SelectQuestionLayoutPopup';
import { webLayoutTypes, WebLayoutTypeIds } from '../../../types/generalInterfaces';


const ql1 = require("../../../assets/images/questionLayouts/q_type_1.png")
const ql2 = require("../../../assets/images/questionLayouts/q_type_2.png")
const ql3 = require("../../../assets/images/questionLayouts/q_type_3.png")
const ql4 = require("../../../assets/images/questionLayouts/q_type_4.png")
const ql5 = require("../../../assets/images/questionLayouts/q_type_5.png")
const ql6 = require("../../../assets/images/questionLayouts/q_type_6.png")
//TODO  - make this dynamic somehow and require looping over the whole folder

interface Iprops {
  onLayoutSelect:Function
  disabled:boolean
  startWebLayoutId:string
}

const QuestionLayoutSelector = ({ onLayoutSelect,disabled,startWebLayoutId }: Iprops) => {

  const [selectedLayoutId, setSelectedLayoutId] = useState<string>(webLayoutTypes.Q_TEXT_A_TEXT_3.id);
  
  useEffect(() => {
    var modalelems = document.querySelectorAll('.modal');
    M.Modal.init(modalelems);
  }, [])

  useEffect(() => {
    setSelectedLayoutId(startWebLayoutId)
  }, [startWebLayoutId]);

  const getRelevantImage = (currentWebLayoutid:string) => {//TODO - see top TODO make this not manuall
    switch (currentWebLayoutid) {
      case WebLayoutTypeIds.Q_TEXT_A_TEXT_3 :
        return ql1;
        case WebLayoutTypeIds.Q_TEXT_A_TEXT_HOR_3 :
        return ql2;
        case WebLayoutTypeIds.Q_IMAGE_A_TEXT_HOR_3 :
        return ql3;
        case WebLayoutTypeIds.Q_TEXT_A_IMAGE_4 :
        return ql4;
        case WebLayoutTypeIds.DID_YOU_KNOW :
        return ql5;
        case WebLayoutTypeIds.Q_TEXT_A_TRUE_OR_FALSE :
        return ql6;
        
    
      default:
        break;
    }
  }

  return (
    <>
    <div className="" style={{}}>
      <div className="row center">
        Choose question type
      </div>
      <div className="row center" style={{height:"150px"}}>
        <img className="responsive-img center" style={{width:"82px"}} alt="" src={getRelevantImage(selectedLayoutId)} />
      </div>
      <div className="row center">
        <button className="btn col s12 l6 offset-l3"
            disabled={disabled}
            style={{background:DYNAMO_COLOR}}
            onClick={(e) =>{
              e.preventDefault();
              const elem = document.getElementById("SelectQuestionLayoutPopup")
              const instance =  M.Modal.getInstance(elem as Element)
              instance && instance.open()
            } }
        >
          Choose type
        </button>
      </div>

      
    </div>
    <SelectQuestionLayoutPopup
    id="SelectQuestionLayoutPopup"
    submitActions={(webLayoutTypeId:string) => {
      const elem = document.getElementById("SelectQuestionLayoutPopup")
      const instance = M.Modal.getInstance(elem as Element)
      instance.close()
      setSelectedLayoutId(webLayoutTypeId)
      onLayoutSelect(webLayoutTypeId)
    }}
  />    
  </>
  )
}

export default QuestionLayoutSelector