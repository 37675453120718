import { Dispatch } from "redux"
import { requestWrapper2 } from "../../services/ServerConnection"
import { RootState } from "../reducers/rootReducer"
import { Bundle } from "../../types/Bundle"
import { Question } from "../../types/Question"
import { EUserType } from "../../types/generalInterfaces"
import { EBundleActionsTypes } from "../../types/actionTypes/BundleActionTypes"
import { getPersonalizedBundles, getQuestionsForPackageId } from "./userActions"
import { AppActions } from "../../types/actionTypes/actions"

export const updateSelectedBundleId = ({
  bundleId,
  isEditable,
}: {
  bundleId: string
  isEditable?: boolean
}): any => (dispatch: Dispatch<AppActions>) => {
  dispatch({
    type: EBundleActionsTypes.UPDATE_SELECTED_PERSONALIZED_BUNDLE_ID,
    bundleId,
    isEditable,
  })
}

export const clearSelectedBundleId = () => (dispatch: Dispatch<AppActions>) => {
  dispatch({
    type: EBundleActionsTypes.CLEAR_SELECTED_BUNDLE_ID,
  })
}

export interface ITypedAction {
  type: EBundleActionsTypes
  payload: any
}

export const createBundle = (bundle: Bundle) => async (
  dispatch: Dispatch,
  getState: () => RootState
) => {
  try {
    const { user } = getState()
    dispatch({
      type: EBundleActionsTypes.CREATE_OR_UPDATE_BUNDLE_START,
      bundleId: bundle.id,
    })
    dispatch(
      updateSelectedBundleId({
        bundleId: bundle.id,
        isEditable: false,
      })
    )

    const res = await requestWrapper2("createOrUpdateBundle", {
      userId: user.id,
      userType: EUserType.MENTOR, // TODO
      bundle: { ...bundle, difficultyLevel: 1 }, // TODO - deal with difficulty Level
    })

    console.log(res)
    if (res.status === 200) {
      const { packageId, shareableLink } = res.data
      bundle.id = packageId
      bundle.shareableLink = shareableLink
      dispatch({
        type: EBundleActionsTypes.CREATE_OR_UPDATE_BUNDLE_SUCCESS,
        bundleId: bundle.id,
      })
      dispatch(getPersonalizedBundles())
      dispatch(
        updateSelectedBundleId({
          bundleId: packageId,
          isEditable: true,
        })
      )
    }
  } catch (error) {
    console.error(error)
    console.error(error.response)
    dispatch({
      type: EBundleActionsTypes.CREATE_OR_UPDATE_BUNDLE_FAIL,
      bundleId: bundle.id,
    })
  }
}

export const updateQuestionsBundle = (
  bundle: Bundle,
  questionActions: { action: string; question: Question }[]
) => async (dispatch: Dispatch, getState: () => RootState) => {
  try {
    const { user } = getState()
    dispatch({
      type: EBundleActionsTypes.UPDATE_QUESTIONS_START,
      bundleId: bundle.id,
    })

    const res = await requestWrapper2("updateQuestionsBundle", {
      userId: user.id,
      userType: EUserType.MENTOR, // TODO
      bundleId: bundle.id,
      questionActions,
    })

    console.log(res)
    if (res.status === 200) {
      dispatch({
        type: EBundleActionsTypes.UPDATE_QUESTIONS_SUCCES,
        bundleId: bundle.id,
        questions: bundle.questions,
      })
    }
  } catch (error) {
    console.log(error)
    console.log(error.response)
    dispatch({
      type: EBundleActionsTypes.UPDATE_QUESTIONS_ERROR,
      bundleId: bundle.id,
    })
  }
}
