import {
  ChildActionTypes,
  EChildActionTypes,
} from "../../types/actionTypes/ChildActionTypes"
import {
  EBundleActionsTypes,
  BundleActionTypes,
} from "../../types/actionTypes/BundleActionTypes"
import {
  EUserActionTypes,
  UserActionTypes,
} from "../../types/actionTypes/UserActionTypes"
import {
  EKingdomActionsTypes,
  KingdomActionTypes,
} from "../../types/actionTypes/KingdomActionTypes"

export interface loaderInterface {
  [key: string]: { isUpdating: boolean; timestamp?: number }
}

export interface loadersReducerDefaultStateInterface {
  updatingLastAnswers: loaderInterface
  bundleLoaders: loaderInterface
  updatingBundleQuestions: loaderInterface
  updatingBundlesLoaders: loaderInterface
  updatingConnectionDetails: loaderInterface
  gettingKingdomBundles: boolean
  gettingPersonalizedBundles: boolean
  gettingPublicPersonalizedBundles: boolean
  gettingStudents: boolean
  gettingStudentData: boolean
  gettingConnectionDetails: boolean
}

const loadersReducerDefaultState: loadersReducerDefaultStateInterface = {
  updatingLastAnswers: {},
  bundleLoaders: {},
  updatingBundleQuestions: {},
  updatingBundlesLoaders: {},
  updatingConnectionDetails: {},
  gettingKingdomBundles: false,
  gettingPersonalizedBundles: false,
  gettingPublicPersonalizedBundles: false,
  gettingStudents: false,
  gettingStudentData: false,
  gettingConnectionDetails: false,
}

const loadersReducer = (
  state = loadersReducerDefaultState,
  action:
    | ChildActionTypes
    | BundleActionTypes
    | UserActionTypes
    | KingdomActionTypes
): loadersReducerDefaultStateInterface => {
  switch (action.type) {
    case EChildActionTypes.GET_CHILD_LAST_ANSWERS_START: {
      const { supervisedId } = action
      const obj = { [supervisedId]: { isUpdating: true } }
      return {
        ...state,
        updatingLastAnswers: { ...state.updatingLastAnswers, ...obj },
      }
    }
    case EChildActionTypes.GET_CHILD_LAST_ANSWERS_SUCCESS: {
      const { supervisedId } = action
      const obj = { [supervisedId]: { isUpdating: false } }
      return {
        ...state,
        updatingLastAnswers: { ...state.updatingLastAnswers, ...obj },
      }
    }

    case EBundleActionsTypes.CREATE_OR_UPDATE_BUNDLE_START:
    case EBundleActionsTypes.UPDATE_QUESTIONS_START: {
      const { bundleId } = action
      const obj = { [bundleId]: { isUpdating: true } }
      return { ...state, bundleLoaders: { ...state.bundleLoaders, ...obj } }
    }

    case EBundleActionsTypes.CREATE_OR_UPDATE_BUNDLE_FAIL:
    case EBundleActionsTypes.CREATE_OR_UPDATE_BUNDLE_SUCCESS:
    case EBundleActionsTypes.UPDATE_QUESTIONS_ERROR:
    case EBundleActionsTypes.UPDATE_QUESTIONS_SUCCES: {
      const { bundleId } = action
      const obj = { [bundleId]: { isUpdating: false } }
      return { ...state, bundleLoaders: { ...state.bundleLoaders, ...obj } }
    }

    case EUserActionTypes.GET_QUESTIONS_FOR_PACKAGEID_START: {
      const { bundleId } = action
      const obj = { [bundleId]: { isUpdating: true } }
      return {
        ...state,
        updatingBundleQuestions: { ...state.updatingBundleQuestions, ...obj },
      }
    }

    case EUserActionTypes.GET_QUESTIONS_FOR_PACKAGEID_FAIL:
    case EUserActionTypes.GET_QUESTIONS_FOR_PACKAGEID_SUCCESS: {
      const { bundleId } = action
      const obj = { [bundleId]: { isUpdating: false } }
      return {
        ...state,
        updatingBundleQuestions: { ...state.updatingBundleQuestions, ...obj },
      }
    }

    case EChildActionTypes.ADD_REMOVE_PACKAGES_START: {
      const { supervisedId } = action
      const obj = { [supervisedId]: { isUpdating: true } }
      return {
        ...state,
        updatingBundlesLoaders: { ...state.updatingBundlesLoaders, ...obj },
      }
    }

    case EChildActionTypes.ADD_REMOVE_PACKAGES_SUCCESS: {
      const { supervisedId } = action
      const obj = { [supervisedId]: { isUpdating: false } }
      return {
        ...state,
        updatingBundlesLoaders: { ...state.updatingBundlesLoaders, ...obj },
      }
    }

    case EChildActionTypes.GET_CHILD_CONNECT_DETAILS_START: {
      const { supervisedId } = action
      const obj = { [supervisedId]: { isUpdating: true } }
      return {
        ...state,
        gettingConnectionDetails: true,
        updatingConnectionDetails: {
          ...state.updatingConnectionDetails,
          ...obj,
        },
      }
    }

    case EChildActionTypes.GET_CHILD_CONNECT_DETAILS_SUCCESS:
    case EChildActionTypes.GET_CHILD_CONNECT_DETAILS_FAIL: {
      const { supervisedId } = action
      const obj = { [supervisedId]: { isUpdating: false } }
      return {
        ...state,
        gettingConnectionDetails: false,
        updatingConnectionDetails: {
          ...state.updatingConnectionDetails,
          ...obj,
        },
      }
    }

    case EKingdomActionsTypes.GET_KINGDOMS:
      return { ...state, gettingKingdomBundles: true }

    case EKingdomActionsTypes.GET_KINGDOMS_FAIL:
    case EKingdomActionsTypes.UPDATE_KINGDOMS: {
      return { ...state, gettingKingdomBundles: false }
    }

    case EUserActionTypes.GET_PERSONALIZED_BUNDLES_START: {
      return { ...state, gettingPersonalizedBundles: true }
    }

    case EUserActionTypes.GET_PERSONALIZED_BUNDLES_SUCCESS:
    case EUserActionTypes.GET_PERSONALIZED_BUNDLES_FAIL: {
      return { ...state, gettingPersonalizedBundles: false }
    }

    case EUserActionTypes.GET_CHILDREN_START: {
      return { ...state, gettingStudents: true }
    }

    case EUserActionTypes.GET_CHILDREN_SUCCESS:
    case EUserActionTypes.GET_CHILDREN_FAIL: {
      return { ...state, gettingStudents: false }
    }

    case EUserActionTypes.UPDATE_CHILDREN_DATA_START: {
      return { ...state, gettingStudentData: true }
    }

    case EUserActionTypes.UPDATE_CHILDREN_DATA_SUCCESS:
    case EUserActionTypes.UPDATE_CHILDREN_DATA_FAIL: {
      return { ...state, gettingStudentData: false }
    }

    case EKingdomActionsTypes.GET_PUBLIC_PERSONALIZED_BUNDLES_START: {
      return { ...state, gettingPublicPersonalizedBundles: true }
    }

    case EKingdomActionsTypes.GET_PUBLIC_PERSONALIZED_BUNDLES_SUCCESS:
    case EKingdomActionsTypes.GET_PUBLIC_PERSONALIZED_BUNDLES_FAIL: {
      return { ...state, gettingPublicPersonalizedBundles: false }
    }

    default:
      return state
  }
}

export { loadersReducer }
