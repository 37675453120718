export enum ContentInputType {
  TEXT = "TEXT",
  IMAGE = "IMAGE",
  SOUND = "SOUND",
  SPRITE = "SPRITE",
  TEXT_OR_IMAGE = "TEXT_OR_IMAGE",
  TEXT_AND_IMAGE = "TEXT_OR_IMAGE",
  TEXT_AND_IMAGE_OR_SPRITE = "TEXT_AND_IMAGE_OR_SPRITE",
}

export enum EUserType {
  CHILD = "CHILD",
  PARENT = "PARENT",
  MENTOR = "MENTOR",
}

export enum activeStatus {
  PENDING = "PENDING",
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
}

export enum EAssetType {
  IMAGE_FOR_PERSONALIZED_BUNDLE = "IMAGE_FOR_PERSONALIZED_BUNDLE",
}

export enum ECharacterCount {
  ANSWER_CHAR_COUNT = 20,
  ANSWER_SHORT_CHAR_COUNT = 5,
  NOTIFICATION_CHAR_COUNT = 100,
  Q_ONE_LINE_CHAR_COUNT = 50,
  Q_TWO_LINES_CHAR_COUNT = 100,
}

export interface answerTryInterface {
  id: string
  timestamp: number
}

export enum packageStatusType {
  FRESH = "FRESH",
  RECOMMENDED = "RECOMMENDED",
  ENABLED = "ENABLED",
  PENDING_APPROVAL = "PENDING_APPROVAL",
  DISABLED = "DISABLED",
  COMPLETED = "COMPLETED",
}

export enum ParentTypeEnum {
  MOM = "Mom",
  DAD = "Dad",
  OTHER = "Other",
}

export enum SourceType {
  DYNAMO = "DYNAMO",
  PARENT = "PARENT",
  DIRECT_MESSAGE = "DIRECT_MESSAGE",
  BUNDLER = "BUNDLER",
}

export enum contentTypeType {
  QUESTION = "QUESTION",
  INFO = "INFO",
}

export enum contentTranslationType {
  NO_TRANSLATION = "noTranslation",
  NATIVE_LANGUAGE = "nativeLanguage",
  PRACTICED_LANGUAGE = "practicedLanguage",
}

export interface questionPackageActionInterface {
  id: string
  action: packageActionType
  practicedLanguage?: string
}

export enum packageActionType {
  ADD = "ADD",
  REMOVE = "REMOVE",
}

export enum PackageDBStatus {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
}

export enum genderEnum {
  MALE = "MALE",
  FEMALE = "FEMALE",
  UNKNOWN = "UNKNOWN",
}

export enum layoutTypes {
  VERTICAL_TEXT_TWO_LINES_1_TO_4_ANSWERS_WHITE_BACKGROUND = "VERTICAL_TEXT_TWO_LINES_1_TO_4_ANSWERS_WHITE_BACKGROUND",
  VERTICAL_TEXT_ONE_LINE_1_TO_4_ANSWERS_WHITE_BACKGROUND = "VERTICAL_TEXT_ONE_LINE_1_TO_4_ANSWERS_WHITE_BACKGROUND",
  GENERIC_DID_YOU_KNOW = "GENERIC_DID_YOU_KNOW",

  DID_YOU_KNOW_VERTICAL_A = "DID_YOU_KNOW_VERTICAL_A",
  BE_INTERNET_AWESOME_VERTICAL_A = "BE_INTERNET_AWESOME_VERTICAL_A",
  HORIZONTAL_A = "HORIZONTAL_A",
  HORIZONTAL_IMAGE_320PX_1_TO_4_ANSWERS_NO_BACKGROUND = "HORIZONTAL_IMAGE_320PX_1_TO_4_ANSWERS_NO_BACKGROUND",
  VERTICAL_TEXT_ONE_LINE_1_TO_4_ANSWERS_GREEN_BACKGROUND = "VERTICAL_TEXT_ONE_LINE_1_TO_4_ANSWERS_GREEN_BACKGROUND",
  VERTICAL_TEXT_TWO_LINES_1_TO_4_ANSWERS_GREEN_BACKGROUND = "VERTICAL_TEXT_TWO_LINES_1_TO_4_ANSWERS_GREEN_BACKGROUND",
  HORIZONTAL_TWO_ROWS_IMAGE_220PX_AND_BOTTOM_TEXT_1_TO_8_ANSWERS_GREEN_BACKGROUND = "HORIZONTAL_TWO_ROWS_IMAGE_220PX_AND_BOTTOM_TEXT_1_TO_8_ANSWERS_GREEN_BACKGROUND",
  HORIZONTAL_TWO_ROWS_IMAGE_220PX_AND_BOTTOM_TEXT_1_TO_8_ANSWERS_WHITE_BACKGROUND = "HORIZONTAL_TWO_ROWS_IMAGE_220PX_AND_BOTTOM_TEXT_1_TO_8_ANSWERS_WHITE_BACKGROUND",
}

export enum WebLayoutTypeIds {
  Q_TEXT_A_TEXT_3 = "Q_TEXT_A_TEXT_3",
  Q_TEXT_A_TEXT_HOR_3 = "Q_TEXT_A_TEXT_HOR_3",
  Q_IMAGE_A_TEXT_HOR_3 = "Q_IMAGE_A_TEXT_HOR_3",
  Q_TEXT_A_IMAGE_4 = "Q_TEXT_A_IMAGE_4",
  DID_YOU_KNOW = "DID_YOU_KNOW",
  Q_TEXT_A_TRUE_OR_FALSE = "Q_TEXT_A_TRUE_OR_FALSE",
}

export interface webLayoutsInterface {
  [key: string]: {
    id: string
    layoutType: layoutTypes
    answers: { contentType: ContentInputType }[]
    questionContentType: ContentInputType
    imageLayoutName: string
    order: number
  }
}

export const webLayoutTypes: webLayoutsInterface = {
  Q_TEXT_A_TEXT_3: {
    id: WebLayoutTypeIds.Q_TEXT_A_TEXT_3,
    layoutType:
      layoutTypes.VERTICAL_TEXT_TWO_LINES_1_TO_4_ANSWERS_GREEN_BACKGROUND,
    answers: [
      { contentType: ContentInputType.TEXT },
      { contentType: ContentInputType.TEXT },
      { contentType: ContentInputType.TEXT },
    ],
    questionContentType: ContentInputType.TEXT,
    imageLayoutName: "q_type_1.png",
    order: 1,
  },
  Q_TEXT_A_TEXT_HOR_3: {
    id: WebLayoutTypeIds.Q_TEXT_A_TEXT_HOR_3,
    layoutType:
      layoutTypes.HORIZONTAL_TWO_ROWS_IMAGE_220PX_AND_BOTTOM_TEXT_1_TO_8_ANSWERS_GREEN_BACKGROUND,
    answers: [
      { contentType: ContentInputType.TEXT },
      { contentType: ContentInputType.TEXT },
      { contentType: ContentInputType.TEXT },
    ],
    questionContentType: ContentInputType.TEXT,
    imageLayoutName: "q_type_2.png",
    order: 2,
  },
  Q_IMAGE_A_TEXT_HOR_3: {
    id: WebLayoutTypeIds.Q_IMAGE_A_TEXT_HOR_3,
    layoutType:
      layoutTypes.HORIZONTAL_TWO_ROWS_IMAGE_220PX_AND_BOTTOM_TEXT_1_TO_8_ANSWERS_GREEN_BACKGROUND,
    answers: [
      { contentType: ContentInputType.TEXT },
      { contentType: ContentInputType.TEXT },
      { contentType: ContentInputType.TEXT },
    ],
    questionContentType: ContentInputType.IMAGE,
    imageLayoutName: "q_type_3.png",
    order: 3,
  },
  Q_TEXT_A_IMAGE_4: {
    id: WebLayoutTypeIds.Q_TEXT_A_IMAGE_4,
    layoutType: layoutTypes.HORIZONTAL_IMAGE_320PX_1_TO_4_ANSWERS_NO_BACKGROUND,
    answers: [
      { contentType: ContentInputType.IMAGE },
      { contentType: ContentInputType.IMAGE },
      { contentType: ContentInputType.IMAGE },
      { contentType: ContentInputType.IMAGE },
    ],
    questionContentType: ContentInputType.TEXT,
    imageLayoutName: "q_type_4.png",
    order: 4,
  },
  DID_YOU_KNOW: {
    id: WebLayoutTypeIds.DID_YOU_KNOW,
    layoutType: layoutTypes.GENERIC_DID_YOU_KNOW,
    answers: [{ contentType: ContentInputType.TEXT }],
    questionContentType: ContentInputType.TEXT_AND_IMAGE_OR_SPRITE,
    imageLayoutName: "q_type_5.png",
    order: 5,
  },
  Q_TEXT_A_TRUE_OR_FALSE: {
    id: WebLayoutTypeIds.Q_TEXT_A_TRUE_OR_FALSE,
    layoutType:
      layoutTypes.VERTICAL_TEXT_TWO_LINES_1_TO_4_ANSWERS_GREEN_BACKGROUND,
    answers: [
      { contentType: ContentInputType.TEXT },
      { contentType: ContentInputType.TEXT },
    ],
    questionContentType: ContentInputType.TEXT,
    imageLayoutName: "q_type_6.png",
    order: 6,
  },
}

export enum EStorageKeys {
  isAdmin = "rrjvivhe",
}

export enum ESubscriptionContactUsStatus {
  NONE = "NONE",
  SENDING = "SENDING",
  SENT = "SENT",
  FAILED = "FAILED",
}

export enum EPaymentStatus {
  success = "PAYMENT_SUCCESS",
  failure = "PAYMENT_FAILURE",
}

export enum buttonTypes {
  button = "button",
  submit = "submit",
  reset = "reset",
}

export enum EBundlePrivacy {
  PUBLIC = "PUBLIC",
  PRIVATE = "PRIVATE",
}
