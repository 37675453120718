import React from "react"
import { connect } from "react-redux"
import { ThunkDispatch } from "redux-thunk"
import { bindActionCreators } from "redux"
import { NavLink } from "react-router-dom"
import { RootState } from "../../store/reducers/rootReducer"
import { AppActions } from "../../types/actionTypes/actions"
import { DYNAMO_COLOR } from "../../constants/constants"
import { hideSideBar } from "../../utils/utils"
import { history } from "../../App"
import LocaleSelector from "./LocaleSelector"

interface IProps {
  hidden: boolean
  logo: string
}

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  IProps

const NavBar = ({ hidden, logo }: Props) => {
  return (
    <div className="navbar-fixed" hidden={hidden} style={{ zIndex: 1000 }}>
      <nav
        className="navbar-wrapper"
        style={{
          backgroundColor: DYNAMO_COLOR,
          paddingLeft: "0px !important",
          paddingRight: "20px",
        }}
      >
        {!hideSideBar(history?.location?.pathname) && (
          <a
            href="##"
            data-target="sidenav-left"
            className="sidenav-trigger show-on-small"
          >
            <i className="material-icons">menu</i>
          </a>
        )}
        <div className="right">
          <LocaleSelector />
        </div>

        <NavLink
          to={{ pathname: "/" }}
          className="valign-wrapper"
          style={{ height: "100%" }}
        >
          <img
            className="responsive-img"
            alt=""
            src={logo}
            style={{ height: "60%", marginLeft: "12px" }}
          />
        </NavLink>
      </nav>
    </div>
  )
}

const mapStateToProps = (state: RootState) => {
  const { user } = state
  return {
    user,
  }
}

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AppActions>) => {
  return bindActionCreators({}, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(NavBar)
