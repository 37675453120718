import React, { useEffect } from "react"

interface Option {
  value: string | number
  text: string
}

interface Props {
  id: string
  onChange: Function
  title?: string
  required?: boolean
  options: Option[]
  initialValue?: number | string | null
}

const DynaDropdown = ({
  onChange,
  id,
  title = "",
  required = false,
  options,
  initialValue,
}: Props) => {
  useEffect(() => {
    const elem = document.getElementById(id)
    let instance: any = null
    if (elem) {
      instance = M.FormSelect.init(elem, {
        classes: "createStudentDropdown",
      })
    }

    return () => instance.destroy()
  }, [initialValue])

  const renderOptions = (options: Option[]) => {
    return options.map((option, index) => {
      return (
        <option key={index} value={option.value}>
          {option.text}
        </option>
      )
    })
  }

  return (
    <div className="dynaInputContainer">
      <div className="titleContainer">
        {title && (
          <div
            className="right-align subtitle"
            style={{
              padding: "0 20px",
            }}
          >
            {title}
          </div>
        )}
      </div>
      <div className="input-field">
        <select
          id={id}
          onChange={(e) => onChange(e)}
          defaultValue={initialValue || ""}
          required={required}
        >
          {renderOptions(options)}
        </select>
      </div>
      <div className="placeholder" />
    </div>
  )
}

export { DynaDropdown }
