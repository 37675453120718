import React from "react"

interface Props {
  id: string
  value: string
  onChange: Function
  title?: string
  titleTextColor?: string
  type?: string
  validate?: boolean
  required?: boolean
  minLength?: number
  errorText?: string
  placeholder?: string
}

const DynaInput = ({
  value,
  onChange,
  id,
  title = "",
  type = "text",
  validate = false,
  required = false,
  minLength = 2,
  errorText = "",
  placeholder = "",
}: Props) => {
  return (
    <div className="dynaInputContainer">
      {title && (
        <div className="titleContainer">
          <div
            className="right-align subtitle"
            style={{
              padding: "0 20px 18px 20px",
            }}
          >
            {title}
          </div>
        </div>
      )}

      <div className="input-field">
        <input
          value={value}
          type={type}
          id={id}
          required={required}
          minLength={minLength}
          className={validate ? "validate" : ""}
          onChange={(e) => onChange(e.target.value)}
          placeholder={placeholder}
          style={{
            borderBottom: 0,
            border: "1px solid #979797",
            borderRadius: "10px",
            paddingLeft: "5%",
            paddingRight: "5%",
            width: "90%",
            margin: 0,
            backgroundColor: "white",
          }}
        />
        <span className="helper-text" data-error={errorText} />
      </div>
      {title && <div className="placeholder" />}
    </div>
  )
}

export { DynaInput }
