import React, { useState, useEffect } from "react"
import { EPopupTypes } from "../../../types/Popups"
import { Supervised } from "../../../types/Child";
import { Bundle } from "../../../types/Bundle";
import { RootState } from "../../../store/reducers/rootReducer";
import { ThunkDispatch } from "redux-thunk";
import { AppActions } from "../../../types/actionTypes/actions";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { DYNAMO_COLOR } from "../../../constants/constants";
import { packageActionType } from "../../../types/generalInterfaces";
import * as _ from 'lodash'

interface IProps {
  submitActions: Function
  id:string
  bundle:Bundle
}

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  IProps

const SelectSupervisedPopup = ({ id,bundle,submitActions, user:{supervised} }: Props) => {

  const [addRemoveObj,setAddRemoveObj] = useState<{[key:string]:packageActionType}>({})

  const onSelectChange = (e:any,supervisedId:string) => {
    const addRemove = e.target.checked ? packageActionType.ADD : packageActionType.REMOVE
    const obj = _.clone(addRemoveObj)
    //if there is already an action for this user
    if (obj[supervisedId]){
      if (obj[supervisedId] !== addRemove){
        //then there was an add and now remove or vice versa. Either way, remove the key
        delete obj[supervisedId];
      }
    }else{
      //there is not, so create one
      obj[supervisedId] = addRemove
    }
    setAddRemoveObj(obj)  
}

const setIsChecked = (supervisedId:string):boolean => {
  let checked = false;
  if (addRemoveObj[supervisedId]){
    checked =  addRemoveObj[supervisedId] === packageActionType.ADD ? true : false
  }else{
    //check if this bundle is already added to the supervisedChild
    //in order to show that this is already checked for this user
    //TODO
  }
  return checked
}
  
  return (
    <div
      id={id}
      className="modal modal-fixed-footer"
    >
      <div className="modal-content row">
        <h3
          className="black-text col s12"
          style={{ textAlign: "center", marginBottom: "40px" }}
        >
          {bundle.name}
        </h3>
        <h4
          className="black-text col s12"
        >
          Add bundle to:
        </h4>
        {
          supervised.map(sup => {
            return (
              <div className="col s12" key={sup.id}>
                <label>
                  <input type="checkbox" checked={setIsChecked(sup.id)} onChange={(e) => onSelectChange(e, sup.id)} />
                  <span>{sup.profile.nickname}</span>
                </label>

              </div>)
          })
        }
      <div className="btn col s12"  
            style={{ borderRadius: "5%", backgroundColor: DYNAMO_COLOR }}
            onClick={() => submitActions(addRemoveObj)}
            >
            Add
          </div>
      </div>
      
    </div>
  )
}

const mapStateToProps = (state: RootState) => {
  const {
    user,
    
    
    
  } = state
  return {
    user,
    
  }
}

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AppActions>) => {
  return bindActionCreators(
    {
      
    },
    dispatch
  )
}

export default connect(mapStateToProps,mapDispatchToProps)(SelectSupervisedPopup)
