import React, { useState, useEffect } from "react"
import { connect } from "react-redux"
import { ThunkDispatch } from "redux-thunk"
import { bindActionCreators } from "redux"
import * as _ from "lodash"
import { NavLink } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { history } from "../../App"
import { RootState } from "../../store/reducers/rootReducer"
import { AppActions } from "../../types/actionTypes/actions"
import StudentQuickView from "./Students/StudentQuickView/StudentQuickView"
import { SearchBox } from "../common"
import { Supervised } from "../../types/Child"
import AddStudentListItemBtn from "./AddStudentListItemBtn"
import Loader from "../layout/Loader"
import { DYNAMO_COLOR } from "../../constants/constants"
import { getSupervisedListMetadata } from "../../store/actions/userActions"

interface IProps {}

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  IProps

const StudentsView = ({
  user: { supervised },
  gettingStudents,
  getSupervisedListMetadata,
}: Props) => {
  const [searchTerm, setSearchTerm] = useState("")
  const [students, setStudents] = useState<Supervised[]>([])
  const { t } = useTranslation()
  useEffect(() => {
    setStudents(supervised)
    setSearchTerm("")
  }, [supervised])

  useEffect(() => {
    if (searchTerm.length === 0) {
      setStudents(supervised)
    } else
      setStudents(
        supervised.filter(
          (sup) =>
            sup.profile.nickname
              .toLowerCase()
              .indexOf(searchTerm.toLowerCase()) != -1
        )
      )
  }, [searchTerm])

  return (
    <div className="contentContainer">
      <div className="header" style={{ justifyContent: "space-between" }}>
        <div className="title bold">{t("students")}</div>

        <div style={{ display: "flex" }}>
          <button
            className="btn dynamoBackground"
            onClick={() => getSupervisedListMetadata()}
            style={{
              borderRadius: "10px",
              background: DYNAMO_COLOR,
              height: "50px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <i className="material-icons" style={{ fontSize: 30 }}>
              refresh
            </i>
          </button>
          <NavLink
            className="btn addStudentsBtn dynamoBackground"
            style={{
              display: "flex",
              alignItems: "center",
              background: DYNAMO_COLOR,
              height: "50px",
            }}
            to="/createChild"
          >
            <i className="material-icons left">add_circle</i>
            <span>{t("addNewStudent")}</span>
          </NavLink>
          <SearchBox
            value={searchTerm}
            placeholder="Search"
            onChange={(value: string) => setSearchTerm(value)}
          />
        </div>
      </div>

      {gettingStudents ? (
        <Loader />
      ) : (
        students.map((sup) => {
          return <StudentQuickView supervised={sup} key={sup.id} />
        })
      )}

      {searchTerm.length === 0 && (
        <AddStudentListItemBtn
          onClick={() =>
            history.push({
              pathname: "/createChild",
            })
          }
          title={t("addNewStudent")}
          tutorialText="Click here to add your first student."
          showTutorial={supervised.length === 0}
        />
      )}

      {searchTerm.length !== 0 && students.length === 0 && (
        <div className="title bold center" style={{ marginTop: 100 }}>
          No results
        </div>
      )}
    </div>
  )
}

const mapStateToProps = (state: RootState) => {
  const {
    user,
    loaders: { gettingStudents },
  } = state

  return {
    user,
    gettingStudents,
  }
}

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AppActions>) => {
  return bindActionCreators(
    {
      getSupervisedListMetadata,
    },
    dispatch
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(StudentsView)
