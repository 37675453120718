import React from "react"

interface Props {
  value: string
  placeholder?: string
  onChange: Function
}

const SearchBox = ({ value, placeholder, onChange }: Props) => {
  return (
    <div className="input-field searchBoxContainer">
      <i
        className="material-icons left prefix"
        style={{ top: "0.5rem", left: "10px" }}
      >
        search
      </i>
      <input
        value={value}
        type="text"
        onChange={(e) => onChange(e.target.value)}
        placeholder={placeholder}
      />
    </div>
  )
}

SearchBox.defaultProps = {
  placeholder: "search",
}

export { SearchBox }
