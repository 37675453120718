import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { v1 as uuidv1 } from "uuid"
import { Dispatch, bindActionCreators } from "redux"
import * as _ from "lodash"
import QuestionDetails from "./QuestionDetails"
import { Question } from "../../../types/Question"
import QuestionQuickView from "./QuestionQuickView"
import { initQuestion, duplicateQuestion } from "../../../utils/utils"
import {
  layoutTypes,
  packageStatusType,
  EBundlePrivacy,
} from "../../../types/generalInterfaces"
import { RootState } from "../../../store/reducers/rootReducer"
import { Bundle } from "../../../types/Bundle"
import Loader from "../../layout/Loader"
import {
  createBundle,
  clearSelectedBundleId,
  updateQuestionsBundle,
  updateSelectedBundleId,
} from "../../../store/actions/bundleActions"
import { getQuestionsForPackageId } from "../../../store/actions/userActions"

import ImageSelector from "./layouts/ImageSelector"
import { getSelectedBundle } from "../../../store/selectors/bundleSelector"

type Props = ReturnType<typeof mapDispatchToProps> &
  ReturnType<typeof mapStateToProps>

const createNewBundle = ({ userId }: { userId: string }) => {
  return {
    id: `${uuidv1()}--${userId}`,
    kingdomId: "",
    name: "",
    imageName: "",
    imageUrl: "",
    inactiveImageUrl: "",
    description: "",
    status: packageStatusType.ENABLED,
    order: 1,
    questions: [],
    privacy: EBundlePrivacy.PRIVATE,
  }
}

const BundleView = (props: Props) => {
  const {
    selectedbundleId,
    isEditable,
    createBundle,
    getQuestionsForPackageId,
    bundleLoaders,
    updatingBundleQuestions,
    userId,
    selectedBundle,
    clearSelectedBundleId,
    updateQuestionsBundle,
  } = props

  const [showSaveButton, setShowSaveButton] = useState<boolean>(true)
  const [showBundleIconMissing, setShowBundleIconMissing] = useState<boolean>(
    false
  )
  const [showQuestionsLoader, setShowQuestionsLoader] = useState<boolean>(false)
  const [isUpdating, setIsUpdating] = useState<boolean>(false)
  const [showQuestionUI, setShowQuestionUI] = useState<boolean>(false)
  const [currentQuestion, setCurrentQuestion] = useState<Question>(
    initQuestion({
      layoutType:
        layoutTypes.VERTICAL_TEXT_TWO_LINES_1_TO_4_ANSWERS_WHITE_BACKGROUND,
      creatorName: "",
      difficultyLevel: 1,
    })
  )
  const [bundle, setBundle] = useState<Bundle>(createNewBundle({ userId }))

  useEffect(() => {
    return () => {
      clearSelectedBundleId() // TODO this is whats causing the non loader in the first save of a new bundle
    }
  }, [])

  useEffect(() => {
    if (selectedBundle) {
      setBundle(selectedBundle)
      if (!selectedBundle.questions) {
        getQuestionsForPackageId(selectedBundle.id, selectedBundle.kingdomId)
      }
    } else {
      const b = createNewBundle({ userId })
      setBundle(b)
    }
  }, [selectedBundle, userId])

  useEffect(() => {
    const elems = document.querySelectorAll("select")
    M.FormSelect.init(elems)
    M.updateTextFields()

    const tooltippElems = document.querySelectorAll(".tooltipped")
    M.Tooltip.init(tooltippElems)
    if (selectedBundle) {
      setShowSaveButton(
        bundle.imageName !== selectedBundle.imageName ||
          bundle.name !== selectedBundle.name ||
          bundle.description !== selectedBundle.description ||
          bundle.privacy !== selectedBundle.privacy
      )
    } else {
      setShowSaveButton(
        bundle.imageName !== "" ||
          bundle.name !== "" ||
          bundle.description !== ""
      )
    }
  }, [bundle, selectedBundle])

  useEffect(() => {
    if (
      bundleLoaders[selectedbundleId] &&
      bundleLoaders[selectedbundleId].isUpdating
    ) {
      setIsUpdating(true)
    } else {
      setIsUpdating(false)
    }
  }, [bundleLoaders, selectedbundleId])

  useEffect(() => {
    if (
      updatingBundleQuestions[bundle.id] &&
      updatingBundleQuestions[bundle.id].isUpdating
    ) {
      setShowQuestionsLoader(true)
    } else {
      setShowQuestionsLoader(false)
    }
  }, [updatingBundleQuestions])

  const initCurrentQuestion = () => {
    return initQuestion({
      layoutType:
        layoutTypes.VERTICAL_TEXT_TWO_LINES_1_TO_4_ANSWERS_WHITE_BACKGROUND,
      creatorName: "",
      difficultyLevel: 1,
    })
  }

  const handleNameChange = (e: any) => {
    const { value } = e.target
    setBundle({ ...bundle, name: value })
  }

  const handleDescriptionChange = (e: any) => {
    const { value } = e.target
    setBundle({ ...bundle, description: value })
  }

  const handleBundleStatusChanged = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    // TODO - deal with the enable of disable of a package
    // const status = e.target.checked === true ? PackageDBStatus.ACTIVE : PackageDBStatus.INACTIVE
    // setBundle({...bundle,status})
  }

  const handleSubmit = (e: any) => {
    e.preventDefault()
    if (bundle.imageName === "") {
      setShowBundleIconMissing(true)
      return
    }

    createBundle(bundle)
  }

  const addQuestion = () => {
    const newQuestion = initCurrentQuestion()
    setShowQuestionUI(true)
    setCurrentQuestion(newQuestion)
  }

  const saveQuestion = (question: Question) => {
    const qs = [...bundle.questions]
    // find the index of the question in the questions array
    const index = qs.findIndex((q) => q.id === question.id)
    if (index === -1) {
      question.difficultyLevel =
        (qs && qs.length && qs[qs.length - 1].difficultyLevel + 1) || 1 // add this as the lasy difficultyLevel
      qs.push(question)
    } else {
      qs.splice(index, 1, question)
    }
    setShowQuestionUI(false)
    setBundle({ ...bundle, questions: qs })
    updateQuestionsBundle({ ...bundle, questions: qs }, [
      { action: "ADD", question },
    ])
  }

  const editQuestion = (index: number) => {
    const q = bundle.questions[index]
    const clonedQ = _.cloneDeep(q)
    setCurrentQuestion(clonedQ)
    setShowQuestionUI(true)
  }

  const deleteQuestion = (index: number) => {
    const qs = [...bundle.questions]
    console.log(qs)
    const removedQuestion = qs.splice(index, 1)
    const question = removedQuestion[0]
    console.log(qs)
    setBundle({ ...bundle, questions: qs })
    updateQuestionsBundle({ ...bundle, questions: qs }, [
      { action: "DELETE", question },
    ])
  }

  const duplicateSelectedQuestion = (index: number) => {
    const q = duplicateQuestion(bundle.questions[index])
    setCurrentQuestion(q)
    setShowQuestionUI(true)
  }

  return (
    <div className="" style={{ width: "90%" }}>
      <div className="row">
        <form onSubmit={handleSubmit} style={{}}>
          <div
            className="col xl5 m12"
            style={{
              backgroundColor: "#eeeeee",
              border: "thick solid white",
              height: "100vh",
              overflow: "auto",
            }}
          >
            <div className="row" style={{ marginBottom: 0, paddingTop: 20 }}>
              <div className="col s3">
                <ImageSelector
                  contentId={bundle.id}
                  imageName={bundle.imageName}
                  showAddButton={isEditable}
                  updateImageSelected={(val: string) => {
                    setShowBundleIconMissing(false)
                    setBundle({ ...bundle, imageName: val, imageUrl: val })
                  }}
                />
              </div>

              <div className="col s8">
                <div className="grey-text title text-darken-3">
                  Bundle Details
                </div>
                <button
                  className="btn pink lighten-1"
                  type="submit"
                  disabled={!showSaveButton || isUpdating}
                >
                  Save
                </button>
                <div
                  className={`input-field ${isEditable ? "show" : "hide"}`}
                  style={{ display: "inline", paddingLeft: "100px" }}
                >
                  <button
                    type="button"
                    className="btn pink lighten-1"
                    disabled={!selectedBundle}
                    onClick={() => {
                      addQuestion()
                    }}
                  >
                    Add Questions
                  </button>
                </div>
                {isUpdating && <Loader />}
              </div>
            </div>

            <div
              className="flow-text smallText red-text"
              style={{ height: 25 }}
            >
              {showBundleIconMissing ? "*Please choose a bundle icon" : ""}
            </div>

            <div className="input-field">
              <input
                value={bundle.name}
                type="text"
                id="bundleName"
                onChange={handleNameChange}
                disabled={!isEditable}
                required
                spellCheck
              />
              <span
                className="helper-text"
                data-error="Please enter the name of the bundle"
                data-success=""
              />
              <label htmlFor="bundleName">Bundle Name</label>
            </div>

            <div className="input-field">
              <textarea
                style={{ height: "150px" }}
                spellCheck
                value={bundle.description}
                id="description"
                className="materialize-textarea"
                onChange={handleDescriptionChange}
                disabled={!isEditable}
                required
              />
              <label htmlFor="description">Bundle description</label>
            </div>

            {isEditable && (
              <div
                className="grey-text subtitle text-darken-3"
                style={{
                  alignItems: "center",
                  display: "flex",
                  paddingBottom: 20,
                  marginBottom: 20,
                  borderBottom: "1px solid",
                }}
              >
                Privacy
                <i
                  className="material-icons tooltipped"
                  data-position="top"
                  data-tooltip={
                    bundle.privacy === EBundlePrivacy.PRIVATE
                      ? "This bundle will be available for you and your students only"
                      : "This bundle will be available for all dynamo tutors"
                  }
                >
                  info
                </i>
                <div
                  className="switch"
                  style={{ alignItems: "center", display: "flex" }}
                >
                  <label>
                    <input
                      id="privacySwitch"
                      type="checkbox"
                      disabled={!isEditable}
                      defaultChecked={
                        selectedBundle
                          ? selectedBundle.privacy === EBundlePrivacy.PRIVATE
                          : bundle.privacy === EBundlePrivacy.PRIVATE
                      }
                      onClick={(e) => {
                        setBundle({
                          ...bundle,
                          privacy:
                            bundle.privacy === EBundlePrivacy.PRIVATE
                              ? EBundlePrivacy.PUBLIC
                              : EBundlePrivacy.PRIVATE,
                        })
                      }}
                    />
                    <span className="lever" />
                  </label>
                </div>
              </div>
            )}

            {showQuestionsLoader && <Loader />}

            {bundle.questions &&
              bundle.questions.map((q, index) => {
                return (
                  <QuestionQuickView
                    key={q.id}
                    question={q}
                    index={index}
                    editQuestion={editQuestion}
                    deleteQuestion={deleteQuestion}
                    duplicateQuestion={duplicateSelectedQuestion}
                    viewOnlyMode={!isEditable}
                  />
                )
              })}
          </div>
        </form>
        <div
          className={`col xl7 ${showQuestionUI ? "show" : "hide"}`}
          style={{ backgroundColor: "#eeeeee" }}
        >
          <QuestionDetails
            key={currentQuestion.id}
            inEditMode={
              currentQuestion.contentData.text.length > 0 ||
              currentQuestion.contentData.image !== ""
            }
            q={currentQuestion}
            saveQuestion={saveQuestion}
            cancel={() => {
              setShowQuestionUI(false)
            }}
          />
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state: RootState) => {
  const {
    assetManager,
    loaders: { bundleLoaders, updatingBundleQuestions },
    user: { id },
    bundle: { selectedbundleId, isEditable },
  } = state

  const selectedBundle = getSelectedBundle(state)

  return {
    selectedbundleId,
    isEditable,
    assetManager,
    bundleLoaders,
    updatingBundleQuestions,
    userId: id,
    selectedBundle,
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      createBundle,
      getQuestionsForPackageId,
      clearSelectedBundleId,
      updateSelectedBundleId,
      updateQuestionsBundle,
    },
    dispatch
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(BundleView)
